<template>
  <div class="max-close-position-table">
    <table v-if="filteredPositions.length">
      <thead>
      <tr>
        <th>账户</th>
        <th>MAX-L</th>
        <th v-if="usePercentage">BUY</th>
        <th>MAX-S</th>
        <th v-if="usePercentage">SELL</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="position in filteredPositions" :key="position.account">
        <td>{{ position.account }}</td>
        <td>{{ position.longAmount }}</td>
        <td v-if="usePercentage">{{ calculateOrderAmount(position.longAmount) }}</td>
        <td>{{ position.shortAmount}}</td>
        <td v-if="usePercentage">{{ calculateOrderAmount(position.shortAmount) }}</td>
      </tr>
      <!-- 添加汇总行 -->
      <tr>
        <td>汇总</td>
        <td>{{ totalLongAmount }}</td>
        <td v-if="usePercentage">{{ totalOrderLongAmount }}</td>
        <td>{{ totalShortAmount }}</td>
        <td v-if="usePercentage">{{ totalOrderShortAmount }}</td>
      </tr>

      </tbody>
    </table>
    <p v-else>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: 'MaxClosePositionTable',
  props: {
    usePercentage: Boolean,
    percentage: Number,
  },
  data() {
    return {
      positions: [],
      currentInstId: '',
      selectedAccounts: [],
    };
  },

  computed: {
    filteredPositions() {
      return this.positions.filter(position => this.selectedAccounts.includes(position.account));
    },
    totalLongAmount() {
      return this.filteredPositions.reduce((sum, position) => sum + parseFloat(position.longAmount), 0).toFixed(1);
    },
    totalShortAmount() {
      return this.filteredPositions.reduce((sum, position) => sum + parseFloat(position.shortAmount), 0).toFixed(1);
    },
    totalOrderLongAmount() {
      return this.calculateOrderAmount(parseFloat(this.totalLongAmount));
    },
    totalOrderShortAmount() {
      return this.calculateOrderAmount(parseFloat(this.totalShortAmount));
    }
  },


  created() {
    this.selectedAccounts = [];
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    this.$eventBus.on('ticker-selected', this.onTickerSelected);
    this.$eventBus.on('update-max-close-positions', this.updatePositions);


    // 添加这行来初始化数据
    this.$nextTick(() => this.fetchMaxClosePositions());

  },
  beforeUnmount() {
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    this.$eventBus.off('ticker-selected', this.onTickerSelected);
    this.$eventBus.off('update-max-close-positions', this.updatePositions);
  },
  methods: {
    onAccountsChanged(accounts) {
      // 将 account value 转换为 label
      this.selectedAccounts = accounts.map(value => `账户${value}`);
      this.fetchMaxClosePositions();
    },



    onTickerSelected(ticker) {
      // console.log('Ticker selected:', ticker); // 添加日志
      this.currentInstId = ticker.instId;
      this.fetchMaxClosePositions();
    },


    updatePositions(positionInfo) {
      // console.log('Received update-max-close-positions event with data:', positionInfo); // 添加日志
      if (positionInfo && typeof positionInfo === 'object') {
        this.positions = this.calculateMaxClosePositions(positionInfo);
      } else {
        // console.error('Received invalid positionInfo:', positionInfo);
        this.positions = []; // 重置为空数组
      }
    },




    fetchMaxClosePositions() {
      // 假设 PositionTable 组件已经发出了 'update-max-close-positions' 事件
      // 并且包含了最新的持仓信息
      this.$eventBus.emit('request-max-close-positions');
    },



    calculateMaxClosePositions(positionInfo) {
      // console.log('Received positionInfo:', positionInfo); // 保留这行日志
      // console.log('Current instId:', this.currentInstId); // 添加这行来检查 currentInstId

      if (!this.currentInstId) {
        // console.warn('currentInstId is not set');
        return []; // 如果 currentInstId 未设置，返回空数组
      }


      const positions = [];
      for (const [account, data] of Object.entries(positionInfo)) {
        if (!Array.isArray(data)) {
          // console.warn(`Invalid data for account ${account}:`, data); // 保留这行警告
          continue; // 跳过无效数据
        }
        const longAmount = data
            .filter(pos => pos.instId === this.currentInstId && pos.posSide === 'long')
            .reduce((sum, pos) => sum + parseFloat(pos.pos), 0);
        const shortAmount = data
            .filter(pos => pos.instId === this.currentInstId && pos.posSide === 'short')
            .reduce((sum, pos) => sum + parseFloat(pos.pos), 0);
        positions.push({
          account,
          longAmount,

          shortAmount,
        });
      }
      return positions;
    },



    calculateOrderAmount(amount) {
      if (!amount || isNaN(parseFloat(amount))) {
        return 0;
      }
      const maxAmount = parseFloat(amount);
      return (maxAmount * (this.percentage / 100)).toFixed(1); // 四舍五入保留1位小数
    },
  },
};
</script>

<style scoped>
.max-close-position-table {
  margin-top: auto;
  margin-bottom: 10px;
  padding-right: 25px; /* 保留右边距 */
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* 使列的间距适应单元格内容 */
}

th, td {
  border: 1px solid #ffffff; /* 将边框颜色设置为白色 */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000;
  color: #ffffff; /* 设置表头文字颜色为白色 */
}

/* 移动端适配 */
@media (max-width: 600px) {
  table {
    font-size: 16px; /* 调整字体大小 */
    padding-right: 10px; /* 保留右边距 */
  }

  th, td {
    padding: 6px; /* 调整内边距 */
  }

  th {
    font-size: 16px; /* 调整表头字体大小 */
  }
}
</style>