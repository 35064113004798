<template>
    <!-- 移动端适配 -->
    <div v-if="$device.isMobile" class="main-content">
      <CryptoTickers ref="cryptoTickersComponent"/>
      <AccountBalanceCards @trading-panel-visibility-changed="updateTradingPanelVisibility"
                           @logout="handleLogout"
                           @GetAllApi="GetAllApi"/>
      <!-- 移动端的 trading-panel 容器放在 AccountBalanceCards 组件之后 -->
      <div class="trading-panel0" :class="{ 'hidden': !isTradingPanelVisible }">
        <TradingPanelSplit ref="tradingPanelSplit" v-show="isTradingPanelVisible" :class="{ 'hidden': !isTrading }" />
      </div>
      <div class="position-table0">
        <PositionTable ref="positionTable" />
        <OrderList ref="orderList" />
        <algoOrderList ref="algoOrderList" />
        <tradeFills ref="tradeFills" />
      </div>
    </div>

    <!-- 桌面端适配 -->
    <div v-else class="main-content">
      <div class="account-overview">
        <CryptoTickers ref="cryptoTickersComponent"/>
        <div class="balance-cards-desktop">
          <AccountBalanceCards @trading-panel-visibility-changed="updateTradingPanelVisibility"
                               @logout="handleLogout"
                               @GetAllApi="GetAllApi"/>
        </div>

        <div class="position-table">
          <PositionTable ref="positionTable" />
          <OrderList ref="orderList" />
        </div>
        <div class="position-table">
          <algoOrderList ref="algoOrderList" />
        </div>
        <div class="position-table">
          <tradeFills ref="tradeFills" />
        </div>
      </div>
      <div class="trading-panel" :class="{ 'hidden': !isTradingPanelVisible }">
        <TradingPanelSplit ref="tradingPanelSplit" v-show="isTradingPanelVisible" :class="{ 'hidden': !isTrading }" />
      </div>
    </div>
</template>

<script>

import CryptoTickers from './CryptoTickers.vue';
import AccountBalanceCards from './AccountBalanceCards.vue';
import PositionTable from './PositionTable.vue';
import OrderList from "./OrderList.vue";
import tradeFills from "./tradeFills.vue";
import algoOrderList from "./algoOrderList.vue";
import TradingPanelSplit from "./TradingPanelsplit.vue";

export default {
  data() {
    return {
      isTradingPanelVisible: this.$device.isMobile ? false : true, // 根据设备类型设置初始状态
      isTrading: true,
      activeAccounts: [],
    };
  },

  methods: {
    handleLogout() {
      this.$emit('logout'); // 将 logout 事件向上触发
      this.disconnectWebSocket()
    },
    
    GetAllApi(activeAccounts) {
      // 将GetAllApi传递值写入到activeAccountes中
      this.activeAccounts = activeAccounts; // 保存接收的 activeAccounts
      // console.log('Received activeAccounts:', this.activeAccounts);  // 控制台输出接收到的参数

      if (this.$refs.positionTable) {
        this.$refs.positionTable.fetchDataViaAPI(this.activeAccounts); // 调用 PositionTable 的 API
      }

      if (this.$refs.orderList) {
        this.$refs.orderList.fetchOrderList(); // 调用 OrderList 的 API
      }

      if (this.$refs.algoOrderList) {
        this.$refs.algoOrderList.fetchAlgoOrderList(); // 调用 OrderList 的 API
      }

      if (this.$refs.tradeFills) {
        this.$refs.tradeFills.fetchtradefills3D(); // 调用 OrderList 的 API
      }

      if (this.$refs.tradingPanelSplit) {
        // 调用 TradingPanelSplit 的 updateMaxAmounts() 和 fetchLeverageInfo()
        this.$refs.tradingPanelSplit.updateMaxAmounts();
        this.$refs.tradingPanelSplit.fetchLeverageInfo();
      }
    },

    disconnectWebSocket() {
      // 通过 $refs 调用 CryptoTickers 的 disconnectWebSocket 方法
      if (this.$refs.cryptoTickersComponent) {
        this.$refs.cryptoTickersComponent.disconnectWebSocket();
      }
    },
    updateTradingPanelVisibility(visible) {
      this.isTradingPanelVisible = visible;
    },
  },

  name: 'cod',
  components: {
    OrderList,
    CryptoTickers,
    AccountBalanceCards,
    PositionTable,
    tradeFills,
    algoOrderList,
    TradingPanelSplit
  }
}
</script>

<style>
body {
  background-color: #1a1a1a;
  color: #ffffff;
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.main-content {
  display: flex;
  width: 100%;
  gap: 1px;
  position: relative; /* 使按钮相对定位 */

}

.account-overview {
  flex: 3; /* 占页面的80% */
  overflow: hidden; /* 使内容可以滚动 */
  margin-top: -15px;
}

.position-table {
  flex: 1; /* 占页面的20% */
  height: auto; /* 固定高度 */
  overflow: hidden; /* 使内容可以滚动 */
  margin-right: 15px;
}

.trading-panel {
  flex: 1; /* 占页面的20% */
  height: auto; /* 固定高度 */
  overflow: hidden; /* 使内容可以滚动 */
  margin-right: 1px; /* 保留一点点的右边距 */
}

.trading-panel.hidden {
  opacity: 0;
  transform: translateX(100%);
  pointer-events: none;
  margin-right: 10px;
}

.balance-cards-mobile {
  display: none;
}

.hidden {
  display: none; /* 隐藏交易面板 */
}

/* 移动端适配 */
@media (max-width: 600px) {
  body {
    padding: 10px;
  }

  #app {
    gap: 15px; /* 增加整体间距 */
  }

  .main-content {
    flex-direction: column;
    gap: 15px; /* 增加主要内容区域的间距 */
    margin-left: 11px;
    margin-top: -10px;
    margin-right: 1px;
  }

  .account-overview,
  .trading-panel {
    flex: none;
    width: auto;
    margin-right: 10px;
    padding: 1px 0; /* 添加上下内边距 */
    border-bottom: 1px solid #333; /* 添加底部边框以区分组件 */
  }

  .trading-panel {
    order: -1; /* 将交易面板移到账户概览之前 */
    margin-bottom: 15px; /* 添加底部外边距 */
    padding-left: 1px; /* 添加左侧内边距 */
    padding-right: 1px; /* 添加左侧内边距 */
    overflow: hidden; /* 使内容不可以滚动 */
  }

  .account-overview {
    margin-top: -10px;
  }

  .balance-cards-desktop {
    display: none;
  }

  .balance-cards-mobile {
    display: block;
    margin-top: 15px;
  }
}

/* 针对更小的屏幕进行额外调整 */
@media (max-width: 480px) {
  body {
    padding: 5px;
  }

  #app {
    gap: 10px;
  }

  .main-content {
    gap: 10px;
    margin-left: 10px;
    margin-top: -10px;
    margin-right: 11px;
  }

  .account-overview,
  .trading-panel {
    padding: 10px 0;
    margin-right: 10px;

  }

  .trading-panel {
    margin-bottom: 10px;
    padding-left: 5px; /* 为更小的屏幕减少左侧内边距 */
    padding-right: 5px; /* 为更小的屏幕减少左侧内边距 */
  }


  .account-overview {
    margin-top: -10px;
  }
}
</style>
