<template>
  <div class="order-list">

    <div class="section-header">
      <h3 @click="toggleAllAlgoAccounts" class="section-header">
        策略订单 ({{ totalAlgoOrdersCount }})
      </h3>

      <!-- 新增的产品ID筛选下拉框 -->
      <div v-if="!$device.isMobile" class="filter-container">
        <label for="product-filter-algo">产品ID</label>
        <select id="product-filter-algo" v-model="selectedAlgoProductId" @change="filterAlgoOrders">
          <option value="">ALL</option>
          <option v-for="id in uniqueAlgoProductIds" :key="id" :value="id">{{ id }}</option>
        </select>
      </div>

      <!-- 新增的订单类型筛选下拉框 -->
      <div v-if="!$device.isMobile" class="filter-container">
        <label for="order-type-filter">订单类型</label>
        <select id="order-type-filter" v-model="selectedOrderType" @change="filterAlgoOrders">
          <option value="">ALL</option>
          <option v-for="type in uniqueOrderTypes" :key="type" :value="type">{{ type }}</option>
        </select>
      </div>


      <div v-for="(count, account) in filteredAlgoOrdersCount" :key="account">
        <span v-if="!$device.isMobile">账户{{ account }}: {{ count }} 个订单</span>
      </div>

      <div class="button-group">
        <button @click="fetchAlgoOrderList">获取订单</button>
        <button @click="cancelAllAlgoOrders">批量撤销订单</button>

      </div>
    </div>

    <table :class="{ 'desktop-table': true, 'hidden': isAlgoTableHidden }">
      <thead>
      <tr>
        <th @click="sort('account')">
          账户
          <span v-if="sortKey === 'account'">{{ sortOrders['account'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('instId')">
          产品ID
          <span v-if="sortKey === 'instId'">{{ sortOrders['instId'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('modeDirectionLeverage')">
          模式方向杠杆
          <span v-if="sortKey === 'modeDirectionLeverage'">{{ sortOrders['modeDirectionLeverage'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('orderType')">
          订单类型
          <span v-if="sortKey === 'orderType'">{{ sortOrders['orderType'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('triggerPx')">
          触发价格
          <span v-if="sortKey === 'triggerPx'">{{ sortOrders['triggerPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('callbackRange')">
          回调幅度
          <span v-if="sortKey === 'callbackRange'">{{ sortOrders['callbackRange'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('sz')">
          委托数量
          <span v-if="sortKey === 'sz'">{{ sortOrders['sz'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('slTriggerPx')">
          止损触发价格
          <span v-if="sortKey === 'slTriggerPx'">{{ sortOrders['slTriggerPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('tpTriggerPx')">
          止盈触发价格
          <span v-if="sortKey === 'tpTriggerPx'">{{ sortOrders['tpTriggerPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('uTime')">
          更新时间
          <span v-if="sortKey === 'uTime'">{{ sortOrders['uTime'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
<!--        <th @click="sort('state')">-->
<!--          状态-->
<!--          <span v-if="sortKey === 'state'">{{ sortOrders['state'] === 'asc' ? '▲' : '▼' }}</span>-->
<!--        </th>-->
        <th>撤单</th>
        <th>修改</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="filteredAlgoOrders.length === 0">
        <td colspan="13">暂无策略委托订单</td>
      </tr>
      <tr v-for="order in sortedFilteredAlgoOrders" :key="order.algoId">
        <td>{{ order.account }}</td>
        <td>{{ order.instId }}</td>
        <td>{{ formatModeDirectionLeverage(order) }}</td>
        <td>{{ formatOrderType(order) }}</td>
        <td>{{ order.triggerPx }}</td>
        <td>{{ order.callbackRange }}</td>
        <td>{{ order.sz }}</td>
        <td>{{ order.slTriggerPx }}</td>
        <td>{{ order.tpTriggerPx }}</td>
        <td>{{ formatDate(order.uTime) }}</td>
<!--        <td>{{ order.state }}</td>-->
        <td><button @click="cancelAlgoOrder(order)">撤单</button></td>
        <td>
          <button v-if="order.ordType === 'conditional' || order.ordType === 'oco' || order.ordType === 'trigger'" @click="openAmendAlgoModal(order)">修改</button>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="mobile-cards">

      <!-- 新增的产品ID筛选下拉框 -->
      <div v-if="$device.isMobile" class="filter-container">

        <label for="product-filter-algo">ID</label>
        <select id="product-filter-algo" v-model="selectedAlgoProductId">
          <option value="">ALL</option>
          <option v-for="id in uniqueAlgoProductIds" :key="id" :value="id">{{ id }}</option>
        </select>

        <label for="order-type-filter">类型</label>
        <select id="order-type-filter" v-model="selectedOrderType" @change="filterAlgoOrders">
          <option value="">ALL</option>
          <option v-for="type in uniqueOrderTypes" :key="type" :value="type">{{ type }}</option>
        </select>

      </div>


      <div v-for="(accountAlgoOrders, accountName) in groupedAlgoOrders" :key="accountName" class="account-card">
        <h3 @click="toggleAlgoAccount(accountName)" class="account-header">
          账户{{ accountName }} ({{ accountAlgoOrders.length }})
        </h3>
        <div v-if="!collapsedAlgoAccounts.includes(accountName)">
          <div v-for="order in accountAlgoOrders" :key="order.algoId" class="order-card">
            <div class="order-grid">
              <div class="order-item">
                <strong>{{ order.instId }}</strong> <span>{{ formatModeDirectionLeverage(order) }}</span>
              </div>
              <div class="order-item">
                <strong>订单类型:</strong> {{ formatOrderType(order) }}
              </div>
              <div class="order-item">
                <strong>触发价格:</strong> {{ order.triggerPx }}
              </div>
              <div class="order-item">
                <strong>委托数量:</strong> {{ order.sz }}
              </div>
              <div class="order-item">
                <strong>止损触发价格:</strong> {{ order.slTriggerPx }}
              </div>
              <div class="order-item">
                <strong>止盈触发价格:</strong> {{ order.tpTriggerPx }}
              </div>
<!--              <div class="order-item">-->
<!--                <strong>订单ID:</strong> {{ order.algoId }}-->
<!--              </div>-->
<!--              <div class="order-item">-->
<!--                <strong>创建时间:</strong> {{ formatDate(order.cTime) }}-->
<!--              </div>-->
<!--              <div class="order-item">-->
<!--                <strong>状态:</strong> {{ order.state }}-->
<!--              </div>-->
              <div class="order-item">
                <button @click="cancelAlgoOrder(order)">撤单</button>
              </div>
              <div class="order-item">
                <!-- 如果订单类型是条件单或oco或计划委托，则显示修改按钮，点击按钮打开修改算法模态框 -->
                <button v-if="order.ordType === 'conditional' || order.ordType === 'oco' || order.ordType === 'trigger'" @click="openAmendAlgoModal(order)">修改</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改策略委托订单模态框 -->
    <div v-if="showAmendAlgoModal" class="modal" @click.self="closeAmendAlgoModal">
      <div class="modal-content">
        <span class="close" @click="closeAmendAlgoModal">&times;</span>

        <h3>
          账户：{{ amendAlgoOrderData.account }}<br>
          {{ amendAlgoOrderData.instId }}<br>
          {{ formatModeDirectionLeverage(amendAlgoOrderData) }}<br>
          {{ formatOrderType(amendAlgoOrderData) }}<br>
        </h3>


        <div class="form-group">
          <label for="newSz">新数量:
          <input v-model="amendAlgoOrderData.newSz" type="number" id="newSz" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newTriggerPx">新触发价格:
            <input v-model="amendAlgoOrderData.newTriggerPx" type="number" id="newTriggerPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newTpTriggerPx">新止盈触发价:
          <input v-model="amendAlgoOrderData.newTpTriggerPx" type="number" id="newTpTriggerPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newSlTriggerPx">新止损触发价:
          <input v-model="amendAlgoOrderData.newSlTriggerPx" type="number" id="newSlTriggerPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newTpOrdPx">新止盈委托价:
          <input v-model="amendAlgoOrderData.newTpOrdPx" type="number" id="newTpOrdPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newSlOrdPx">新止损委托价:
          <input v-model="amendAlgoOrderData.newSlOrdPx" type="number" id="newSlOrdPx" class="input-field" />
          </label>
        </div>
        <button @click="amendAlgoOrder" class="amend-button">修改</button>
      </div>
    </div>
  </div>
</template>


<script>
import api from '@/services/api';
import WebSocketService from '@/services/websocket';
import { useToast } from "vue-toastification";
import { inject } from 'vue';

export default {
  name: 'algoOrderList',

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },

  data() {
    return {
      lastFetchOrderList: 0,
      algoOrders: [], // 新增：用于存储策略委托订单
      selectedAccounts: [],
      showAmendAlgoModal: false,
      amendAlgoOrderData: {
        account: '',
        algoId: '',
        instId: '',
        newSz: '',
        newTpTriggerPx: '',
        newSlTriggerPx: '',
        newTpOrdPx: '',
        newSlOrdPx: '',
        newTriggerPx:''

      },
      collapsedAlgoAccounts: [], // 用于存储策略委托订单的折叠状态

      selectedAlgoProductId: '',   // 策略委托订单筛选的产品ID
      selectedOrderType: '', // 筛选的订单类型


      isAlgoTableHidden: true, // 策略委托订单表格默认隐藏

      sortKey: 'uTime', // 默认排序
      sortOrders: {uTime: 'desc'},


    };
  },



  // 在创建时注册事件监听
  created() {
    // 监听账户变化事件
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    // 监听刷新订单列表事件
    // this.$eventBus.on('refresh-order-list', this.fetchAlgoOrderList);
    // 监听订单更新事件
    WebSocketService.addListener('algoOrderUpdate', this.handleAlgoOrderUpdate);
    WebSocketService.addListener('algoAdvanceUpdate', this.handleAlgoAdvanceUpdate);
  },

  // 在卸载时移除事件监听
  beforeUnmount() {
    // 移除账户变化事件监听
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    // 移除刷新订单列表事件监听
    // this.$eventBus.off('refresh-order-list', this.fetchAlgoOrderList);
    // 移除订单更新事件监听
    WebSocketService.removeListener('algoOrderUpdate', this.handleAlgoOrderUpdate);
    WebSocketService.removeListener('algoAdvanceUpdate', this.handleAlgoAdvanceUpdate);
  },

  computed: {

    // 根据筛选后的订单计算每个账户的订单数量
    filteredAlgoOrdersCount() {
      const counts = {};
      this.filteredAlgoOrders.forEach(order => {
        counts[order.account] = (counts[order.account] || 0) + 1;
      });
      return counts;
    },




    sortedFilteredAlgoOrders() {
      if (!this.sortKey || !this.sortOrders[this.sortKey]) {
        return this.filteredAlgoOrders;
      }
      return [...this.filteredAlgoOrders].sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];
        if (aValue < bValue) {
          return this.sortOrders[this.sortKey] === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return this.sortOrders[this.sortKey] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },

    uniqueAlgoProductIds() {
      const ids = new Set(this.algoOrders.map(order => order.instId));
      return Array.from(ids);
    },

    uniqueOrderTypes() {
      const types = new Set(this.algoOrders.map(order => this.formatOrderType(order)));
      return Array.from(types);
    },
    filteredAlgoOrders() {
      return this.algoOrders.filter(order =>
          (this.selectedAlgoProductId === "" || order.instId === this.selectedAlgoProductId) &&
          (this.selectedOrderType === "" || this.formatOrderType(order) === this.selectedOrderType)
      );
    },

    totalOrdersCount() {
      return this.orders.length;
    },

    algoOrdersCount() {
      const counts = {};
      this.algoOrders.forEach(order => {
        counts[order.account] = (counts[order.account] || 0) + 1;
      });
      return counts;
    },

    totalAlgoOrdersCount() {
      return this.algoOrders.length;
    },


    groupedAlgoOrders() {
      const grouped = {};
      this.filteredAlgoOrders.forEach(order => {
        if (!grouped[order.account]) {
          grouped[order.account] = [];
        }
        grouped[order.account].push(order);
      });
      return grouped;
    }
  },

  methods: {


    // 排序方法
    sort(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] === 'asc' ? 'desc' : 'asc';

      this.filteredAlgoOrders.sort((a, b) => {
        const aValue = this.getSortableValue(a, key);
        const bValue = this.getSortableValue(b, key);

        if (aValue < bValue) return this.sortOrders[key] === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrders[key] === 'asc' ? 1 : -1;
        return 0;
      });
    },

    getSortableValue(order, key) {
      if (key === 'orderType') {
        return this.formatOrderType(order);
      }
      return order[key];
    },


    filterAlgoOrders() {
      this.filteredAlgoOrders; // 触发计算属性更新
    },


    // 处理算法订单更新
    handleAlgoOrderUpdate(data) {
      // console.log('Algo order update received in OrderList component:', data);
      // 如果距离上次获取订单列表的时间超过 1500 毫秒，则重新获取订单列表
      if (Date.now() - this.lastFetchOrderList > 1500) {
        this.lastFetchOrderList = Date.now();
        // console.log('Fetching algo order list...'); // 添加调试日志
        // 重新获取算法订单列表
        this.fetchAlgoOrderList();
      }
    },

    // 当接收到高级算法更新时处理函数
    handleAlgoAdvanceUpdate(data) {
      // console.log('Algo advance update received in OrderList component:', data);
      // 如果距离上次获取订单列表的时间超过 1500 毫秒，则重新获取订单列表
      if (Date.now() - this.lastFetchOrderList > 1500) {
        this.lastFetchOrderList = Date.now();
        this.fetchAlgoOrderList(); // 假设高级算法订单也包含在 algoOrders 中
      }
    },

    // 当选择账户发生变化时触发
    async onAccountsChanged(accounts) {
      // console.log('Accounts changed in OrderList:', accounts);
      // 保存新的账户选择
      this.selectedAccounts = [...accounts];
      // 重新获取策略委托订单未成交列表
      await this.fetchAlgoOrderList();
    },



    // 获取策略委托订单未成交列表
    async fetchAlgoOrderList() {
      // 打印选择的账户
      // console.log('Fetching algo order list for accounts:', this.selectedAccounts);
      // 如果没有选择账户，则跳过API调用
      if (this.selectedAccounts.length === 0) {
        // console.log('No accounts selected, skipping API call');
        return;
      }
      try {
        // 遍历选择的账户，获取策略委托订单列表
        const algoOrderPromises = this.selectedAccounts.map(async (account) => {
          const response = await api.getAlgoOrderList(account);
          // console.log('getAlgoOrderList API response:', response);
          // 如果返回的数据存在且是数组，则将订单信息添加到数组中
          if (response.data && Array.isArray(response.data.data)) {
            return response.data.data.map(order => ({ ...order, account }));
          } else if (response.data && Array.isArray(response.data)) {
            return response.data.map(order => ({ ...order, account }));
          } else {
            // console.log('No valid data received for account:', account);
            return [];
          }
        });
        // 将所有策略委托订单列表合并为一个数组
        const algoOrdersArray = await Promise.all(algoOrderPromises);
        this.algoOrders = algoOrdersArray.flat();
        // console.log('Fetched algo orders:', this.algoOrders);

        // 更新折叠状态
        this.collapsedAlgoAccounts = Object.keys(this.groupedAlgoOrders)

      } catch (error) {
        // console.error('Error fetching algo order list:', error);
        this.toast.error('获取策略委托订单列表失败');
      }
    },


    // 根据订单信息，格式化交易模式和方向杠杆
    formatModeDirectionLeverage(order) {
      if (order.side === 'buy' && order.posSide === 'long') {
        return `${order.tdMode} ${order.lever}x 买入 开多`;
      } else if (order.side === 'sell' && order.posSide === 'short') {
        return `${order.tdMode} ${order.lever}x 卖出 开空`;
      } else if (order.side === 'buy' && order.posSide === 'short') {
        return `${order.tdMode} ${order.lever}x 买入 平空`;
      } else if (order.side === 'sell' && order.posSide === 'long') {
        return `${order.tdMode} ${order.lever}x 卖出 平多`;
      } else {
        return `${order.tdMode} ${order.lever}x ${order.side} ${order.posSide}`;
      }
    },

    // 根据订单信息，设置订单类型
    formatOrderType(order) {
      if (order.ordType === 'conditional') {
        return '单向止盈止损';
      } else if (order.ordType === 'oco') {
        return '双向止盈止损';
      } else if (order.ordType === 'trigger') {
        return '计划委托';
      } else if (order.ordType === 'move_order_stop') {
        return '移动止盈止损';
      } else if (order.ordType === 'twap') {
        return '时间加权委托';
      } else {
        return order.ordType;
      }
    },


    formatDate(timestamp) {
      const date = new Date(parseInt(timestamp));
      return date.toLocaleString();
    },




    // 撤销策略委托订单
    async cancelAlgoOrder(order) {
      try {
        const orderData = {
          account: order.account,
          algoId: order.algoId,
          instId: order.instId
        };
        const response = await api.cancelAlgoOrder(orderData);
        // console.log('cancelAlgoOrder API response:', response);
        if (response.data) {
          this.toast.success('撤销策略委托订单成功');

          this.eventBus.emit('update-max-amounts'); // 撤单后，重新获取最大可买卖/开仓数量
        } else {
          this.toast.error('撤销策略委托订单失败');
        }
      } catch (error) {
        // console.error('Error cancelling algo order:', error);
        this.toast.error('撤销策略委托订单失败');
      }
    },

    openAmendAlgoModal(order) {
      if (order.ordType !== 'conditional' && order.ordType !== 'oco' && order.ordType !== 'trigger') {
        return;
      }
      this.amendAlgoOrderData = {
        account: order.account,
        algoId: order.algoId,
        instId: order.instId,
        newSz: order.sz,
        newTpTriggerPx: order.tpTriggerPx,
        newSlTriggerPx: order.slTriggerPx,
        newTpOrdPx: order.tpOrdPx,
        newSlOrdPx: order.slOrdPx,
        newTriggerPx: order.triggerPx,
        side: order.side,  // 添加 side
        posSide: order.posSide,  // 添加 posSide
        tdMode: order.tdMode,  // 添加 tdMode
        lever: order.lever,  // 添加 lever
        ordType: order.ordType  // 添加 ordType
      };
      this.showAmendAlgoModal = true;
    },

    closeAmendAlgoModal() {
      this.showAmendAlgoModal = false;
    },

    async amendAlgoOrder() {
      try {
        const response = await api.amendAlgoOrder(this.amendAlgoOrderData);
        // console.log('amendAlgoOrder API response:', response);
        if (response.data) {
          this.closeAmendAlgoModal();

          this.eventBus.emit('update-max-amounts'); // 修改订单后，重新获取最大可买卖/开仓数量
        } else {
          this.toast.error('修改策略委托订单失败');
        }
      } catch (error) {
        // console.error('Error amending algo order:', error);
        this.toast.error('修改策略委托订单失败');
      }
    },

    async cancelAllAlgoOrders() {
      if (this.algoOrders.length === 0) {
        this.toast.error('没有可撤销的策略委托订单');
        return;
      }

      const confirmCancel = confirm('确定要撤销所有策略委托订单吗？');
      if (!confirmCancel) return;

      try {
        const cancelPromises = this.filteredAlgoOrders.map(order => {
          const orderData = {
            account: order.account,
            algoId: order.algoId,
            instId: order.instId
          };
          return api.cancelAlgoOrder(orderData);
        });

        const results = await Promise.all(cancelPromises);
        // console.log('Cancel all algo orders results:', results);

        const successCount = results.filter(result => result.data).length;
        const failCount = results.length - successCount;

        if (failCount === 0) {
          this.toast.success(`成功撤销所有 ${successCount} 个策略委托订单`);
        } else {
          this.toast.success(`成功撤销 ${successCount} 个策略委托订单，${failCount} 个撤销失败`);
        }
        if (successCount > 0) {
          this.eventBus.emit('update-max-amounts'); // 撤单后，重新获取最大可买卖/开仓数量
        }

      } catch (error) {
        // console.error('Error cancelling all algo orders:', error);
        this.toast.error('批量撤销策略委托订单时发生错误');
      }
    },



    toggleAlgoAccount(accountName) {
      const index = this.collapsedAlgoAccounts.indexOf(accountName);
      if (index > -1) {
        this.collapsedAlgoAccounts.splice(index, 1); // 展开
      } else {
        this.collapsedAlgoAccounts.push(accountName); // 折叠
      }
    },


    toggleAllAlgoAccounts() {
      if (this.$device.isMobile) {
        // 移动端逻辑：折叠或展开所有算法账户卡片
        if (this.collapsedAlgoAccounts.length === 0) {
          // 如果没有折叠的账户，展开所有
          this.collapsedAlgoAccounts = Object.keys(this.groupedAlgoOrders);
        } else {
          // 如果有折叠的账户，折叠所有
          this.collapsedAlgoAccounts = [];
        }
      } else {
        // 网页端逻辑：隐藏或显示算法账户表格
        this.isAlgoTableHidden = !this.isAlgoTableHidden;
      }
    },



  },
};
</script>


<style scoped>
.order-list {
  margin: auto;
}

.hidden {
  display: none;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-top: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 18px;
}

.button-group {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

button {
  padding: 8px 16px;
  background-color: rgba(163, 149, 149, 0.2); /* 灰色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049; /* 鼠标悬停时的颜色 */
}

.order-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.order-list th, .order-list td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
}

.order-list th {
  background-color: #000000;
  color: #FFFFFF;
}

/* 模态框 */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
}

/* 模态框内容 */
.modal-content {
  background-color: #000000;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  width: 90%; /* 调整弹窗宽度 */
  max-width: 500px; /* 最大宽度 */
  height: auto; /* 调整弹窗高度 */
  display: flex;
  flex-direction: column;
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.input-field {
  height: 30px; /* 调整输入框高度 */
  padding: 8px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #ffffff;
  background-color: rgba(163, 149, 149, 0.2);
  width: 70%;
}
.amend-button {
  font-size: 18px;
  padding: 10px;
  margin-top: 20px;
  width: 100%; /* 放大按钮 */
}

/* 移动端适配 */
@media (max-width: 600px) {
  .desktop-table {
    display: none;
  }

  .mobile-cards {
    display: block;
  }

  .account-card {
    margin-bottom: 20px;
  }

  .account-header {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    text-align: center;

  }

  .order-card {
    border: 1px solid #ffffff;
    padding: 10px;
    background-color: #111;
    margin-bottom: 10px;
  }

  .order-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 设置为2列布局 */
    gap: 5px; /* 设置卡片之间的间距 */
  }

  .order-item {
    display: flex;
    flex-direction: column;

  }

  .order-item strong {
    margin-bottom: auto;
  }

  .order-card button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px; /* 增加圆角 */
    transition: background-color 0.3s; /* 增加过渡效果 */
  }

  .order-card button:hover {
    background-color: #333333;
  }

  /* 移动端模态框适配 */
  .modal-content {
    width: 90%; /* 调整弹窗宽度 */
    max-width: 100%; /* 最大宽度 */
    height: auto; /* 高度自适应 */
    padding: 15px; /* 增加内边距 */
    font-size: 14px; /* 调整字体大小 */
  }

  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 50px;
    font-size: 18px;
  }

  .input-field {
    height: 20px; /* 调整输入框高度 */
    font-size: 18px; /* 调整输入框字体大小 */
    width: 50%;
  }

  .amend-button {
    font-size: 16px; /* 调整按钮字体大小 */
    padding: 8px; /* 调整按钮内边距 */
  }

  .close {
    font-size: 24px; /* 调整关闭按钮的大小 */
  }
}

/* 网页端适配 */
@media (min-width: 601px) {
  .mobile-cards {
    display: none;
  }
}
</style>

