<template>
  <div class="card-container">

    <button @click="toggleTradingPanel" class="toggle-button">
<!--      {{ isTradingPanelVisible ? '隐藏交易面板' : '显示交易面板' }}-->
      {{ isTradingPanelVisible ? 'hidden' : 'show' }}
    </button>

    <button @click="logout">logout</button>
    <button @click="GetAllApi">GetAllData</button>

<!--    <button @click="toggleUpdateMethod">更新方式:{{ updateMethod }}</button>-->
    <button @click="toggleUpdateMethod">{{ updateMethod }}</button>
    <div
        v-for="account in accounts"
        :key="account.value"
        :class="['card', { active: activeAccounts.includes(account.value) }]"
        @click="toggleAccount(account)"
    >
      <span class="card-text">{{ account.label }}</span>
      <span class="card-balance">{{ accountBalances[account.label] || 'N/A' }}</span>
      <div :class="['border', accountBorderClasses(account.label)]"></div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/services/websocket';
import api from '@/services/api';
import {inject, reactive} from 'vue';
import {useToast} from "vue-toastification";

export default {
  name: 'AccountBalanceCards',
  data() {

    return {
      error: null,
      isTradingPanelVisible: this.$device.isMobile ? false : true,
      accounts: [
        // { label: "账户1", value: "1" },
        // { label: "账户2", value: "2" },
        // { label: "账户3", value: "3" },
        // { label: "账户4", value: "4" },
        { label: "账户5", value: "5" },
        { label: "账户6", value: "6" },
        { label: "账户7", value: "7" },
      ],
      // activeAccounts: ["1", "2", "3", "4"],
      activeAccounts: ["5", "6", "7"],
      accountBalances: reactive({}), // 使用 reactive 确保响应性
      previousBalances: reactive({}), // 使用 reactive 确保响应性
      updateMethod: 'WebSocket',
      apiInterval: null,
      abortController: null, // 用于取消请求的控制器
      colorStates: {
        green: 'lightgreen',
        red: 'lightcoral',
      },
    }
  },

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },


  computed: {
    accountBorderClasses() {
      return (accountLabel) => {
        const currentBalance = this.accountBalances[accountLabel];
        // console.log(`Current balance for ${accountLabel}: ${currentBalance} (type: ${typeof currentBalance})`);
        const previousBalance = this.previousBalances[accountLabel];
        // console.log(`Previous balance for ${accountLabel}: ${previousBalance} (type: ${typeof previousBalance})`);
        const threshold = 0.01; // 设置一个阈值

        return {
          'green': currentBalance > previousBalance + threshold,
          'red': currentBalance < previousBalance - threshold,
          'default': currentBalance === previousBalance,
        };
      };
    }
  },
  mounted() {
    this.fetchDataViaAPI(this.activeAccounts)
    this.connectWebSocket();
    this.$nextTick(() => {
      this.$eventBus.emit('accounts-changed', this.activeAccounts);
      // console.log('Accounts changed event emitted:', this.activeAccounts);
      this.fetchDataForAccounts(this.activeAccounts);
    });
  },

  beforeUnmount() {
    this.removeWebSocketListeners();
    WebSocketService.disconnect();
    clearInterval(this.apiInterval);
    if (this.abortController) {
      this.abortController.abort(); // 取消未完成的API请求
    }
  },



  methods: {

    logout() {
      this.$emit('logout'); // 向父组件触发 logout 事件
    },
    GetAllApi() {
      this.fetchDataViaAPI(this.activeAccounts)
      this.$emit('GetAllApi', this.activeAccounts); // 向所有组件触发 GetAllApi 事件
      // console.log('GetAllApi', this.activeAccounts)
    },

    toggleTradingPanel() {
      this.isTradingPanelVisible = !this.isTradingPanelVisible;
      this.$emit('trading-panel-visibility-changed', this.isTradingPanelVisible);
    },

    toggleAccount(account) {
      const index = this.activeAccounts.indexOf(account.value);
      if (index > -1) {
        this.activeAccounts.splice(index, 1);
      } else {
        this.activeAccounts.push(account.value);
      }
      this.$eventBus.emit('accounts-changed', this.activeAccounts);
      this.fetchDataForAccounts(this.activeAccounts);
    },

    fetchDataForAccounts(accountValues) {
      if (this.updateMethod === 'WebSocket') {

        // WebSocketService.send('fetchData', { accounts: accountValues });
      } else {
        this.fetchDataViaAPI(accountValues);
      }
    },



    addWebSocketListeners() {
      WebSocketService.addListener('account', this.updateAccountBalance);
    },
    removeWebSocketListeners() {
      WebSocketService.removeListener('account', this.updateAccountBalance);
    },


    updateAccountBalance(data) {
      // console.log('WebSocket data received:', data); // 调试信息
      if (data && data.type === "account" && data.data) {
        // console.log('Previous balances before update:', this.previousBalances); // 调试信息
        this.previousBalances = { ...this.accountBalances }; // 保存当前余额
        for (const account in data.data) {
          const newBalance = parseFloat(data.data[account]);
          if (!isNaN(newBalance)) {
            this.accountBalances[account] = newBalance; // 直接赋值，确保响应性
            // console.log(`Updated balance for ${account}: ${newBalance}`); // 调试信息
          } else {
            console.warn(`Invalid balance for ${account}: ${data.data[account]}`); // 调试信息
          }
        }
        // console.log('Current balances after update:', this.accountBalances); // 调试信息
      } else {
        console.error('Received invalid account balance data:', data);
        this.error = "No data";
      }
    },

    toggleUpdateMethod() {
      this.updateMethod = this.updateMethod === 'WebSocket' ? 'API' : 'WebSocket';
      // console.log(`Switched to ${this.updateMethod} update method`);

      if (this.updateMethod === 'WebSocket') {
        this.connectWebSocket();
        clearInterval(this.apiInterval);
        if (this.abortController) {
          this.abortController.abort();
        }
        this.abortController = null;
      } else {
        this.removeWebSocketListeners();
        WebSocketService.disconnect();
        this.fetchDataViaAPI(this.activeAccounts);
        this.startApiPolling(this.activeAccounts);
      }

      this.$eventBus.emit('update-method-changed', this.updateMethod);
    },

    connectWebSocket() {
      WebSocketService.connect();
      this.addWebSocketListeners();
    },


    async fetchDataViaAPI(accountValues) {
      try {
        // 创建新的 AbortController
        this.abortController = new AbortController();

        const response = await api.getAccountBalance(accountValues, {
          signal: this.abortController.signal
        });

        this.previousBalances = { ...this.accountBalances };
        this.accountBalances = response.data;
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('API request canceled:', error.message);
        } else {
          this.error = "No data";
          console.error('Error fetching data via API:', error);
        }
      }
    },

    startApiPolling(accountValues) {
      this.apiInterval = setInterval(async () => {
        await this.fetchDataViaAPI(accountValues);
      }, 200);
    }
  },
}
</script>

<style scoped>
:root {
  --color-green: lightgreen;
  --color-red: lightcoral;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1px;
  margin-bottom: 3px;
  justify-content: center;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background-color: gray;
  color: white;
  cursor: pointer;
  user-select: none;
  min-width: 160px;
  max-width: 190px;
  text-align: center;
  position: relative;
}

.card.active {
  background-color: #0089ff;
}

.card:hover {
  transform: translateY(-5px);
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: border-color 0.3s ease, background 0.3s ease;
}

.border.green {
  border-color: var(--color-green);
  background: linear-gradient(to bottom, rgba(144, 238, 144, 0.5), transparent);
}

.border.red {
  border-color: var(--color-red);
  background: linear-gradient(to bottom, rgba(255, 99, 71, 0.5), transparent);
}

.border.default {
  border-color: transparent;
}

.card-text {
  font-size: 16px;
  margin-right: 5px;
}

.card-balance {
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -20px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .card {
    width: calc(40% + 8px);
    min-width: 0;
    max-width: none;
    margin-bottom: -1px;
  }

  .card-text,
  .card-balance {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 10px;
  }

  .card-text,
  .card-balance {
    font-size: 16px;
  }
}
</style>

















<!--<template>-->
<!--  <div class="card-container">-->
<!--    <button @click="toggleTradingPanel" class="toggle-button">-->
<!--      {{ isTradingPanelVisible ? '隐藏交易面板' : '显示交易面板' }}-->
<!--    </button>-->
<!--    <button @click="toggleUpdateMethod">切换更新方式为: {{ updateMethod }}</button>-->
<!--    <div-->
<!--        v-for="account in accounts"-->
<!--        :key="account.value"-->
<!--        :class="['card', { active: activeAccounts.includes(account.value) }]"-->
<!--        @click="toggleAccount(account)"-->
<!--    >-->
<!--      <span class="card-text">{{ account.label }}</span>-->
<!--      <span class="card-balance">{{ accountBalances[account.label] || 'N/A' }}</span>-->
<!--      <div :class="['border', { 'green': accountBalances[account.label] > previousBalances[account.label], 'red': accountBalances[account.label] < previousBalances[account.label] }]"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import WebSocketService from '@/services/websocket';-->
<!--import api from '@/services/api';-->

<!--export default {-->
<!--  name: 'AccountBalanceCards',-->
<!--  data() {-->
<!--    return {-->
<!--      // isTradingPanelVisible: true, // 用于控制交易面板的显示状态-->
<!--      isTradingPanelVisible: this.$device.isMobile ? false : true, // 根据设备类型设置初始状态-->

<!--      accounts: [-->
<!--        // { label: "账户1", value: "1" },-->
<!--        // { label: "账户2", value: "2" },-->
<!--        // { label: "账户3", value: "3" },-->
<!--        // { label: "账户4", value: "4" },-->
<!--        { label: "账户5", value: "5" },-->
<!--        { label: "账户6", value: "6" },-->
<!--        { label: "账户7", value: "7" },-->
<!--      ],-->
<!--      activeAccounts: ["5", "6", "7"],-->
<!--      accountBalances: {},-->
<!--      previousBalances: {},-->
<!--      updateMethod: 'WebSocket',-->
<!--      apiInterval: null,-->
<!--    }-->
<!--  },-->
<!--  mounted() {-->
<!--    this.connectWebSocket();-->
<!--    this.$nextTick(() => {-->
<!--      this.$eventBus.emit('accounts-changed', this.activeAccounts);-->
<!--      this.fetchDataForAccounts(this.activeAccounts);-->
<!--    });-->
<!--  },-->
<!--  beforeUnmount() {-->
<!--    this.removeWebSocketListeners();-->
<!--    WebSocketService.disconnect();-->
<!--    clearInterval(this.apiInterval);-->
<!--  },-->
<!--  methods: {-->

<!--    toggleTradingPanel() {-->
<!--      this.isTradingPanelVisible = !this.isTradingPanelVisible;-->
<!--      this.$emit('trading-panel-visibility-changed', this.isTradingPanelVisible);-->
<!--    },-->



<!--    toggleAccount(account) {-->
<!--      const index = this.activeAccounts.indexOf(account.value);-->
<!--      if (index > -1) {-->
<!--        this.activeAccounts.splice(index, 1);-->
<!--      } else {-->
<!--        this.activeAccounts.push(account.value);-->
<!--      }-->
<!--      this.$eventBus.emit('accounts-changed', this.activeAccounts);-->
<!--      this.fetchDataForAccounts(this.activeAccounts);-->
<!--    },-->

<!--    fetchDataForAccounts(accountValues) {-->
<!--      if (this.updateMethod === 'WebSocket') {-->
<!--        WebSocketService.send('fetchData', { accounts: accountValues });-->
<!--      } else {-->
<!--        this.fetchDataViaAPI(accountValues);-->
<!--      }-->
<!--    },-->

<!--    fetchDataViaAPI(accountValues) {-->
<!--      api.getAccountBalance(accountValues)-->
<!--          .then(response => {-->
<!--            console.log('API response:', response.data);-->
<!--            this.previousBalances = { ...this.accountBalances };-->
<!--            this.accountBalances = response.data; // API 数据格式直接赋值-->
<!--          })-->
<!--          .catch(error => {-->
<!--            console.error('Error fetching data via API:', error);-->
<!--          });-->
<!--    },-->

<!--    addWebSocketListeners() {-->
<!--      WebSocketService.addListener('account', this.updateAccountBalance);-->
<!--    },-->
<!--    removeWebSocketListeners() {-->
<!--      WebSocketService.removeListener('account', this.updateAccountBalance);-->
<!--    },-->
<!--    updateAccountBalance(data) {-->
<!--      if (data && data.type === "account" && data.data) {-->
<!--        // 更新 previousBalances-->
<!--        this.previousBalances = { ...this.accountBalances };-->

<!--        // 处理 WebSocket 数据格式-->
<!--        for (const account in data.data) {-->
<!--          this.accountBalances[account] = parseFloat(data.data[account]); // 将字符串转换为数字-->
<!--        }-->
<!--      } else {-->
<!--        console.error('Received invalid account balance data:', data);-->
<!--      }-->
<!--    },-->

<!--    toggleUpdateMethod() {-->
<!--      this.updateMethod = this.updateMethod === 'WebSocket' ? 'API' : 'WebSocket';-->
<!--      console.log(`Switched to ${this.updateMethod} update method`);-->

<!--      if (this.updateMethod === 'WebSocket') {-->
<!--        this.connectWebSocket();-->
<!--        clearInterval(this.apiInterval);-->
<!--      } else {-->
<!--        this.removeWebSocketListeners();-->
<!--        WebSocketService.disconnect();-->
<!--        this.fetchDataViaAPI(this.activeAccounts);-->
<!--        this.startApiPolling(this.activeAccounts);-->
<!--      }-->

<!--      this.$eventBus.emit('update-method-changed', this.updateMethod);-->
<!--    },-->

<!--    connectWebSocket() {-->
<!--      WebSocketService.connect();-->
<!--      this.addWebSocketListeners();-->
<!--    },-->

<!--    startApiPolling(accountValues) {-->
<!--      this.apiInterval = setInterval(() => {-->
<!--        this.fetchDataViaAPI(accountValues);-->
<!--      }, 200);-->
<!--    }-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.card-container {-->
<!--  display: flex;-->
<!--  flex-wrap: wrap;-->
<!--  gap: 10px;-->
<!--  margin-top: 1px;-->
<!--  margin-bottom: 3px;-->
<!--  justify-content: center;-->
<!--}-->

<!--.card {-->
<!--  display: flex;-->
<!--  justify-content: space-between;-->
<!--  align-items: center;-->
<!--  padding: 15px;-->
<!--  border-radius: 8px;-->
<!--  background-color: gray;-->
<!--  color: white;-->
<!--  cursor: pointer;-->
<!--  user-select: none;-->
<!--  min-width: 160px;-->
<!--  max-width: 190px;-->
<!--  text-align: center;-->
<!--  position: relative;-->
<!--}-->

<!--.card.active {-->
<!--  background-color: #0089ff;-->
<!--}-->

<!--.card:hover {-->
<!--  transform: translateY(-5px);-->
<!--  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);-->
<!--}-->

<!--.border {-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  border-radius: 8px;-->
<!--  box-sizing: border-box;-->
<!--  border: 2px solid transparent;-->
<!--  transition: border-color 0.3s ease;-->
<!--  will-change: border-color; /* 启用硬件加速 */-->
<!--}-->

<!--.border.green {-->
<!--  border-color: lightgreen;-->
<!--  background: linear-gradient(to bottom, rgba(144, 238, 144, 0.5), transparent);-->
<!--}-->

<!--.border.red {-->
<!--  border-color: lightcoral;-->
<!--  background: linear-gradient(to bottom, rgba(255, 99, 71, 0.5), transparent);-->
<!--}-->

<!--.card-text {-->
<!--  font-size: 16px;-->
<!--  margin-right: 5px;-->
<!--}-->

<!--.card-balance {-->
<!--  font-size: 18px;-->
<!--  font-weight: bold;-->
<!--}-->

<!--@media (max-width: 768px) {-->
<!--  .card-container {-->
<!--    flex-direction: row;-->
<!--    flex-wrap: wrap;-->
<!--    justify-content: space-between;-->
<!--    margin-bottom: -20px;-->
<!--    margin-left: 5px;-->
<!--    margin-right: 5px;-->

<!--  }-->


<!--  .card {-->
<!--    width: calc(40% + 8px);-->
<!--    min-width: 0;-->
<!--    max-width: none;-->
<!--    margin-bottom: -1px;-->
<!--  }-->

<!--  .card-text,-->
<!--  .card-balance {-->
<!--    font-size: 18px;-->
<!--  }-->
<!--}-->

<!--@media (max-width: 480px) {-->
<!--  .card {-->
<!--    padding: 10px;-->
<!--  }-->

<!--  .card-text,-->
<!--  .card-balance {-->
<!--    font-size: 16px;-->
<!--  }-->
<!--}-->
<!--</style>-->

