<template>
  <div class="order-list">

    <div class="section-header">
      <h3 @click="toggleAllTradeFillsAccounts">
        成交明细3D ({{ totalTradeFillsCount }})
      </h3>

      <!-- 新增的产品ID筛选下拉框 -->
      <div v-if="!$device.isMobile" class="filter-container">
        <label for="product-filter-trade">产品ID</label>
        <select id="product-filter-trade" v-model="selectedTradeFillsProductId" @change="filterTradeFills">
          <option value="">ALL</option>
          <option v-for="id in uniqueTradeFillsProductIds" :key="id" :value="id">{{ id }}</option>
        </select>
      </div>

      <div v-if="!$device.isMobile" class="filter-container">
        <!-- 新增的成交类型筛选下拉框 -->
        <label for="subtype-filter-trade">成交类型</label>
        <select id="subtype-filter-trade" v-model="selectedTradeFillsSubType" @change="filterTradeFills">
          <option value="">ALL</option>
          <option v-for="type in uniqueTradeFillsSubTypes" :key="type" :value="type">{{ formatsubType({ subType: type }) }}</option>
        </select>

      </div>

      <div v-for="(count, account) in tradeFillsCount" :key="account">
        <span v-if="!$device.isMobile">账户{{ account }}: {{ count }} 个成交</span>
      </div>

      <div class="button-group">
        <button @click="fetchtradefills3D">获取成交明细3D</button>
      </div>

    </div>

    <table :class="{ 'desktop-table': true, 'hidden': isTableHidden }">
      <thead>
      <tr>
        <th @click="sortBy('account')">
          账户
          <span v-if="sortKey.includes('account')">{{ sortOrders['account'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('instId')">
          产品ID
          <span v-if="sortKey.includes('instId')">{{ sortOrders['instId'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('execType')">
          流动性方向
          <span v-if="sortKey.includes('execType')">{{ sortOrders['execType'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('subType')">
          成交类型
          <span v-if="sortKey.includes('subType')">{{ sortOrders['subType'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('side')">
          订单持仓方向
          <span v-if="sortKey.includes('side')">{{ sortOrders['side'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('fillPnl')">
          最新成交收益
          <span v-if="sortKey.includes('fillPnl')">{{ sortOrders['fillPnl'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('fillSz')">
          最新成交数量
          <span v-if="sortKey.includes('fillSz')">{{ sortOrders['fillSz'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('fillPx')">
          最新成交价格
          <span v-if="sortKey.includes('fillPx')">{{ sortOrders['fillPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('fee')">
          手续费
          <span v-if="sortKey.includes('fee')">{{ sortOrders['fee'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sortBy('fillTime')">
          成交时间
          <span v-if="sortKey.includes('fillTime')">{{ sortOrders['fillTime'] === 'asc' ? '▲' : '▼' }}</span>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr v-if="tradefills.length === 0">
        <td colspan="12">暂无成交明细（近三天）</td>
      </tr>
      <tr v-for="fill in filteredTradeFills" :key="fill.fillId">
        <td>{{ fill.account }}</td>
        <td>{{ fill.instId }}</td>
        <td>{{ execType(fill)}}</td>
        <td>{{ formatsubType(fill) }}</td>
        <td>{{ fill.side}} - {{ fill.posSide }}</td>
<!--        <td>{{ parseFloat(fill.fillPnl).toFixed(2) }}</td>-->
        <td :style="{ color: parseFloat(fill.fillPnl) < 0 ? 'red' : 'white' }">
          {{ parseFloat(fill.fillPnl).toFixed(2) }}
        </td>

        <td>{{ fill.fillSz }}</td>
        <td>{{ fill.fillPx }}</td>
        <td>{{ parseFloat(fill.fee).toFixed(2)}}</td>
        <td>{{ formatDate(fill.fillTime) }}</td>
      </tr>
      </tbody>
    </table>


    <div class="mobile-cards">

      <!-- 新增的产品ID筛选下拉框 -->
      <!-- 移动端下拉框 -->
      <div v-if="$device.isMobile" class="filter-container">
        <label for="product-filter-trade-mobile">ID</label>
        <select id="product-filter-trade-mobile" v-model="selectedTradeFillsProductId">
          <option value="">ALL</option>
          <option v-for="id in uniqueTradeFillsProductIds" :key="id" :value="id">{{ id }}</option>
        </select>
        <!-- 新增的成交类型筛选下拉框 -->
        <label for="subtype-filter-trade">成交类型</label>
        <select id="subtype-filter-trade" v-model="selectedTradeFillsSubType" @change="filterTradeFills">
          <option value="">ALL</option>
          <option v-for="type in uniqueTradeFillsSubTypes" :key="type" :value="type">{{ formatsubType({ subType: type }) }}</option>
        </select>

      </div>


      <div v-for="(accountTradeFills, accountName) in groupedTradeFills" :key="accountName" class="account-card">
        <h3 @click="toggleTradeFillsAccount(accountName)" class="account-header">
          账户{{ accountName }} ({{ accountTradeFills.length }})
        </h3>
        <div v-if="!collapsedTradeFillsAccounts.includes(accountName)">
          <div v-for="fill in accountTradeFills" :key="fill.fillId" class="order-card">
            <div class="order-grid">
              <div class="order-item">
                <strong>{{ fill.instId }}</strong>{{ execType(fill) }}
              </div>
              <div class="order-item">
                <strong>{{ formatsubType(fill) }} {{ fill.side }} {{ fill.posSide }}</strong>数量: {{ fill.fillSz }}
              </div>
              <div class="order-item" :style="{ color: parseFloat(fill.fillPnl) < 0 ? 'red' : 'white' }">
                <strong>收益: {{ parseFloat(fill.fillPnl).toFixed(2) }}</strong>
              </div>
              <div class="order-item">
                <strong> 手续费：{{ parseFloat(fill.fee).toFixed(2) }} </strong>
              </div>
              <div class="order-item">
                <strong>价格: {{ fill.fillPx }}</strong>
              </div>
              <div class="order-item">
                <strong> {{ formatDate(fill.fillTime) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>

</template>


<script>
import api from '@/services/api';
import WebSocketService from '@/services/websocket';
import { useToast } from "vue-toastification";
import { inject } from 'vue';

export default {
  name: 'tradeFills',

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },

  data() {
    return {
      lastFetchOrderList: 0,
      tradefills: [], // 成交明细数据
      collapsedTradeFillsAccounts: [], // 用于存储成交明细的折叠状态

      selectedTradeFillsProductId: '', // 成交明细筛选的产品ID
      selectedTradeFillsSubType: '', // 成交类型筛选

      selectedAccounts: [],
      isTableHidden: true, // 用于存储表格是否隐藏的状态
      // 新增数据
      sortKey: ['fillTime'], // 默认按成交时间排序
      sortOrders: { fillTime: 'desc' } // 默认倒序

    };
  },


  // 在创建时注册事件监听
  created() {
    // 监听账户变化事件
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    // 监听订单更新事件，调用处理成交明细3D更新函数
    WebSocketService.addListener('order_filled', this.fetchtradefills3DUpdate);
    WebSocketService.addListener('algo_order_effective', this.fetchtradefills3DUpdate);
    WebSocketService.addListener('algo_advance_effective', this.fetchtradefills3DUpdate);
  },

  // 在卸载时移除事件监听
  beforeUnmount() {
    // 移除账户变化事件监听
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    // 移除订单更新事件监听，，调用处理成交明细3D更新函数
    WebSocketService.removeListener('order_filled', this.fetchtradefills3DUpdate);
    WebSocketService.removeListener('algo_order_effective', this.fetchtradefills3DUpdate);
    WebSocketService.removeListener('algo_advance_effective', this.fetchtradefills3DUpdate);
  },



  computed: {

    totalTradeFillsCount() {
      return this.tradefills.length;
    },
    uniqueTradeFillsProductIds() {
      const ids = new Set(this.tradefills.map(fill => fill.instId));
      return Array.from(ids);
    },


    uniqueTradeFillsSubTypes() {
      const types = new Set(this.tradefills.map(fill => fill.subType));
      return Array.from(types);
    },

    filteredTradeFills() {
      let fills = this.tradefills.filter(fill =>
          (this.selectedTradeFillsProductId === "" || fill.instId === this.selectedTradeFillsProductId) &&
          (this.selectedTradeFillsSubType === "" || fill.subType === this.selectedTradeFillsSubType)
      );

      // 多列排序逻辑
      fills.sort((a, b) => {
        for (let key of this.sortKey) {
          let order = this.sortOrders[key] === 'asc' ? 1 : -1;
          if (a[key] > b[key]) return order;
          if (a[key] < b[key]) return -order;
        }
        return 0;
      });

      return fills;
    },



    groupedTradeFills() {
      const grouped = {};
      this.filteredTradeFills.forEach(fill => {
        if (!grouped[fill.account]) {
          grouped[fill.account] = [];
        }
        grouped[fill.account].push(fill);
      });
      return grouped;
    },
    tradeFillsCount() {
      const counts = {};
      this.filteredTradeFills.forEach(fill => {
        counts[fill.account] = (counts[fill.account] || 0) + 1;
      });
      return counts;
    },
  },

  methods: {

    sortBy(key) {
      if (this.sortKey.includes(key)) {
        // 切换排序顺序
        this.sortOrders[key] = this.sortOrders[key] === 'asc' ? 'desc' : 'asc';
      } else {
        // 新列加入排序条件，但确保成交时间始终保持在排序条件中
        this.sortKey = this.sortKey.filter(k => k !== 'fillTime');
        this.sortKey.push(key);
        this.$set(this.sortOrders, key, 'asc');
      }

      // 始终确保成交时间在最后按倒序排序
      if (!this.sortKey.includes('fillTime')) {
        this.sortKey.push('fillTime');
        this.sortOrders['fillTime'] = 'desc';
      }
    },


    // 处理成交明细3D更新函数
    fetchtradefills3DUpdate(data) {
      // console.log('fetchtradefills3D update received in OrderList component:', data);
      // 如果距离上次获取订单列表的时间超过 1500 毫秒，则重新获取订单列表
      if (Date.now() - this.lastFetchOrderList > 1500) {
        this.lastFetchOrderList = Date.now();
        // console.log('Fetching algo order list...'); // 添加调试日志
        // 重新获取算法订单列表
        this.fetchtradefills3D();
      }
    },

    toggleTradeFillsAccount(accountName) {
      const index = this.collapsedTradeFillsAccounts.indexOf(accountName);
      if (index > -1) {
        this.collapsedTradeFillsAccounts.splice(index, 1); // 展开
      } else {
        this.collapsedTradeFillsAccounts.push(accountName); // 折叠
      }
    },

    toggleAllTradeFillsAccounts() {
      if (this.$device.isMobile) {
        // 移动端逻辑：折叠或展开所有账户卡片
        if (this.collapsedTradeFillsAccounts.length === 0) {
          // 如果没有折叠的账户，展开所有
          this.collapsedTradeFillsAccounts = Object.keys(this.groupedTradeFills);
        } else {
          // 如果有折叠的账户，折叠所有
          this.collapsedTradeFillsAccounts = [];
        }
      } else {
        // 网页端逻辑：隐藏或显示表格
        this.isTableHidden = !this.isTableHidden;
      }
    },

    filterTradeFills() {
      this.filteredTradeFills; // 触发计算属性更新
    },// }

    // 当选择账户发生变化时触发
    async onAccountsChanged(accounts) {
      // console.log('Accounts changed in OrderList:', accounts);
      // 保存新的账户选择
      this.selectedAccounts = [...accounts];
      // 重新获取成交明细（近三天）
      await this.fetchtradefills3D();
    },

    // 获取成交明细（近三天）
    async fetchtradefills3D() {
      // 重新获取成交明细
      if (this.selectedAccounts.length === 0) return;

      try {
        const fillPromises = this.selectedAccounts.map(async (account) => {
          const response = await api.gettradefills(account);
          if (response.data && Array.isArray(response.data.data)) {
            return response.data.data.map(fills => ({ ...fills, account }));
          } else if (response.data && Array.isArray(response.data)) {
            return response.data.map(fills => ({ ...fills, account }));
          } else {
            return [];
          }
        });
        const tradefillsArray = await Promise.all(fillPromises);
        this.tradefills = tradefillsArray.flat();

        // 更新折叠状态
        this.collapsedTradeFillsAccounts = Object.keys(this.groupedTradeFills);
      } catch (error) {
        this.toast.error('获取成交明细（近三天）失败');
      }
    },

    // 根据订单信息，设置成交类型
    formatsubType(fill) {
      const subTypeMap = {
        '1': '买入', '2': '卖出', '3': '开多', '4': '开空', '5': '平多', '6': '平空',
        '100': '强减平多', '101': '强减平空', '102': '强减买入', '103': '强减卖出',
        '104': '强平平多', '105': '强平平空', '106': '强平买入', '107': '强平卖出',
        '110': '强平换币转入', '111': '强平换币转出', '112': '交割平多', '113': '交割平空', '118': '系统换币转入', '119': '系统换币转出',
        '125': '自动减仓平多', '126': '自动减仓平空', '127': '自动减仓买入', '128': '自动减仓卖出',
        '212': '一键借币的自动借币', '213': '一键借币的自动还币',
        '204': '大宗交易买', '205': '大宗交易卖', '206': '大宗交易开多', '207': '大宗交易开空',
        '208': '大宗交易平多', '209': '大宗交易平空', '270': '价差交易买', '271': '价差交易卖',
        '272': '价差交易开多', '273': '价差交易开空', '274': '价差交易平多', '275': '价差交易平空'
      };
      return subTypeMap[fill.subType] ? fill.subType + subTypeMap[fill.subType] : fill.subType;
    },

    // 根据订单信息，设置流动性方向
    execType(fill) {
      const execMap = {
        'T': 'Taker吃单', 'M': 'Maker挂单'
      };
      return execMap[fill.execType] ? execMap[fill.execType] : fill.execType;
    },

    formatDate(timestamp) {
      const date = new Date(parseInt(timestamp));
      return date.toLocaleString();
    },


  },
};
</script>


<style scoped>
.order-list {
  margin: auto;
}
th {
  cursor: pointer;
}
th span {
  margin-left: 5px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-top: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 18px;
  cursor: pointer;
}

.hidden {
  display: none; /* 用于隐藏元素 */
}

.button-group {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

button {
  padding: 8px 16px;
  background-color: rgba(163, 149, 149, 0.2); /* 灰色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049; /* 鼠标悬停时的颜色 */
}

.order-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.order-list th, .order-list td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
}

.order-list th {
  background-color: #000000;
  color: #FFFFFF;
}

/* 移动端适配 */
@media (max-width: 600px) {
  .desktop-table {
    display: none;
  }

  .mobile-cards {
    display: block;
  }

  .account-card {
    margin-bottom: 1px;
  }

  .account-header {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }

  .order-card {
    border: 1px solid #ffffff;
    padding: 10px;
    background-color: #111;
    margin-bottom: 10px;
  }

  .order-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 设置为2列布局 */
    gap: 0.1px; /* 设置卡片之间的间距 */
  }

  .order-item {
    display: flex;
    flex-direction: column;
  }

  .order-item strong {
    margin-bottom: auto;
  }

  .order-card button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px; /* 增加圆角 */
    transition: background-color 0.3s; /* 增加过渡效果 */
  }

  .order-card button:hover {
    background-color: #333333;
  }
}

/* 网页端适配 */
@media (min-width: 601px) {
  .mobile-cards {
    display: none;
  }
}
</style>



