<template>
  <div class="modal-overlay" @keydown.esc="close">
    <div class="modal-content" @click.stop>
      <h3>Enter the Email here</h3>
      <input
          v-model="email"
          class="input"
          type="email"
          autocomplete="email"
          placeholder="@**.com"
          required
          @input="validateEmail"

      />

      <button
          @click="getVerificationCode"
          class="submit-btn"
          :disabled="!isValidEmail || timer > 0"
      >
        {{ timer > 0 ? `Get Code (${timer}s)` : 'Get Code' }}
      </button>

      <h3>Enter the 8-digit code here</h3>

      <div class="otp-inputs">
        <input
            v-for="(value, index) in otpInputs"
            :key="index"
            v-model="otpInputs[index]"
            maxlength="1"
            @input="onInput(index)"
            @keydown="onKeyDown(index, $event)"
            ref="inputRefs"
            class="otp-input"
            type="password"
            autocomplete="off"
            @keydown.enter="validateOtp"
        />
      </div>

      <button @click="validateOtp" class="submit-btn">Verify</button>
      <button @click="close" class="cancel-btn">Close</button>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import forge from 'node-forge';
import {useToast} from "vue-toastification";
import {inject} from "vue";
import {mapActions} from "vuex";

export default {
  name: "LoginOtpModal",
  data() {
    return {
      email: '',
      otpInputs: ["", "", "", "", "", "", "", ""],
      isValidEmail: true,
      timer: 0,
    };
  },


  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },



  methods: {
    ...mapActions(['verifyOtp', 'closeLogout']),

    async validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.email);
    },

    async getVerificationCode() {
      await this.validateEmail();
      if (!this.isValidEmail) {
        this.toast.error("Enter a valid email address");
        this.$emit("error", "Please enter a valid email address");
        return;
      }

      const response = await api.getVerificationCode({
        email: this.email
      });

      if (response.data.code === 200) {
        this.toast.success(response.data.msg);
        this.startTimer();
      } else {
        this.toast.error(response.data.msg);
        this.$emit("error", response.data.msg);
      }
    },

    startTimer() {
      this.timer = 30; // 30 seconds countdown
      const countdown = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(countdown);
        }
      }, 1000);
    },


    // 聚焦第一个输入框
    focusFirstInput() {
      this.$nextTick(() => {
        if (Array.isArray(this.$refs.inputRefs) && this.$refs.inputRefs[0]) {
          this.$refs.inputRefs[0].focus();
        }
      });
    },

    // 输入事件，自动跳转到下一个输入框
    onInput(index) {
      if (this.otpInputs[index].length === 1 && index < 7) {
        this.$refs.inputRefs[index + 1].focus();
      }
    },

    // 键盘按下事件，处理退格键和其他键盘事件
    onKeyDown(index, event) {
      if (event.key === 'Backspace') {
        this.onBackspace(index);
      }
    },

    // 退格事件，自动返回前一个输入框
    onBackspace(index) {
      if (this.otpInputs[index] === "" && index > 0) {
        this.otpInputs[index - 1] = ""; // 清空上一个输入框的内容
        this.$nextTick(() => {
          this.$refs.inputRefs[index - 1].focus(); // 聚焦到前一个输入框
        });
      } else {
        this.otpInputs[index] = ""; // 如果当前输入框有内容，直接清空
      }
    },


    // 密码验证
    async validateOtp() {
      const OTP = this.otpInputs.join("");

      if (OTP.length === 0) {
        this.$emit("error", "无效");
        this.clearOtp();
        return;
      }

      try {
        // // 获取公钥
        // const publicKeyResponse = await api.getPublicKey();
        // const publicKey = publicKeyResponse?.public_key;

        // 从 sessionStorage 获取公钥
        const publicKey = sessionStorage.getItem('public_key');
        const LoginUsername = sessionStorage.getItem('LoginUsername');

        if (!publicKey) {
          this.$emit("error", "无法获取加密公钥，请稍后重试");
          this.clearOtp();
          return;
        }

        // 生成nonce和时间戳
        const nonce = forge.util.bytesToHex(forge.random.getBytesSync(16)); // 生成16字节的随机字符串作为nonce
        const timestamp = Math.floor(Date.now() / 1000); // 当前时间戳（秒）

        // 1. 生成对称密钥（AES密钥）
        const aesKey = forge.random.getBytesSync(32); // 256位AES密钥
        const iv = forge.random.getBytesSync(16); // 16字节的初始向量

        // 2. 用AES密钥加密交易密码
        const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
        cipher.start({ iv: iv });
        cipher.update(forge.util.createBuffer(OTP, 'utf8'));
        cipher.finish();
        const encryptedOtp = forge.util.encode64(iv + cipher.output.getBytes()); // IV + 密文

        // 3. 用RSA公钥加密AES密钥
        const rsa = forge.pki.publicKeyFromPem(publicKey);
        const encryptedSymmetricKey = forge.util.encode64(rsa.encrypt(aesKey, 'RSA-OAEP'));


        const OtpData = {
          encryptedSymmetricKey: encryptedSymmetricKey,
          encryptedOtp: encryptedOtp,
          nonce: nonce,
          timestamp: timestamp,
          username: LoginUsername,

        };

        await this.verifyOtp({ OtpData });
        await this.$router.push('/home');
        this.clearOtp();

      } catch (error) {
        console.error("验证otp失败:", error);
        this.toast.error(error.message || "otp验证失败，请稍后再试")
        this.$emit("error", "otp验证失败，请稍后再试");
        this.clearOtp();
        // await this.$router.push('/Error');
      }
    },


    // 清除密码输入
    clearOtp() {
      this.otpInputs = ["", "", "", "", "", "", "", ""];
      // this.email = '';
      this.focusFirstInput(); // 清空后聚焦第一个输入框
    },

    // 关闭弹窗
    async close() {
      this.clearOtp();
      this.$emit("close");
      await this.closeLogout();
      await this.$router.push('/login');
    }
  },

};
</script>


<style>
/* Modal overlay and styling for better UX */
.modal-overlay {
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;

}
.modal-content {
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  margin-bottom: auto;
}
.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
}
.input {
  width: 300px;
  height: auto;
  text-align: center;
  font-size: 22px;
  margin-bottom: 5px;
}
.submit-btn,
.cancel-btn {
  margin-top: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border: none;
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  color: #000000;
}



/* Mobile-specific styles */
@media (max-width: 480px) {
  .modal-content {
    max-width: 90%; /* Adjust modal width to fit smaller screens */
    padding: 15px;  /* Reduce padding for mobile */
  }


  .otp-input {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  /* Password inputs */
  .otp-inputs {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line */
    gap: 3px;       /* Space between input fields */
  }

  /* Ensure 4 inputs per row */
  .otp-inputs .otp-input {
    flex: 1 1 20%; /* Each input takes 20% of the width to fit 4 in one row with spacing */
  }

}


</style>
