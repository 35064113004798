<template>
  <div class="login-container">
    <h3>Login</h3>
    <form @submit.prevent="handleLogin">

      <input v-model="username"
             class="input-login"
             type="text"
             autocomplete="off"
             placeholder="username"
             required
      />

      <input v-model="password"
             class="input-login"
             type="password"
             autocomplete="off"
             placeholder="password"
             required
      />

      <button type="submit">Login</button>
    </form>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

  </div>
</template>

<script>
import {mapActions} from 'vuex';
import forge from 'node-forge';
import {useToast} from "vue-toastification";
import {inject} from "vue";

export default {
  data() {
    return {
      username: 'admin',
      password: 'admin',
      errorMessage: '',
    };
  },

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },

  methods: {
    ...mapActions(['login', "getPublicKey", "logout"]),

    async handleLogin() {

      const { password } = this;

      try {
        // 获取公钥
        const publicKey = await this.getPublicKey();
        if (!publicKey) {
          this.toast.error("无法获取加密公钥，请稍后重试");
          console.log("无法获取加密公钥，请稍后重试")
          // await this.clearLoginData()
          return;
        }

        // 生成nonce和时间戳
        const nonce = forge.util.bytesToHex(forge.random.getBytesSync(16)); // 生成16字节的随机字符串作为nonce
        const timestamp = Math.floor(Date.now() / 1000); // 当前时间戳（秒）

        // 生成对称密钥和IV
        const aesKey = forge.random.getBytesSync(32); // 256位AES密钥
        const ivPassword = forge.random.getBytesSync(16);

        // 用AES密钥加密密码
        const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
        cipher.start({ iv: ivPassword });
        cipher.update(forge.util.createBuffer(password, 'utf8'));
        cipher.finish();
        const encryptedPassword = forge.util.encode64(ivPassword + cipher.output.getBytes()); // IV + 密文

        // 用RSA公钥加密AES密钥
        const rsa = forge.pki.publicKeyFromPem(publicKey);
        const encryptedSymmetricKey = forge.util.encode64(rsa.encrypt(aesKey, 'RSA-OAEP'));

        const loginData = {
          encryptedSymmetricKey: encryptedSymmetricKey,
          username: this.username,
          password: encryptedPassword,
          nonce: nonce,
          timestamp: timestamp
        };

        await this.login({ loginData });

        await this.clearLoginData()

        await this.$router.push('/EmailCode');
      } catch (error) {
        console.error("登录失败:", error);
        this.toast.error(error.message || "登录失败，请重试");
        this.errorMessage = error.message || '登录失败';
        await this.clearLoginData()
      }
    },

    async clearLoginData() {
      this.username = '';
      this.password = '';
    },


  },
  name: 'Login',
};
</script>


<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px;
  max-width: 200px;
  margin-bottom: auto;
}

.input-login {
  margin: 5px 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  display: block;
  width: 100%;
  align-items: center;
}

.error-message {
  color: red;
}

@media (max-width: 600px) {
  .login-container {
    width: 90%;
  }
}
</style>
