import {createApp} from 'vue'
import App from './App.vue'
import './global.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {createRouter, createWebHashHistory} from 'vue-router'; // 引入 Vue Router
import Login from '@/components/Login.vue';
import cod from '@/components/cod.vue';
import ErrorPage from "@/components/ErrorPage.vue";
import store from './store/store';
import LoginOtpModal from "@/components/LoginOtpModal.vue"; // 引入 Vuex store

// 设备类型检测
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


// 定义路由规则
const routes = [
    { path: '/login', component: Login }, // 登录页面
    { path: '/EmailCode', component: LoginOtpModal }, // 验证码页面
    { path: '/home', component: cod },    // 主页面
    { path: '/', redirect: '/login' },    // 根路径重定向到登录页面
    { path: '/:pathMatch(.*)*', component: ErrorPage },
    { path: '/Error', component: ErrorPage },
];



// 创建路由实例
const router = createRouter({
    history: createWebHashHistory(),
    routes
});

const app = createApp(App);


// 使用 Vuex store
app.use(store);


// 使用路由
app.use(router);


// Toast 配置
// 定义一个对象options，用于存储通知的配置选项
const options = {
    // 通知的位置
    position: "top-left",
    // 通知显示的时间，单位毫秒
    timeout: 1500,
    // 点击通知时是否关闭
    closeOnClick: true,
    // 失去焦点时是否暂停
    pauseOnFocusLoss: true,
    // 鼠标悬停时是否暂停
    pauseOnHover: true,
    // 是否可拖动
    draggable: true,
    // 拖动时的比例
    draggablePercent: 0.6,
    // 鼠标悬停时是否显示关闭按钮
    showCloseButtonOnHover: true,
    // 是否隐藏进度条
    hideProgressBar: false,
    // 关闭按钮的类型
    closeButton: "icon",
    // 是否显示图标
    icon: true,
    // 是否右向左
    rtl: false
};

// const eventBus = mitt();

// 事件总线
const eventBus = {
    on(event, callback) {
        document.addEventListener(event, (e) => callback(e.detail));
    },
    emit(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    off(event, callback) {
        document.removeEventListener(event, callback);
    }
};

app.config.globalProperties.$eventBus = eventBus;

// 将设备类型作为全局属性提供给组件
app.config.globalProperties.$device = {
    isMobile: isMobile,
};

// 添加这一行，使 eventBus 在组件中可以通过 inject 使用
app.provide('eventBus', eventBus);

app.use(Toast, options);

app.mount('#app');

router.beforeEach((to, from, next) => {
    // 检查登录状态
    const isLoggedIn = store.state.isLoggedIn;
    const token = store.state.token

    // 如果未登录，且访问的不是登录页，重定向到登录页
    if (to.path !== '/login' && !isLoggedIn) {
        next('/login');
    }

    // else if (to.path === '/login' && isLoggedIn) {
    //     next('/EmailCode');
    // }

    // else if (to.path === '/home' && isLoggedIn && !token) {
    //     next('/EmailCode');
    // }

    // // 检查进入 '/home' 的来源是否是 '/EmailCode'
    // else if (to.path === '/home' && from.path !== '/EmailCode' && isLoggedIn && !token) {
    //     next('/EmailCode'); // 如果不是从 '/EmailCode' 来的，重定向到 '/EmailCode'
    // }

    else if (to.path === '/home' && from.path !== '/EmailCode' && !isLoggedIn && !token) {
        next('/login'); // 如果不是从 '/EmailCode' 来的，重定向到 '/EmailCode'
    }

    // // 检查进入 '/home' 的来源是否是 '/EmailCode'
    // else if (to.path === '/home' && from.path === '/EmailCode' && isLoggedIn && !token) {
    //     next('/EmailCode'); // 如果不是从 '/EmailCode' 来的，重定向到 '/EmailCode'
    // }

    // 其他情况，正常导航
    else {
        next();
    }
});



export default router;