<template>
  <div id="app">
    <router-view ref="router-view" @logout="handlelogout" />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import WebSocketService from '@/services/websocket';

export default {
  name: 'App',
  computed: {
    ...mapState(['isLoggedIn', 'loginTime']),
  },
  data() {
    return {
      interval: null, // 定时器ID
      // timeoutDuration: 10 * 60 * 1000 // 默认设置超时时间为10分钟（以毫秒为单位）
      // timeoutDuration: 60 * 1000 // 设置为1分钟
      timeoutDuration: 60 * 60 * 1000 // 设置为1小时
      // timeoutDuration: 30 * 60 * 1000, // 设置为30分钟
    };
  },

  mounted() {
    this.checkTimeout(); // 组件挂载时立即检查超时

    // 使用 setInterval 定时检查登录状态
    this.interval = setInterval(() => {
      this.checkTimeout();
    }, 10000); // 每隔10秒检查一次
  },

  beforeDestroy() {
    // 在组件销毁前清除定时器，避免内存泄漏
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {
    ...mapActions(['logout']),

    async checkTimeout() {
      if (this.isLoggedIn && this.loginTime) {
        const currentTime = new Date().getTime();
        const timeElapsed = currentTime - this.loginTime;

        if (timeElapsed >= this.timeoutDuration) {
          await this.logout(); // 超时自动登出
          WebSocketService.disconnect();
          this.$router.push('/login').then(() => {
            // window.location.reload(); // 在重定向后执行刷新
          });
        }
      }
    },

    async handlelogout() {
      await this.logout();
      WebSocketService.disconnect();
      this.$router.push('/login').then(() => {
        window.location.reload(); // 在重定向后执行刷新
      });
    }
  },
};
</script>

