<template>
  <div>
    <h1>无效访问</h1>
<!--    <p>你必须通过 /#/login 访问页面。</p>-->
  </div>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>
