<template>
  <div class="order-list">
    <div class="section-header">
      <h3 @click="toggleAllNormalAccounts" class="section-header">
        普通订单 ({{ totalOrdersCount }})
      </h3>

      <!-- 新增的产品ID筛选下拉框 -->
      <div v-if="!$device.isMobile" class="filter-container">
        <label for="product-filter-normal">产品ID</label>
        <select id="product-filter-normal" v-model="selectedNormalProductId" @change="filterNormalOrders">
          <option value="">ALL</option>
          <option v-for="id in uniqueNormalProductIds" :key="id" :value="id">{{ id }}</option>
        </select>
      </div>

      <!-- 模式方向杠杆筛选下拉框 -->
      <div v-if="!$device.isMobile" class="filter-container">
        <label for="mode-filter-normal">方向</label>
        <select id="mode-filter-normal" v-model="selectedModeDirectionLeverage" @change="filterNormalOrders">
          <option value="">ALL</option>
          <option v-for="mode in uniqueModeDirectionLeverages" :key="mode" :value="mode">{{ mode }}</option>
        </select>
      </div>

      <div v-for="(count, account) in filteredNormalOrdersCount" :key="account">
        <span v-if="!$device.isMobile">账户{{ account }}: {{ count }} 个订单</span>
      </div>
      <div class="button-group">
        <button @click="fetchOrderList">获取订单</button>
        <button @click="cancelAllOrders">批量撤销订单</button>
      </div>
    </div>

    <table :class="{ 'desktop-table': true, 'hidden': isNormalTableHidden }">
      <thead>
      <tr>
        <th @click="sort('account')">
          账户
          <span v-if="sortKey === 'account'">{{ sortOrders['account'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('instId')">
          产品ID
          <span v-if="sortKey === 'instId'">{{ sortOrders['instId'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('modeDirectionLeverage')">
          模式方向杠杆
          <span v-if="sortKey === 'modeDirectionLeverage'">{{ sortOrders['modeDirectionLeverage'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('px')">
          委托价格
          <span v-if="sortKey === 'px'">{{ sortOrders['px'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('sz')">
          委托数量
          <span v-if="sortKey === 'sz'">{{ sortOrders['sz'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('slTriggerPx')">
          止损价格
          <span v-if="sortKey === 'slTriggerPx'">{{ sortOrders['slTriggerPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('tpTriggerPx')">
          止盈价格
          <span v-if="sortKey === 'tpTriggerPx'">{{ sortOrders['tpTriggerPx'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('orderType')">
          订单类型
          <span v-if="sortKey === 'orderType'">{{ sortOrders['orderType'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th @click="sort('uTime')">
          更新时间
          <span v-if="sortKey === 'uTime'">{{ sortOrders['uTime'] === 'asc' ? '▲' : '▼' }}</span>
        </th>
        <th>撤单</th>
        <th>修改</th>
      </tr>
      </thead>

      <tbody>
      <tr v-if="orders.length === 0">
        <td colspan="13">暂无普通订单</td>
      </tr>
      <tr v-for="order in filteredNormalOrders" :key="order.ordId">
        <td>{{ order.account }}</td>
        <td>{{ order.instId }}</td>
        <td>{{ formatModeDirectionLeverage(order) }}</td>
        <td>{{ order.px }}</td>
        <td>{{ order.sz }}</td>
        <td>{{ order.slTriggerPx }}</td>
        <td>{{ order.tpTriggerPx }}</td>
<!--        <td>{{ order.ordId }}</td>-->
        <td>{{ order.ordType }}</td>
<!--        <td>{{ formatDate(order.cTime) }}</td>-->
        <td>{{ formatDate(order.uTime) }}</td>
        <td><button @click="cancelOrder(order)">撤单</button></td>
        <td><button @click="openAmendModal(order)">修改</button></td>
      </tr>
      </tbody>
    </table>

    <div class="mobile-cards">

      <!-- 新增的产品ID筛选下拉框 -->
      <div v-if="$device.isMobile" class="filter-container">
        <label for="product-filter-normal">ID</label>
        <select id="product-filter-normal" v-model="selectedNormalProductId">
          <option value="">ALL</option>
          <option v-for="id in uniqueNormalProductIds" :key="id" :value="id">{{ id }}</option>
        </select>

        <!-- 模式方向杠杆筛选下拉框 -->
        <label for="mode-filter-normal">方向</label>
        <select id="mode-filter-normal" v-model="selectedModeDirectionLeverage" @change="filterNormalOrders">
          <option value="">ALL</option>

          <option v-for="mode in uniqueModeDirectionLeverages" :key="mode" :value="mode">{{ mode }}</option>
        </select>
      </div>




      <div v-for="(accountOrders, accountName) in groupedOrders" :key="accountName" class="account-card">
        <h3 @click="toggleNormalAccount(accountName)" class="account-header">
          账户{{ accountName }} ({{ accountOrders.length }})
        </h3>

        <div v-if="!collapsedNormalAccounts.includes(accountName)">
          <div v-for="order in accountOrders" :key="order.ordId" class="order-card">
            <div class="order-grid">
              <div class="order-item">
                <strong>{{ order.instId }}</strong> <span>{{ formatModeDirectionLeverage(order) }}</span>
              </div>
              <div class="order-item">
                <strong>委托价格:</strong> {{ order.px }}
              </div>
              <div class="order-item">
                <strong>委托数量:</strong> {{ order.sz }}
              </div>
              <div class="order-item">
                <strong>订单类型:</strong> {{ order.ordType }}
              </div>
              <div class="order-item">
                <strong>止损价格:</strong> {{ order.slTriggerPx }}
              </div>
              <div class="order-item">
                <strong>止盈价格:</strong> {{ order.tpTriggerPx }}
              </div>
<!--              <div class="order-item">-->
<!--                <strong>订单ID:</strong> {{ order.ordId }}-->
<!--              </div>-->

<!--              <div class="order-item">-->
<!--                <strong>创建时间:</strong> {{ formatDate(order.cTime) }}-->
<!--              </div>-->
<!--              <div class="order-item">-->
<!--                <strong>更新时间:</strong> {{ formatDate(order.uTime) }}-->
<!--              </div>-->
              <div class="order-item">
                <button @click="cancelOrder(order)">撤单</button>
              </div>
              <div class="order-item">
                <button @click="openAmendModal(order)">修改</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- 修改普通订单模态框 -->
    <div v-if="showAmendModal" class="modal" @click.self="closeAmendModal">
      <div class="modal-content">
        <span class="close" @click="closeAmendModal">&times;</span>

        <h3>
          账户：{{ amendOrderData.account }}<br>
          {{ amendOrderData.instId }}<br>
          {{ formatModeDirectionLeverage(amendOrderData) }}<br>
        </h3>

        <div class="form-group">
          <label for="newSz">新数量:
          <input v-model="amendOrderData.newSz" type="number" id="newSz" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newPx">新价格:
          <input v-model="amendOrderData.newPx" type="number" id="newPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newPx">新止盈触发价:
          <input v-model="amendOrderData.newTpTriggerPx" type="number" id="newTpTriggerPx" class="input-field" />
          </label>
        </div>
        <div class="form-group">
          <label for="newPx">新止损触发价:
          <input v-model="amendOrderData.newSlTriggerPx" type="number" id="newSlTriggerPx" class="input-field" />
          </label>
        </div>
        <button @click="amendOrder" class="amend-button">修改</button>
      </div>
    </div>

  </div>
</template>


<script>
import api from '@/services/api';
import WebSocketService from '@/services/websocket';
import { useToast } from "vue-toastification";
import { inject } from 'vue';

export default {
  name: 'OrderList',

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },

  data() {
    return {
      lastFetchOrderList: 0,
      orders: [],
      selectedAccounts: [],
      showAmendModal: false,
      amendOrderData: {
        account: '',
        ordId: '',
        instId: '',
        newSz: '',
        newPx: '',
        newTpTriggerPx: '',
        newSlTriggerPx: ''
      },
      collapsedNormalAccounts: [], // 用于存储普通订单的折叠状态
      selectedNormalProductId: '', // 普通订单筛选的产品ID
      isNormalTableHidden: true, // 普通订单表格默认隐藏

      sortKey: 'uTime', // 默认排序
      sortOrders: {uTime: 'desc'},

      selectedModeDirectionLeverage: '', // 新增属性：筛选的模式方向杠杆

    };
  },


  // 在创建时注册事件监听
  created() {
    // 监听账户变化事件
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    // 监听刷新订单列表事件
    // this.$eventBus.on('refresh-order-list', this.fetchOrderList);
    // 监听订单更新事件
    WebSocketService.addListener('orderUpdate', this.handleOrderUpdate);
  },

  // 在卸载时移除事件监听
  beforeUnmount() {
    // 移除账户变化事件监听
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    // 移除刷新订单列表事件监听
    // this.$eventBus.off('refresh-order-list', this.fetchOrderList);
    // 移除订单更新事件监听
    WebSocketService.removeListener('orderUpdate', this.handleOrderUpdate);
  },

  computed: {

    // 根据筛选后的订单计算每个账户的订单数量
    filteredNormalOrdersCount() {
      const counts = {};
      this.filteredNormalOrders.forEach(order => {
        counts[order.account] = (counts[order.account] || 0) + 1;
      });
      return counts;
    },


    uniqueNormalProductIds() {
      const ids = new Set(this.orders.map(order => order.instId));
      return Array.from(ids);
    },

    uniqueModeDirectionLeverages() {
      const modes = new Set(this.orders.map(order => this.formatDirection(order)));
      return Array.from(modes);
    },

    filteredNormalOrders() {
      const filteredOrders = this.orders.filter(order =>
          (this.selectedNormalProductId === "" || order.instId === this.selectedNormalProductId) &&
          (this.selectedModeDirectionLeverage === "" || this.formatDirection(order) === this.selectedModeDirectionLeverage)
      );

      // 排序处理
      if (this.sortKey) {
        filteredOrders.sort((a, b) => {
          const aValue = this.getSortableValue(a, this.sortKey);
          const bValue = this.getSortableValue(b, this.sortKey);

          if (aValue < bValue) return this.sortOrders[this.sortKey] === 'asc' ? -1 : 1;
          if (aValue > bValue) return this.sortOrders[this.sortKey] === 'asc' ? 1 : -1;
          return 0;
        });
      }

      return filteredOrders;
    },

    totalOrdersCount() {
      return this.orders.length;
    },

    ordersCount() {
      const counts = {};
      this.orders.forEach(order => {
        counts[order.account] = (counts[order.account] || 0) + 1;
      });
      return counts;
    },

    groupedOrders() {
      const grouped = {};
      this.filteredNormalOrders.forEach(order => {
        if (!grouped[order.account]) {
          grouped[order.account] = [];
        }
        grouped[order.account].push(order);
      });
      return grouped;
    },

  },

  methods: {

    filterNormalOrders() {
      this.filteredNormalOrders; // 触发计算属性更新
    },

    sort(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] === 'asc' ? 'desc' : 'asc';
    },

    getSortableValue(order, key) {
      if (key === 'orderType') {
        return this.formatOrderType(order);
      }
      return order[key];
    },



    // 当接收到订单更新时触发
    handleOrderUpdate(data) {
      // console.log('Order update received in OrderList component:', data);
      // 如果距离上次获取订单列表的时间超过 1500 毫秒，则重新获取订单列表
      if (Date.now() - this.lastFetchOrderList > 1500) {
        this.lastFetchOrderList = Date.now();
        this.fetchOrderList();
      }
    },


    // 当选择账户发生变化时触发
    async onAccountsChanged(accounts) {
      // console.log('Accounts changed in OrderList:', accounts);
      // 保存新的账户选择
      this.selectedAccounts = [...accounts];
      // 重新获取所有订单
      await this.fetchOrderList();
    },


    // 获取普通订单未成交列表
    async fetchOrderList() {
      // 打印选择的账号
      // console.log('Fetching order list for accounts:', this.selectedAccounts);
      // 如果没有选择账号，则跳过API调用
      // console.log('fetchOrderList called');
      if (this.selectedAccounts.length === 0) {
        // console.log('No accounts selected, skipping API call');
        return;
      }
      try {
        // 遍历选择的账号，获取订单列表
        const orderPromises = this.selectedAccounts.map(async (account) => {
          const response = await api.getOrderList(account);
          // console.log('getOrderList API response:', response);
          // 如果返回的数据中包含data属性，且data属性是数组，则将订单数据添加到orders数组中
          if (response.data && Array.isArray(response.data.data)) {
            return response.data.data.map(order => ({ ...order, account }));
          } else if (response.data && Array.isArray(response.data)) {
            return response.data.map(order => ({ ...order, account }));
          } else {
            // console.log('No valid data received for account:', account);
            return [];
          }
        });
        // 将所有订单数据合并到一个数组中
        const ordersArray = await Promise.all(orderPromises);
        this.orders = ordersArray.flat();
        // console.log('Fetched orders:', this.orders);

        // 更新折叠状态
        this.collapsedNormalAccounts = Object.keys(this.groupedOrders)
      } catch (error) {
        // console.error('Error fetching order list:', error);
        this.toast.error('获取订单列表失败');
      }
    },


    // 撤单
    async cancelOrder(order) {
      try {
        const orderData = {
          account: order.account,
          ordId: order.ordId,
          instId: order.instId
        };
        const response = await api.cancelOrder(orderData);
        // console.log('cancelOrder API response:', response);
        if (response.data) {
          this.toast.success('撤单成功');
          this.eventBus.emit('update-max-amounts');
        } else {
          this.toast.error('撤单失败');
        }
      } catch (error) {
        // console.error('Error cancelling order:', error);
        this.toast.error('撤单失败');
      }
    },

    async cancelAllOrders() {
      if (this.orders.length === 0) {
        this.toast.error('没有可撤销的普通订单');
        return;
      }

      const confirmCancel = confirm('确定要撤销所有普通订单吗？');
      if (!confirmCancel) return;

      try {
        // 遍历订单列表，将订单信息放入ordersData数组中
        const ordersData = this.filteredNormalOrders.map(order => ({
          account: order.account,
          ordId: order.ordId,
          instId: order.instId
        }));
        // 调用api接口，传入ordersData数组，撤销订单
        const response = await api.cancelOrders({ orders: ordersData });
        // console.log('cancelOrders API response:', response);
        // 如果response中有data，则表示撤单成功，刷新订单列表
        if (response.data) {
          this.toast.success('批量撤单成功');

          this.eventBus.emit('update-max-amounts'); // 撤单后，重新获取最大可买卖/开仓数量
        } else {
          // 如果response中没有data，则表示撤单失败，弹出提示框
          this.toast.error('批量撤单失败');
        }
      } catch (error) {
        // 如果捕获到异常，则表示撤单失败，弹出提示框
        // console.error('Error cancelling orders:', error);
        this.toast.error('批量撤单失败');
      }
    },



    // 打开修改订单模态框
    openAmendModal(order) {
      // 将订单信息赋值给修改订单数据
      this.amendOrderData.account = order.account;
      this.amendOrderData.ordId = order.ordId;
      this.amendOrderData.instId = order.instId;
      // 将订单数量赋值给修改订单数据
      this.amendOrderData.newSz = order.sz;
      // 将订单价格赋值给修改订单数据
      this.amendOrderData.newPx = order.px;
      // 将止损触发价格赋值给修改订单数据
      this.amendOrderData.newSlTriggerPx = order.slTriggerPx;
      // 将止盈触发价格赋值给修改订单数据
      this.amendOrderData.newTpTriggerPx = order.tpTriggerPx;
      this.amendOrderData.posSide = order.posSide;
      this.amendOrderData.side = order.side;
      this.amendOrderData.tdMode = order.tdMode;
      this.amendOrderData.lever = order.lever;
      this.amendOrderData.ordType = order.ordType;
      // 显示修改订单模态框
      this.showAmendModal = true;
    },

    closeAmendModal() {
      this.showAmendModal = false;
    },

    async amendOrder() {
      try {
        const response = await api.amendOrder(this.amendOrderData);
        // console.log('amendOrder API response:', response);
        if (response.data) {
          this.closeAmendModal();
          // await this.fetchOrderList(); // 修改订单后刷新订单列表
          this.eventBus.emit('update-max-amounts'); // 修改订单，重新获取最大可买卖/开仓数量
        } else {
          this.toast.error('修改订单失败');
        }
      } catch (error) {
        // console.error('Error amending order:', error);
        this.toast.error('修改订单失败');
      }
    },

    // 根据订单信息，格式化交易模式和方向杠杆
    formatModeDirectionLeverage(order) {
      if (order.side === 'buy' && order.posSide === 'long') {
        return `${order.tdMode} ${order.lever}x 买入 开多`;
      } else if (order.side === 'sell' && order.posSide === 'short') {
        return `${order.tdMode} ${order.lever}x 卖出 开空`;
      } else if (order.side === 'buy' && order.posSide === 'short') {
        return `${order.tdMode} ${order.lever}x 买入 平空`;
      } else if (order.side === 'sell' && order.posSide === 'long') {
        return `${order.tdMode} ${order.lever}x 卖出 平多`;
      } else {
        return `${order.tdMode} ${order.lever}x ${order.side} ${order.posSide}`;
      }
    },


    formatDirection(order) {
      if (order.side === 'buy' && order.posSide === 'long') {
        return `买入 开多`;
      } else if (order.side === 'sell' && order.posSide === 'short') {
        return `卖出 开空`;
      } else if (order.side === 'buy' && order.posSide === 'short') {
        return `买入 平空`;
      } else if (order.side === 'sell' && order.posSide === 'long') {
        return `卖出 平多`;
      } else {
        return `${order.side} ${order.posSide}`;
      }
    },


    formatDate(timestamp) {
      const date = new Date(parseInt(timestamp));
      return date.toLocaleString();
    },

    toggleNormalAccount(accountName) {
      const index = this.collapsedNormalAccounts.indexOf(accountName);
      if (index > -1) {
        this.collapsedNormalAccounts.splice(index, 1); // 展开
      } else {
        this.collapsedNormalAccounts.push(accountName); // 折叠
      }
    },

    toggleAllNormalAccounts() {
      if (this.$device.isMobile) {
        // 移动端逻辑：折叠或展开所有普通账户卡片
        if (this.collapsedNormalAccounts.length === 0) {
          // 如果没有折叠的账户，展开所有
          this.collapsedNormalAccounts = Object.keys(this.groupedOrders);
        } else {
          // 如果有折叠的账户，折叠所有
          this.collapsedNormalAccounts = [];
        }
      } else {
        // 网页端逻辑：隐藏或显示普通账户表格
        this.isNormalTableHidden = !this.isNormalTableHidden;
      }
    },

  },
};
</script>


<style scoped>
.order-list {
  margin: auto;
}

.hidden {
  display: none;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-top: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 18px;
}

.button-group {
  display: flex;
  gap: 10px; /* 按钮之间的间距 */
}

button {
  padding: 8px 16px;
  background-color: rgba(163, 149, 149, 0.2); /* 灰色背景 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049; /* 鼠标悬停时的颜色 */
}

.order-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.order-list th, .order-list td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
}

.order-list th {
  background-color: #000000;
  color: #FFFFFF;
}

/* 模态框 */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
}

/* 模态框内容 */
.modal-content {
  background-color: #000000;
  margin: auto;
  padding: 20px;
  border: 1px solid #000000;
  width: 90%; /* 调整弹窗宽度 */
  max-width: 500px; /* 最大宽度 */
  height: auto; /* 调整弹窗高度 */
  display: flex;
  flex-direction: column;
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.input-field {
  height: 30px; /* 调整输入框高度 */
  padding: 8px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #ffffff;
  background-color: rgba(163, 149, 149, 0.2);
  width: 70%;
}
.amend-button {
  font-size: 18px;
  padding: 10px;
  margin-top: 20px;
  width: 100%; /* 放大按钮 */
}

/* 移动端适配 */
@media (max-width: 600px) {
  .desktop-table {
    display: none;
  }

  .mobile-cards {
    display: block;
  }

  .account-card {
    margin-bottom: 20px;
  }

  .account-header {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    text-align: center;

  }

  .order-card {
    border: 1px solid #ffffff;
    padding: 10px;
    background-color: #111;
    margin-bottom: 10px;
  }

  .order-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 设置为2列布局 */
    gap: 5px; /* 设置卡片之间的间距 */
  }

  .order-item {
    display: flex;
    flex-direction: column;

  }

  .order-item strong {
    margin-bottom: auto;
  }

  .order-card button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px; /* 增加圆角 */
    transition: background-color 0.3s; /* 增加过渡效果 */
  }

  .order-card button:hover {
    background-color: #333333;
  }

  /* 移动端模态框适配 */
  .modal-content {
    width: 90%; /* 调整弹窗宽度 */
    max-width: 100%; /* 最大宽度 */
    height: auto; /* 高度自适应 */
    padding: 15px; /* 增加内边距 */
    font-size: 16px; /* 调整字体大小 */
  }

  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    height: 50px;
    font-size: 18px;
  }

  .input-field {
    height: 20px; /* 调整输入框高度 */
    font-size: 18px; /* 调整输入框字体大小 */
    width: 50%;
  }

  .amend-button {
    font-size: 16px; /* 调整按钮字体大小 */
    padding: 8px; /* 调整按钮内边距 */
  }

  .close {
    font-size: 24px; /* 调整关闭按钮的大小 */
  }
}

/* 网页端适配 */
@media (min-width: 601px) {
  .mobile-cards {
    display: none;
  }
}
</style>

