import axios from 'axios';
import LZString from 'lz-string';
import store from "@/store/store.js";

const api = axios.create({

    // baseURL: 'http://localhost:8000',

    // baseURL: `${window.location.protocol}//${window.location.hostname}:8000`,

    // 修改为生产环境的 API 地址
    baseURL: 'https://alittleflower.us.kg',  // 使用 https 确保安全通信

    });

    api.interceptors.request.use((config) => {

        const token = store.getters.token;  // 从 Vuex 中获取 JWT token
        const csrfToken = store.getters.csrfToken;  // 从 Vuex 中获取 CSRF token

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (csrfToken) {
            config.headers['X-CSRF-Token'] = csrfToken;  // 设置 CSRF token
        }
        return config;
    });

export default {

    async login(loginData) {
        try {
            const response =
                await api.post('/api/login', {loginData});
            return response;
        } catch (error) {
            console.error("登录失败:", error);
            return { data: { success: false, message: "登录失败" } };
        }
    },

    async verifyOtp(Otp) {
        try {
            const response =
                await api.post('/api/verify_otp', { Otp });
            return response;
        } catch (error) {
            console.error("OTP验证请求失败:", error);
            return  { data: { success: false, message: "VerificationCode验证失败" } };
        }
    },

    async getVerificationCode(email) {
        return api.post('/api/getVerificationCode',
            { email })
            .then(response => {
            console.log('getVerificationCode API response:', response);
            return response;
        });
    },


    async logout() {
        try {
            const response =
                await api.get('/api/logout');
            return response.data;
        } catch (error){
            console.error("登出失败:", error);
            return null;
        }
    },

    async getPublicKey() {
        try {
            const response =
                await api.get('/api/public_key');
            return response.data;
        } catch (error) {
            console.error("获取公钥失败:", error);
            return null;
        }
    },


    async verifyTradePassword(tradePassword) {
        try {
            const response =
                await api.post('/api/verify_trade_password', { tradePassword });
            return response.data;
        } catch (error) {
            console.error("交易密码验证请求失败:", error);
            // return { success: false, message: "请求失败" };
        }
    },






    async getLeverageInfo(instId, accounts) {
        return api.get('/api/leverage_info', {
            params: {
                instId,
                accounts: accounts.length > 0 ? accounts.join(',') : undefined
            }
        });
    },



    async setLeverage(leverageData) {
        return api.post('/set_leverage', leverageData).then(response => {
            // console.log('set_leverage API response:', response);
            return response;
        });
    },



    async getAccountBalance(accounts) {
        return api.get('/api/account_balance', {
            params: {
                accounts: accounts.join(',')
            }
        });
    },


    async getPositionInfo(accounts) {
        return api.get('/api/position_info', {
            params:
                { accounts: accounts.join(',')
                }
        });
    },



    async getMaxAmount(instId, accounts) {
        // console.log('API call params:', instId, accounts);
        return api.get('/api/get-max-amount', {
            params: {
                instId,
                accounts: accounts.length > 0 ? accounts.join(',') : undefined
            }
        });
    },


    async placeOrder(orderData) {
        return api.post('/api/place-order', orderData).then(response => {
            // console.log('API response:', response);
            return response;
        });
    },


    async placeOrdersplit(orderData) {
        try {
            // 压缩订单数据
            const compressedData = LZString.compressToBase64(JSON.stringify(orderData));

            // 发送压缩后的数据
            const response = await api.post('/api/place-order-split', { data: compressedData });

            // 返回响应
            return response;
        } catch (error) {
            console.error('API 请求错误:', error);
            throw error;  // 抛出错误，供调用方捕获
        }
    },


    // 获取未成交订单列表
    async getOrderList(account) {
        return api.get('/api/order_list', { params: { account } }).then(response => {
            // console.log('getOrderList API response:', response);
            return response;
        });
    },


    // 获取成交明细（近三天）
    async gettradefills(account) {
        return api.get('/api/trade_fills', { params: { account } }).then(response => {
            // console.log('gettradefills API response:', response);
            return response;
        });
    },



    // 撤单
    cancelOrder(orderData) {
        return api.post('/api/cancel_order', orderData).then(response => {
            // console.log('API response:', response);
            return response;
        });
    },

    // 批量撤单
    cancelOrders(orderData) {
        return api.post('/api/cancel_orders', orderData)
            .then(response => {
            // console.log('API response:', response);
            return response;
        });
    },



    // 修改订单
    amendOrder(orderData) {
        return api.post('/api/amend_order', orderData).then(response => {
            // console.log('API response:', response);
            return response;
        });
    },

    closePosition(positionData) {
        return api.post('/close_position', positionData);
            // console.log('close_position API response:', response);
            // return response;
    },

    //  策略委托下单
    placeOrderAlgo(orderData) {
        return api.post('/api/place-order-algo', orderData).then(response => {
            // console.log('API response for algo order:', response);
            return response;
        });
    },

    // 获取未完成策略委托单列表
    async getAlgoOrderList(account) {
        return api.get('/api/algo_order_list', { params: { account } }).then(response => {
            // console.log('getAlgoOrderList API response:', response);
            return response;
        });
    },

    // 撤销策略委托单
    cancelAlgoOrder(orderData) {
        return api.post('/api/cancel-algo-order', orderData).then(response => {
            // console.log('cancelAlgoOrder API response:', response);
            return response;
        });
    },

    // 修改策略委托订单路由
    amendAlgoOrder(orderData) {
        return api.post('/api/amend_algo_order', orderData).then(response => {
            // console.log('amendAlgoOrder API response:', response);
            return response;
        });
    },



};