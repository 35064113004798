<template>
  <div class="trading-container">
    <div class="trading-panel">

      <div class="ticker-card" v-if="selectedTicker">
        <span class="ticker-name">{{ selectedTicker.instId }}</span>
        <span class="ticker-price">{{ selectedTicker.markPx || '加载中...' }}</span>
      </div>

      <MaxAmountTable
          v-show="tradeType === 'open'"
          ref="maxAmountTable"
          :usePercentage="usePercentage"
          :percentage="openPercentage"
      />

      <MaxClosePositionTable
          v-show="tradeType === 'close'"
          ref="maxClosePositionTable"
          :usePercentage="usePercentage"
          :percentage="closePercentage"
          :selectedAccounts="selectedAccounts"
      />

      <div class="trade-options">

        <div class="trade-type-switch">
          <button
              :class="['switch-btn', { active: tradeType === 'open' }]"
              @click="tradeType = 'open'"
          >
            开仓
          </button>
          <button
              :class="['switch-btn', { active: tradeType === 'close' }]"
              @click="tradeType = 'close'"
          >
            平仓
          </button>
        </div>

        <div class="order-type-switch">

          <button
              :class="['switch-btn', { active: orderType === 'limit' }]"
              @click="orderType = 'limit'"
          >
            限价委托
          </button>

          <button
              :class="['switch-btn', { active: orderType === 'market' }]"
              @click="orderType = 'market'"
          >
            市价委托
          </button>

          <button
              :class="['switch-btn', { active: orderType === 'trigger' }]"
              @click="orderType = 'trigger'"
          >
            计划委托
          </button>

          <button
              :class="['switch-btn', { active: orderType === 'split' }]"
              @click="orderType = 'split'"
          >
            分段委托
          </button>

        </div>

        <div class="order-type-switch0" v-if="orderType !== 'split'">


        <div class="order-type-switch">
            <button
                :class="['switch-btn', { active: orderType === 'conditional' }]"
                @click="orderType = 'conditional'"
            >
              单向止盈止损
            </button>
            <button
                :class="['switch-btn', { active: orderType === 'oco' }]"
                @click="orderType = 'oco'"
            >
              双向止盈止损
            </button>
            <button
                :class="['switch-btn', { active: orderType === 'move_order_stop' }]"
                @click="orderType = 'move_order_stop'"
            >
              移动止盈止损
            </button>
          </div>

          <div class="input-fields">

            <div class="order-quantity-switch">
              <button
                  :class="['switch-btn', { active: !usePercentage && !useCloseFraction }]"
                  @click="usePercentage = false; useCloseFraction = false"
              >
                数量
              </button>

              <button
                  :class="['switch-btn', { active: usePercentage && !useCloseFraction }]"
                  @click="usePercentage = true; useCloseFraction = false"
              >
                比例
              </button>
              <button
                  v-if="tradeType === 'close' && orderType === 'oco'"
                  :class="['switch-btn', { active: useCloseFraction }]"
                  @click="useCloseFraction = true"
              >
                全部仓位
              </button>
            </div>



            <div class="form-group" v-if="orderType === 'limit'">
              <label for="price">
                委托价格
                <input
                    v-model.number="price"
                    type="number"
                    id="price"
                    class="input-field"
                    @input="updatePriceFromSlider"


                />
              </label>
              <div class="quantity-slider">
                <input
                    v-model.number="priceSliderValue"
                    type="range"
                    min="-5"
                    max="5"
                    step="0.01"
                    @input="updatePrice"
                />
                <input
                    v-model.number="priceSliderValue"
                    type="number"
                    min="-5"
                    max="5"
                    step="0.01"
                    class="input-percentage"
                    @input="updatePrice"
                />%
              </div>
            </div>


            <div class="form-group" v-if="orderType === 'trigger'">
              <label for="triggerPrice">
                触发价格
                <input
                    v-model.number="triggerPrice"
                    type="number"
                    id="triggerPrice"
                    class="input-field"
                    @input="updateTriggerPriceFromSlider"
                />
              </label>
              <div class="quantity-slider">
                <input
                    v-model.number="triggerPriceSliderValue"
                    type="range"
                    min="-5"
                    max="5"
                    step="0.01"
                    @input="updateTriggerPrice"
                />
                <input
                    v-model.number="triggerPriceSliderValue"
                    type="number"
                    min="-5"
                    max="5"
                    step="0.01"
                    class="input-percentage"
                    @input="updateTriggerPrice"
                />%
              </div>
            </div>

            <!-- Conditional Order Type -->
            <div class="form-group" v-if="orderType === 'conditional'">
              <label for="conditionalType">条件类型
                <select v-model="conditionalType" id="conditionalType" class="input-field select-field">
                  <option value="tp">止盈</option>
                  <option value="sl">止损</option>
                </select>
              </label>

              <div v-if="conditionalType === 'tp'">
                <label for="tpTriggerPx">止盈触发价
                  <input
                      v-model.number="tpTriggerPx"
                      type="number"
                      id="tpTriggerPx"
                      class="input-field"
                      @input="updateTpTriggerPxFromSlider"
                  />
                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="tpTriggerPxSliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.01"
                      @input="updateTpTriggerPx"
                  />
                  <input
                      v-model.number="tpTriggerPxSliderValue"
                      type="number"
                      min="-5"
                      max="5"
                      step="0.01"
                      class="input-percentage"
                      @input="updateTpTriggerPx"
                  />%
                </div>
              </div>

              <div v-if="conditionalType === 'sl'">
                <label for="slTriggerPx">止损触发价
                  <input
                      v-model.number="slTriggerPx"
                      type="number"
                      id="slTriggerPx"
                      class="input-field"
                      @input="updateSlTriggerPxFromSlider"
                  />
                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="slTriggerPxSliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.01"
                      @input="updateSlTriggerPx"
                  />
                  <input
                      v-model.number="slTriggerPxSliderValue"
                      type="number"
                      min="-5"
                      max="5"
                      step="0.01"
                      class="input-percentage"
                      @input="updateSlTriggerPx"
                  />%
                </div>
              </div>
            </div>

            <!-- OCO Order Type -->
            <div v-if="orderType === 'oco'">
              <div class="form-group">
                <label for="tpTriggerPx">止盈触发价
                  <input
                      v-model.number="tpTriggerPx"
                      type="number"
                      id="tpTriggerPx"
                      class="input-field"
                      @input="updateTpTriggerPxFromSlider"
                  />
                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="tpTriggerPxSliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.01"
                      @input="updateTpTriggerPx"
                  />
                  <input
                      v-model.number="tpTriggerPxSliderValue"
                      type="number"
                      min="-5"
                      max="5"
                      step="0.01"
                      class="input-percentage"
                      @input="updateTpTriggerPx"
                  />%
                </div>

              </div>

              <div class="form-group">
                <label for="slTriggerPx">止损触发价
                  <input
                      v-model.number="slTriggerPx"
                      type="number"
                      id="slTriggerPx"
                      class="input-field"
                      @input="updateSlTriggerPxFromSlider"
                  />
                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="slTriggerPxSliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.01"
                      @input="updateSlTriggerPx"
                  />
                  <input
                      v-model.number="slTriggerPxSliderValue"
                      type="number"
                      min="-5"
                      max="5"
                      step="0.01"
                      class="input-percentage"
                      @input="updateSlTriggerPx"
                  />%
                </div>
              </div>
            </div>

            <div class="form-group" v-if="orderType === 'move_order_stop'">
              <label for="callbackType">回调类型
                <select v-model="callbackType" id="callbackType" class="input-field select-field">
                  <option value="ratio">比例</option>
                  <option value="spread">价距</option>
                </select>
              </label>

              <!--            回调（比例）-->
              <div v-if="orderType === 'move_order_stop' && callbackType === 'ratio'">
                <label for="callbackRatio">
                  <div class="slider-container">
                    <input
                        v-model.number="callbackRatioSliderValue"
                        type="range"
                        id="callbackRatio"
                        min="0"
                        max="5"
                        step="0.01"
                        @input="updateCallbackRatio"
                        class="slider"
                    />
                    <input
                        v-model.number="callbackRatioSliderValue"
                        type="number"
                        min="0"
                        max="5"
                        step="0.01"
                        class="input-percentage"
                        @input="updateCallbackRatio"
                    />%
                  </div>
                </label>
              </div>

              <div v-if="orderType === 'move_order_stop' && callbackType === 'spread'">
                <label for="callbackSpread">回调(价距)
                  <input
                      v-model.number="callbackSpread"
                      type="number"
                      id="callbackSpread"
                      class="input-field"
                  />
                </label>
              </div>
            </div>


            <div class="form-group" v-if="!usePercentage && !useCloseFraction">
              <label for="quantity">数量(张)<input v-model.number="quantity" type="number" id="quantity" class="input-field" /></label>
            </div>
            <div class="quantity-slider" v-if="!useCloseFraction">
              <input
                  v-if="tradeType === 'open' && usePercentage"
                  v-model.number="openPercentage"
                  type="range"
                  min="0"
                  max="3"
                  step="0.01"
              >
              <input
                  v-if="tradeType === 'close' && usePercentage"
                  v-model.number="closePercentage"
                  type="range"
                  min="0"
                  max="100"
                  step="0.1"
              >
              <input
                  v-if="!usePercentage"
                  v-model.number="quantity"
                  type="range"
                  min="0"
                  max="10"
                  step="1"
              >
              <span>{{ usePercentage ? (tradeType === 'open' ? openPercentage : closePercentage) + '%' : quantity }}</span>

            </div>


            <!-- 只在非 conditional、oco、move_order_stop 模式下显示止盈止损 -->
            <div v-if="orderType === 'limit' || orderType === 'trigger' || orderType === 'market'">
              <div class="form-group" v-if="tradeType === 'open'">
                <label for="takeProfit">
                  止盈价格
                  <input
                      v-model.number="takeProfit"
                      @input="updateSliderValueFromPrice"
                      type="number"
                      id="takeProfit"
                      class="input-field"
                  />

                  <!-- Real-time calculation result -->
                  <input
                      v-model="calculatedTakeProfitResult"
                      type="number"
                      class="calculated-result-field"
                      disabled
                  />

                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="sliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.1"
                      @input="updateTakeProfit"
                  />
                  <input
                      v-model.number="sliderValue"
                      @input="updateTakeProfit"
                      type="number"
                      min="-10"
                      max="10"
                      step="0.01"
                      class="input-percentage"
                  />%
                </div>



                <label for="stopLoss">
                  止损价格
                  <input
                      v-model.number="stopLoss"
                      @input="updateStopLossSliderValueFromPrice"
                      type="number"
                      id="stopLoss"
                      class="input-field"
                  />


                  <!-- Real-time calculation result -->
                  <input
                      v-model="calculatedStopLossResult"
                      type="number"
                      class="calculated-result-field"
                      disabled
                  />



                </label>
                <div class="quantity-slider">
                  <input
                      v-model.number="stopLossSliderValue"
                      type="range"
                      min="-5"
                      max="5"
                      step="0.1"
                      @input="updateStopLoss"
                  />
                  <input
                      v-model.number="stopLossSliderValue"
                      @input="updateStopLoss"
                      type="number"
                      min="-10"
                      max="10"
                      step="0.01"
                      class="input-percentage"
                  />%
                </div>
              </div>
            </div>


          </div>

          <div class="action-buttons">
            <template v-if="tradeType === 'open'">
              <button
                  class="action-btn open-long"
                  @click="promptTradePassword('buy', 'long')"
                  :disabled="!isValidOrder"
                  :class="{ 'disabled-btn': !isValidOrder }"
              >
                开多
              </button>
              <button
                  class="action-btn open-short"
                  @click="promptTradePassword('sell', 'short')"
                  :disabled="!isValidOrder"
                  :class="{ 'disabled-btn': !isValidOrder }"
              >
                开空
              </button>
            </template>
            <template v-else>
              <button
                  class="action-btn close-long"
                  @click="promptTradePassword('sell', 'long')"
                  :disabled="!isValidOrder"
                  :class="{ 'disabled-btn': !isValidOrder }"
              >
                平多
              </button>
              <button
                  class="action-btn close-short"
                  @click="promptTradePassword('buy', 'short')"
                  :disabled="!isValidOrder"
                  :class="{ 'disabled-btn': !isValidOrder }"
              >
                平空
              </button>
            </template>
          </div>

        </div>

        <div class="order-type-switch0" v-if="orderType === 'split'">

          <div class="form-group0" >
            <div class="form-item0">
              <label for="minPrice">最低价格</label>
              <input v-model.number="minPrice" type="number" id="minPrice" class="input-field0" />
            </div>
            <div class="form-item0">
              <label for="maxPrice">最高价格</label>
              <input v-model.number="maxPrice" type="number" id="maxPrice" class="input-field0" />
            </div>
            <div class="form-item0">
              <label for="totalQuantity">订单数</label>
              <input v-model.number="totalQuantity" type="number" id="totalQuantity" class="input-field0" />
            </div>
            <div class="form-item0">
              <label for="splitquantity">总数量(张)</label>
              <input v-model.number="splitquantity" type="number" id="splitquantity" class="input-field0" />
            </div>
            <div class="form-item0" v-if="tradeType === 'open'">
              <label for="takeProfitPrice">止盈价格</label>
              <input v-model.number="takeProfitPrice" type="number" id="takeProfitPrice" class="input-field0" />
            </div>
            <div class="form-item0" v-if="tradeType === 'open'">
              <label for="stopLossPrice">止损价格</label>
              <input v-model.number="stopLossPrice" type="number" id="stopLossPrice" class="input-field0" />
            </div>
          </div>

          <!-- 排列方式单选按钮 -->
          <div class="form-group0">
            <label>排列方式:</label>
            <input type="radio" id="average" value="average" v-model="sortType" @change="calculateSegments"/>
            <label for="average">平均</label>

            <input type="radio" id="ascend" value="ascend" v-model="sortType" @change="calculateSegments"/>
            <label for="ascend">升序</label>

            <input type="radio" id="descend" value="descend" v-model="sortType" @change="calculateSegments"/>
            <label for="descend">降序</label>

            <input type="radio" id="random" value="random" v-model="sortType" @change="calculateSegments"/>
            <label for="random">随机</label>
          </div>

          <!-- 等差、等比切换 -->
          <div class="form-group0" v-if="sortType === 'ascend' || sortType === 'descend'">
            <label>计算规则:</label>
            <input type="radio" id="arithmetic" value="arithmetic" v-model="sequenceType" @change="calculateSegments"/>
            <label for="arithmetic">等差</label>

            <input type="radio" id="geometric" value="geometric" v-model="sequenceType" @change="calculateSegments"/>
            <label for="geometric">等比</label>
          </div>

          <div class="table-container0">
            <table class="order-split-table">
              <thead>
              <tr>
                <th>价格排序</th>
                <th>数量(张)</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(segment, index) in splitOrderSegments" :key="index">
                <td>
                  <input v-model.number="segment.price" type="number" class="input-field0" />
                </td>
                <td>
                  <input v-model.number="segment.quantity" type="number" class="input-field0" />
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="action-buttons0">
            <template v-if="tradeType === 'open'">
              <button
                  class="action-btn open-long"
                  @click="promptTradePassword('buy', 'long')"
                  :disabled="!isValidOrdersplit"
                  :class="{ 'disabled-btn': !isValidOrdersplit }"
              >
                开多
              </button>
              <button
                  class="action-btn open-short"
                  @click="promptTradePassword('sell', 'short')"
                  :disabled="!isValidOrdersplit"
                  :class="{ 'disabled-btn': !isValidOrdersplit }"
              >
                开空
              </button>
            </template>
            <template v-else>
              <button
                  class="action-btn close-long"
                  @click="promptTradePassword('sell', 'long')"
                  :disabled="!isValidOrdersplit"
                  :class="{ 'disabled-btn': !isValidOrdersplit }"
              >
                平多
              </button>
              <button
                  class="action-btn close-short"
                  @click="promptTradePassword('buy', 'short')"
                  :disabled="!isValidOrdersplit"
                  :class="{ 'disabled-btn': !isValidOrdersplit }"
              >
                平空
              </button>
            </template>
          </div>

        </div>

      </div>
    </div>
    <!-- 交易密码弹窗 -->
    <TradePasswordModal
        v-show="showPasswordModal"
        ref="TradePasswordModal"
        @success="onPasswordSuccess"
        @error="onPasswordError"
        @close="showPasswordModal = false"
    />
  </div>
</template>

<script>
import api from '@/services/api';
import MaxAmountTable from './MaxAmountTable.vue'; // 导入MaxAmountTable组件
import { useToast } from "vue-toastification";
import { inject } from 'vue';
import MaxClosePositionTable from './MaxClosePositionTable.vue';
import TradePasswordModal from './TradePasswordModal.vue';

export default {
  name: 'TradingPanelsplit',
  components: {
    MaxAmountTable, // 注册MaxAmountTable组件
    MaxClosePositionTable,
    TradePasswordModal

  },

  setup() {
    // 调用useToast函数，获取toast
    const toast = useToast();
    // 注入eventBus
    const eventBus = inject('eventBus');
    // 返回toast和eventBus
    return { toast, eventBus };
  },


  data() {
    return {
      showPasswordModal: false, // 控制交易密码弹窗的显示
      actionType: null, // 记录当前操作类型（如 "buy", "sell"）
      actionPosSide: null, // 记录当前操作的仓位方向（如 "long", "short"）

      tradeType: 'open',
      orderType: 'limit',
      price: '',
      triggerPrice: '0',
      priceSliderValue: 0, // Slider percentage (-5% to 5)
      triggerPriceSliderValue: 0, // Slider percentage (-5 to 5%)
      marketPrice: '', // 实时市场价格
      quantity: 0, //总数量
      takeProfit: '',
      sliderValue: 0,       // Slider percentage (-5% to 5%)
      stopLoss: '',
      stopLossSliderValue: 0, // Slider percentage (-5% to 5%) for stop loss
      selectedTicker: null,
      selectedAccounts: [],
      usePercentage: false,
      percentage: 0,
      tpTriggerPx: '',
      slTriggerPx: '',
      tpTriggerPxSliderValue: 0, // Slider percentage (-5 to 5%)
      slTriggerPxSliderValue: 0, // Slider percentage (-5 to 5%)
      callbackType: 'ratio',
      callbackRatioSliderValue: 0, // Slider percentage (0 to 10)
      callbackRatio: '',
      callbackSpread: '',
      conditionalType: 'tp',
      validationError: '',
      openPercentage: 0,
      closePercentage: 0,
      lastFetchOrderList: 0,
      calculatedTakeProfitResult: 0, // Calculated result for take profit
      calculatedStopLossResult: 0, // Calculated result for stop loss
      useCloseFraction: false,
      closeFraction: 1, // "全部仓位"的默认值

      minPrice: null,   //最低价格
      maxPrice: null,   //最高价格
      totalQuantity: null,  //订单数
      splitquantity: null, //总数量
      splitOrderSegments: [],  // 用于存储分段的价格、数量
      sortType: null,  // 排列方式: average, ascend, descend, random
      sequenceType: 'arithmetic', // 排列规则: arithmetic (等差), geometric (等比)
      side: null,
      posSide: null,
      isValidOrdersplit: true, // 验证订单有效性
      tdMode: 'cross',
      ordType: 'limit',
      takeProfitPrice: '', // 止盈价格
      stopLossPrice: '', // 止损价格


    }
  },

  computed: {
    isValidOrder() {
      const baseCondition = this.selectedTicker && this.selectedAccounts.length > 0 && (this.usePercentage || this.quantity > 0  || this.useCloseFraction);

      if (this.orderType === 'market') {
        return baseCondition;
      }

      if (this.orderType === 'limit') {
        return baseCondition && this.price > 0;
      }

      if (this.orderType === 'trigger') {
        return baseCondition && this.triggerPrice > 0;

      }

      if (this.orderType === 'conditional') {
        return baseCondition &&
            ((this.conditionalType === 'tp' && this.tpTriggerPx > 0) ||
                (this.conditionalType === 'sl' && this.slTriggerPx > 0));
      }

      if (this.orderType === 'oco') {
        return baseCondition && this.tpTriggerPx > 0 && this.slTriggerPx > 0;
      }

      if (this.orderType === 'move_order_stop') {
        return baseCondition && ((this.callbackType === 'ratio' && this.callbackRatio > 0) ||
            (this.callbackType === 'spread' && this.callbackSpread > 0));
      }

      if (!['conditional', 'oco', 'move_order_stop'].includes(this.orderType) && this.tradeType === 'open') {
        return baseCondition && this.takeProfit > 0 && this.stopLoss > 0;
      }

      return baseCondition;
    },

    currentPercentage: {
      get() {
        return this.tradeType === 'open' ? this.openPercentage : this.closePercentage;
      },
      set(value) {
        if (this.tradeType === 'open') {
          this.openPercentage = value;
        } else {
          this.closePercentage = value;
        }
      }
    }
  },

  created() {
    // 监听ticker-selected事件，触发onTickerSelected方法
    this.$eventBus.on('ticker-selected', this.onTickerSelected);
    // 监听accounts-changed事件，触发onAccountsChanged方法
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    // 监听update-max-amounts事件，触发updateMaxAmounts方法
    this.eventBus.on('update-max-amounts', this.updateMaxAmounts);
    // 监听实时价格更新事件
    this.$eventBus.on('ticker-price-updated', this.onTickerPriceUpdated);

  },

  beforeUnmount() {
    this.$eventBus.off('ticker-selected', this.onTickerSelected);
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    this.eventBus.off('update-max-amounts', this.updateMaxAmounts);
    // 移除实时价格更新事件监听
    this.$eventBus.off('ticker-price-updated', this.onTickerPriceUpdated);

  },



  methods: {

    // 弹出交易密码输入框并记录操作类型
    promptTradePassword(side, posSide) {
      this.actionType = side;
      this.actionPosSide = posSide;
      this.showPasswordModal = true;
      // console.log('showPasswordModal = true')
      // this.focusFirstInput()
      this.$refs.TradePasswordModal.focusFirstInput();
    },

    // // 聚焦第一个输入框
    // focusFirstInput() {
    //   if (this.$refs.TradePasswordModal) {
    //     this.$refs.TradePasswordModal.focusFirstInput();
    //   }
    // },

    // 交易密码验证成功时调用的函数
    async onPasswordSuccess() {
      this.showPasswordModal = false;

      const isOpenTrade = this.tradeType === 'open';
      const isSplitOrder = this.orderType === 'split';

      // 根据交易类型和订单类型调用相应的函数
      if (isOpenTrade && !isSplitOrder) {
        await this.validateAndPlaceOrder(this.actionType, this.actionPosSide);
      } else if (!isOpenTrade) {
        await this.validateAndCloseOrder(this.actionType, this.actionPosSide);
      } else if (isOpenTrade && isSplitOrder) {
        await this.validateAndPlaceSplitOrder(this.actionType, this.actionPosSide);
      }
    },

    // 交易密码验证失败时调用的函数
    onPasswordError(message) {
      this.toast.error(message);
    },


    async fetchLeverageInfo() {
      if (this.$refs.maxAmountTable) {
        // 调用 maxAmountTable 的 fetchMaxAmounts 方法
        await this.$refs.maxAmountTable.fetchLeverageInfo();
      }
    },

    async updateMaxAmounts() {

      // 如果距离上次获取订单列表的时间超过 1500 毫秒，则重新获取订单列表
      // if (Date.now() - this.lastFetchOrderList > 1500) {
      //   this.lastFetchOrderList = Date.now();
      // 如果存在 maxAmountTable 引用
      if (this.$refs.maxAmountTable) {
        // 调用 maxAmountTable 的 fetchMaxAmounts 方法
        await this.$refs.maxAmountTable.fetchMaxAmounts();
        // 打印已更新最大可买卖/开仓数量
        // console.log('已更新最大可买卖/开仓数量');
      }
      // else {
      //   // 打印 maxAmountTable 引用不存在，无法更新最大可买卖/开仓数量
      //   console.warn('maxAmountTable 引用不存在，无法更新最大可买卖/开仓数量');
      // }
      // }
    },

    async onAccountsChanged(accounts) {
      // console.log('Accounts changed in TradingPanel:', accounts);
      this.selectedAccounts = accounts;
      // if (this.$refs.maxAmountTable) {
      //   await this.$refs.maxAmountTable.onAccountsChanged(accounts);
      // }
    },

    async onTickerSelected(ticker) {
      this.selectedTicker = ticker;
      // console.log("onTickerSelected", ticker);

      this.price = ticker.markPx;
      this.triggerPrice = ticker.markPx;

      this.marketPrice = ticker.markPx;

      if (this.orderType === 'market') {
        this.updateMarketPrices();
      }

      // if (this.$refs.maxAmountTable) {
      //   await this.$refs.maxAmountTable.onTickerSelected(ticker);
      // }
    },

    onTickerPriceUpdated(data) {
      // console.log("onTickerPriceUpdated", data);
      if (this.selectedTicker && this.selectedTicker.instId === data.instId) {
        // 只更新当前选中的ticker的价格
        this.selectedTicker.markPx = data.markPx;
      }
    },

    calculateSegments() {
      if (!this.minPrice || !this.maxPrice || !this.totalQuantity || !this.splitquantity) return;

      const priceRange = this.maxPrice - this.minPrice;
      const quantityRange = this.splitquantity;
      if (priceRange <= 0 || this.totalQuantity <= 0 || this.splitquantity <= 0) return;

      this.splitOrderSegments = [];
      let totalCalculatedQuantity = 0;

      const calculatePriceAndQuantity = (distributionFunc) => {
        for (let i = 0; i < this.totalQuantity; i++) {
          const { price, quantity } = distributionFunc(i);
          totalCalculatedQuantity += quantity;
          this.splitOrderSegments.push({ price: price.toFixed(2), quantity: quantity.toFixed(1) });
        }
      };

      const averageDistribution = (i) => ({
        price: this.minPrice + (priceRange / (this.totalQuantity - 1)) * i,
        quantity: quantityRange / this.totalQuantity
      });

      const arithmeticDistribution = (i) => {
        const maxQuantity = this.splitquantity / ((this.totalQuantity * (this.totalQuantity + 1)) / 2);
        const priceStep = priceRange / (this.totalQuantity - 1);
        const price = this.sortType === 'ascend'
            ? this.minPrice + priceStep * i
            : this.maxPrice - priceStep * i; // Correctly sort price in descending

        return { price, quantity: maxQuantity * (i + 1) };
      };

      const geometricDistribution = (i, isDescending) => {
        const ratio = Math.pow(this.maxPrice / this.minPrice, 1 / (this.totalQuantity - 1));
        const baseQuantity = Math.pow(quantityRange / 0.1, 1 / (this.totalQuantity - 1));
        const price = isDescending ? this.maxPrice / Math.pow(ratio, i) : this.minPrice * Math.pow(ratio, i);
        const quantity = Math.max(0.1, 0.1 * Math.pow(baseQuantity, i));
        return { price, quantity };
      };

      switch (this.sortType) {
        case 'average':
          calculatePriceAndQuantity(averageDistribution);
          break;
        case 'ascend':
          this.sequenceType === 'arithmetic' ? calculatePriceAndQuantity(arithmeticDistribution) : calculatePriceAndQuantity((i) => geometricDistribution(i, false));
          break;
        case 'descend':
          this.sequenceType === 'arithmetic' ? calculatePriceAndQuantity(arithmeticDistribution) : calculatePriceAndQuantity((i) => geometricDistribution(i, true));
          break;
        case 'random':
          for (let i = 0; i < this.totalQuantity; i++) {
            const price = this.minPrice + Math.random() * priceRange;
            let quantity = Math.max(0.1, parseFloat((Math.random() * quantityRange).toFixed(1)));
            totalCalculatedQuantity += quantity;
            this.splitOrderSegments.push({ price: price.toFixed(2), quantity: quantity.toFixed(1) });
          }
          break;
      }

      if (totalCalculatedQuantity > this.splitquantity) {
        const scale = this.splitquantity / totalCalculatedQuantity;
        this.splitOrderSegments.forEach(segment => {
          segment.splitquantity = Math.max(0.1, (parseFloat(segment.quantity) * scale).toFixed(1));
        });
      }
    },

    async validateAndPlaceSplitOrder(side, posSide) {
      this.side = side;
      this.posSide = posSide;
      // 检查订单有效性
      if (this.isValidOrdersplit) {
        // 构造订单数据
        const orderData = {
          segments: this.splitOrderSegments,
          instId: this.selectedTicker ? this.selectedTicker.instId : null,
          accounts: this.selectedAccounts,
          tdMode: this.tdMode,
          side: this.side,
          posSide: this.posSide,
          ordType: this.ordType,
        };

        // 只有在 tradeType 为 'open' 且 takeProfitPrice 和 stopLossPrice 都不为空时才添加这些参数
        if (
            this.tradeType === 'open' &&
            this.takeProfitPrice &&
            this.stopLossPrice
        ) {
          orderData.takeProfitPrice = this.takeProfitPrice;
          orderData.stopLossPrice = this.stopLossPrice;
        }


        try {
          // 调用 API 下单
          const response = await api.placeOrdersplit(orderData);
          this.toast.success('订单已成功下单！');
          await this.updateMaxAmounts();
          // 成功下单后清空splitOrderSegments
          this.splitOrderSegments = [];
          // 根据实际情况处理返回的数据
        } catch (error) {
          this.toast.error('下单失败，请重试！');
          console.error('下单错误:', error);
        }
      } else {
        this.toast.error('订单无效，请检查输入。');
      }
    },






    updateCallbackRatio() {
      this.callbackRatio = parseFloat(this.callbackRatioSliderValue).toFixed(4);
    },
    updateTpTriggerPx() {
      let basePrice = this.selectedTicker.markPx; // Get the base price
      this.tpTriggerPx = this.calculatePrice(basePrice, this.tpTriggerPxSliderValue);
    },
    updateSlTriggerPx() {
      let basePrice = this.selectedTicker.markPx; // Get the base price
      this.slTriggerPx = this.calculatePrice(basePrice, this.slTriggerPxSliderValue);
    },
    updateTpTriggerPxFromSlider() {
      let basePrice = this.selectedTicker.markPx; // Get the base price
      this.tpTriggerPxSliderValue = parseFloat(((this.tpTriggerPx / basePrice) - 1) * 100).toFixed(2);
    },
    updateSlTriggerPxFromSlider() {
      let basePrice = this.selectedTicker.markPx; // Get the base price
      this.slTriggerPxSliderValue = parseFloat(((this.slTriggerPx / basePrice) - 1) * 100).toFixed(2);
    },



    updatePrice() {
      let basePrice = this.selectedTicker.markPx; // Get the base price depending on the order type
      this.price = this.calculatePrice(basePrice, this.priceSliderValue);
    },
    updateTriggerPrice() {
      let basePrice = this.selectedTicker.markPx;// Get the base price depending on the order type
      this.triggerPrice = this.calculatePrice(basePrice, this.triggerPriceSliderValue);
    },
    updatePriceFromSlider() {
      let basePrice = this.selectedTicker.markPx; // Get the base price depending on the order type
      this.priceSliderValue = parseFloat(((this.price / basePrice) - 1) * 100).toFixed(2);
    },
    updateTriggerPriceFromSlider() {
      let basePrice = this.selectedTicker.markPx; // Get the base price depending on the order type
      this.triggerPriceSliderValue = parseFloat(((this.triggerPrice / basePrice) - 1) * 100).toFixed(2);
    },


    updateTakeProfit() {
      let basePrice = this.getBasePrice();
      this.takeProfit = this.calculatePrice(basePrice, this.sliderValue);
      this.calculateTakeProfitResult();
    },

    updateStopLoss() {
      let basePrice = this.getBasePrice();
      this.stopLoss = this.calculatePrice(basePrice, this.stopLossSliderValue);
      this.calculateStopLossResult();
    },

    updateSliderValueFromPrice() {
      let basePrice = this.getBasePrice();
      this.sliderValue = parseFloat(((this.takeProfit / basePrice) - 1) * 100).toFixed(2);
      this.calculateTakeProfitResult();
    },

    updateStopLossSliderValueFromPrice() {
      let basePrice = this.getBasePrice();
      this.stopLossSliderValue = parseFloat(((this.stopLoss / basePrice) - 1) * 100).toFixed(2);
      this.calculateStopLossResult();
    },

    updateMarketPrices() {
      if (this.selectedTicker && this.selectedTicker.markPx) {
        this.marketPrice = this.selectedTicker.markPx;
        this.takeProfit = this.calculatePrice(this.marketPrice, this.sliderValue);
        this.stopLoss = this.calculatePrice(this.marketPrice, this.stopLossSliderValue);

      }
    },

    getBasePrice() {
      if (this.orderType === 'limit') {
        return this.price;
      } else if (this.orderType === 'trigger') {
        return this.triggerPrice;
      } else if (this.orderType === 'market') {
        return this.marketPrice;
      }
      return 0;
    },

    calculatePrice(basePrice, sliderValue) {
      let calculatedPrice = basePrice * (1 + sliderValue / 100);
      if (calculatedPrice < 0.01 || basePrice.toString().startsWith('0')) {
        return calculatedPrice.toString(); // 不保留小数
      } else {
        return calculatedPrice.toFixed(2); // 保留两位小数
      }
    },


    calculateTakeProfitResult() {
      let basePrice = this.getBasePrice();
      this.calculatedTakeProfitResult = (((this.takeProfit - basePrice) / 10) * this.quantity).toFixed(2);
    },

    calculateStopLossResult() {
      let basePrice = this.getBasePrice();
      this.calculatedStopLossResult = (((this.stopLoss - basePrice) / 10) * this.quantity).toFixed(2);
    },

    updateCalculatedResults() {
      this.calculateTakeProfitResult();
      this.calculateStopLossResult();
    },


    async validateTriggerPrice() {
      // 清除验证错误信息
      this.validationError = '';

      const currentPrice = this.selectedTicker?.markPx;
      if (!currentPrice) {
        return true; // 如果当前价格未加载，暂不进行验证
      }

      // // 校验单向止盈止损
      if (this.orderType === 'conditional') {
        // 如果交易类型是开仓，并且条件类型是止盈止损，并且交易方向是买入
        if (this.tradeType === 'open' && this.conditionalType === 'tp' && this.side === 'buy') {
          // 如果止盈触发价格大于当前价格
          if (this.tpTriggerPx > currentPrice) {
            // 设置验证错误信息
            this.validationError = "开仓、单向止盈止损、条件类型：止盈、“开多” 的止盈触发价格不能“大于”选定交易对的当前价格";
            // 返回false
            return false;
          }
        }

        // 如果交易类型是开仓，条件类型是止盈，并且是卖出
        if (this.tradeType === 'open' && this.conditionalType === 'tp' && this.side === 'sell') {
          // 如果止盈触发价格小于当前价格
          if (this.tpTriggerPx < currentPrice) {
            // 设置验证错误信息
            this.validationError = "开仓、单向止盈止损、条件类型：止盈、“开空” 的止盈触发价格不能“小于”选定交易对的当前价格";
            // 返回false
            return false;
          }
        }

        // 如果交易类型是开仓，条件类型是止损，并且交易方向是卖出
        if (this.tradeType === 'open' && this.conditionalType === 'sl' && this.side === 'sell') {
          // 如果止损触发价格大于当前价格
          if (this.slTriggerPx > currentPrice) {
            // 设置验证错误信息
            this.validationError = "开仓、单向止盈止损、条件类型：止损、“开空” 的止损触发价格不能“大于”选定交易对的当前价格";
            // 返回false
            return false;
          }
        }

        // 如果交易类型是开仓，条件类型是止损，并且方向是买入
        if (this.tradeType === 'open' && this.conditionalType === 'sl' && this.side === 'buy') {
          // 如果止损触发价格小于当前价格
          if (this.slTriggerPx < currentPrice) {
            // 设置验证错误信息
            this.validationError = "开仓、单向止盈止损、条件类型：止损、“开多” 的止损触发价格不能“小于”选定交易对的当前价格";
            // 返回false
            return false;
          }
        }

        if (this.tradeType === 'close' && this.conditionalType === 'tp' && this.side === 'buy') {
          if (this.tpTriggerPx > currentPrice) {
            this.validationError = "平仓、单向止盈止损、条件类型：止盈、“平空” 的止盈触发价格不能“大于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'close' && this.conditionalType === 'tp' && this.side === 'sell') {
          if (this.tpTriggerPx < currentPrice) {
            this.validationError = "平仓、单向止盈止损、条件类型：止盈、“平多” 的止盈触发价格不能“小于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'close' && this.conditionalType === 'sl' && this.side === 'sell') {
          if (this.slTriggerPx > currentPrice) {
            this.validationError = "平仓、单向止盈止损、条件类型：止损、“平多” 的止盈触发价格不能“大于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'close' && this.conditionalType === 'sl' && this.side === 'buy') {
          if (this.slTriggerPx < currentPrice) {
            this.validationError = "平仓、单向止盈止损、条件类型：止损、“平空” 的止盈触发价格不能“小于”选定交易对的当前价格";
            return false;
          }
        }
      }


      //  校验双向止盈止损
      if (this.orderType === 'oco') {
        // 止盈触发价需小于最新成交价、止损触发价需大于最新成交价
        if (this.tradeType === 'open' && this.side === 'buy') {
          if (this.tpTriggerPx > currentPrice || this.slTriggerPx < currentPrice) {
            this.validationError = "开仓、双向止盈止损、“开多” 的止盈触发价需“小于”选定交易对的当前价格，止损触发价需“大于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'open' && this.side === 'sell') {
          if (this.tpTriggerPx < currentPrice || this.slTriggerPx > currentPrice) {
            this.validationError = "开仓、双向止盈止损、“开空” 的止盈触发价需“大于”选定交易对的当前价格，止损触发价需“小于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'close' && this.side === 'sell') {
          if (this.tpTriggerPx < currentPrice || this.slTriggerPx > currentPrice) {
            this.validationError = "平仓、双向止盈止损、“平多” 的止盈触发价需“大于”选定交易对的当前价格，止损触发价需“小于”选定交易对的当前价格";
            return false;
          }
        }

        if (this.tradeType === 'close' && this.side === 'buy') {
          if (this.tpTriggerPx > currentPrice || this.slTriggerPx < currentPrice) {
            this.validationError = "平仓、双向止盈止损、“平空” 的止盈触发价需“小于”选定交易对的当前价格，止损触发价需“大于”选定交易对的当前价格";
            return false;
          }
        }

      }

      return true;
    },




    // async verifyTradePassword() {
    //   const tradePassword = prompt("请输入6位交易密码：");
    //   if (!tradePassword || tradePassword.length !== 6) {
    //     this.toast.error("请输入6位有效的交易密码");
    //     return false;
    //   }
    //
    //   try {
    //     const response = await api.verifyTradePassword(tradePassword);
    //     if (response.success) {
    //       return true;
    //     } else {
    //       this.toast.error(response.message || "交易密码验证失败");
    //       return false;
    //     }
    //   } catch (error) {
    //     console.error("交易密码验证错误:", error);
    //     this.toast.error("交易密码验证失败，请稍后再试");
    //     return false;
    //   }
    // },




    // 适用于开多和开空的逻辑
    async validateAndPlaceOrder(side, posSide) {

      this.side = side;

      if (!this.selectedTicker || this.selectedAccounts.length === 0) {
        this.toast.error('请选择交易对和至少一个账户');
        return;
      }

      if (!this.isValidOrder) {
        this.toast.error('订单信息不完整或不正确，请检查输入的字段。');
        return;
      }

      if (this.tradeType === 'open' && this.orderType !== 'market' && !['conditional', 'oco', 'move_order_stop'].includes(this.orderType)) {
        if (!this.validateOrderPrice(side)) return;
      }

      if (!await this.validateTriggerPrice()) {
        this.toast.error(this.validationError);
        return;
      }

      await this.placeOrder(side, posSide);
    },

    async placeOrder(side, posSide) {
      const baseOrderData = this.buildBaseOrderData(side, posSide);

      try {
        const orderPromises = this.selectedAccounts.map(account => this.createOrderPromise(account, baseOrderData, side));
        const responses = await Promise.all(orderPromises.filter(p => p !== null));

        this.handleOrderResponses(responses);
      } catch (error) {
        this.handleOrderError(error);
      }
    },

    createOrderPromise(account, baseOrderData, side) {
      const orderData = { ...baseOrderData, accounts: account };
      const amount = this.$refs.maxAmountTable.maxAmounts.find(a => a.account.replace(/[^0-9]/g, '') === account);

      if (!amount) {
        this.toast.error(`账户 ${account} 的最大可买数量未找到`);
        return null;
      }

      let orderAmount = this.calculateOrderAmount(account, amount, side);
      if (!orderAmount || orderAmount <= 0) {
        this.toast.error(`账户 ${account} 的下单数量无效`);
        return null;
      }

      orderData.sz = orderAmount.toString();
      return ['trigger', 'conditional', 'oco', 'move_order_stop']
          .includes(this.orderType)
          ? api.placeOrderAlgo(orderData)
          : api.placeOrder(orderData);
    },



    // 新增适用于平多和平空的逻辑
    async validateAndCloseOrder(side, posSide) {

      this.side = side;

      if (!this.selectedTicker || this.selectedAccounts.length === 0) {
        this.toast.error('请选择交易对和至少一个账户');
        return;
      }

      if (!this.isValidOrder) {
        this.toast.error('订单信息不完整或不正确，请检查输入的字段。');
        return;
      }

      await this.closeOrder(side, posSide);
    },

    async closeOrder(side, posSide) {
      const baseOrderData = this.buildBaseOrderData(side, posSide);

      try {
        const orderPromises = this.selectedAccounts.map(account => this.createCloseOrderPromise(account, baseOrderData, side));
        const responses = await Promise.all(orderPromises.filter(p => p !== null));

        this.handleOrderResponses(responses);
      } catch (error) {
        this.handleOrderError(error);
      }
    },

    createCloseOrderPromise(account, baseOrderData, side) {
      const orderData = { ...baseOrderData, accounts: account };
      const positionTable = this.$refs.maxClosePositionTable;
      const position = positionTable.filteredPositions.find(p => p.account === `账户${account}`);

      if (!position) {
        console.error(`No position found for account ${account}`);
        this.toast.error(`未找到账户 ${account} 的持仓信息`);
        return null;
      }

      if (this.useCloseFraction) {
        orderData.closeFraction = this.closeFraction.toString();
      } else {
        const amountToClose = side === 'buy' ? position.shortAmount : position.longAmount;
        const orderAmount = this.usePercentage
            ? (amountToClose * this.percentage / 100).toFixed(1)
            : Math.min(this.quantity, amountToClose);

        if (!orderAmount || orderAmount <= 0) {
          this.toast.error(`账户 ${account} 的下单数量无效`);
          return null;
        }

        orderData.sz = orderAmount.toString();
      }

      return ['trigger', 'conditional', 'oco', 'move_order_stop'].includes(this.orderType)
          ? api.placeOrderAlgo(orderData)
          : api.placeOrder(orderData);
    },



    validateOrderPrice(side) {
      const orderPrice = this.orderType === 'limit' ? this.price : this.triggerPrice;
      if (side === 'buy' && (this.takeProfit <= orderPrice || this.stopLoss >= orderPrice)) {
        this.toast.error('开多：止盈价格必须大于委托价格，止损价格必须小于委托价格。');
        return false;
      }
      if (side === 'sell' && (this.takeProfit >= orderPrice || this.stopLoss <= orderPrice)) {
        this.toast.error('开空：止盈价格必须小于委托价格，止损价格必须大于委托价格。');
        return false;
      }
      return true;
    },

    buildBaseOrderData(side, posSide) {
      const baseOrderData = {
        instId: this.selectedTicker.instId,
        tdMode: 'cross',
        side: side,
        posSide: posSide,
        ordType: this.orderType,
      };

      if (this.orderType === 'limit') baseOrderData.px = this.price.toString();
      if (this.orderType === 'trigger') baseOrderData.triggerPx = this.triggerPrice.toString();
      if (this.orderType === 'conditional') this.addConditionalOrderData(baseOrderData);
      if (this.orderType === 'oco') this.addOcoOrderData(baseOrderData);
      if (this.orderType === 'move_order_stop') this.addMoveOrderStopData(baseOrderData);
      if (!['conditional', 'oco', 'move_order_stop'].includes(this.orderType) && this.tradeType === 'open') {
        this.addStopLossAndTakeProfit(baseOrderData);
      }

      return baseOrderData;
    },

    addConditionalOrderData(orderData) {
      orderData.triggerPx = this.triggerPrice.toString();
      if (this.conditionalType === 'tp') {
        orderData.tpTriggerPx = this.tpTriggerPx.toString();
        orderData.tpOrdPx = "-1"; // 市价止盈
      } else {
        orderData.slTriggerPx = this.slTriggerPx.toString();
        orderData.slOrdPx = "-1"; // 市价止损
      }
    },

    addOcoOrderData(orderData) {
      orderData.tpTriggerPx = this.tpTriggerPx.toString();
      orderData.tpOrdPx = "-1"; // 市价止盈
      orderData.slTriggerPx = this.slTriggerPx.toString();
      orderData.slOrdPx = "-1"; // 市价止损
    },

    addMoveOrderStopData(orderData) {
      if (this.callbackType === 'ratio') {
        orderData.callbackRatio = this.callbackRatio.toString();
      } else if (this.callbackType === 'spread') {
        orderData.callbackSpread = this.callbackSpread.toString();
      }
    },

    addStopLossAndTakeProfit(orderData) {
      if (this.takeProfit) {
        orderData.tpTriggerPx = this.takeProfit.toString();
        orderData.tpOrdPx = this.takeProfit.toString();
      }
      if (this.stopLoss) {
        orderData.slTriggerPx = this.stopLoss.toString();
        orderData.slOrdPx = this.stopLoss.toString();
      }
    },

    calculateOrderAmount(account, amount, side) {
      if (this.tradeType === 'open') {
        const maxValue = side === 'buy' ? amount.maxBuy : amount.maxSell;
        return this.usePercentage ? (maxValue * this.percentage / 100).toFixed(1) : Math.min(this.quantity, maxValue);
      } else if (this.tradeType === 'close') {
        const positionTable = this.$refs.maxClosePositionTable;
        const position = positionTable.filteredPositions.find(p => p.account === `账户${account}`);
        const amountToClose = side === 'buy' ? position.shortAmount : position.longAmount;
        return this.usePercentage ? (amountToClose * this.percentage / 100).toFixed(1) : Math.min(this.quantity, amountToClose);
      }
      return 0;
    },

    handleOrderResponses(responses) {
      // 筛选成功的订单（至少有一个成功的订单）
      const successfulOrders = responses.filter(response =>
          response.data.some(r => r.success)
      );
      // console.log(successfulOrders)

      // 筛选失败的订单（所有订单都未成功）
      const failedOrders = responses.filter(response =>
          response.data.every(r => !r.success)
      );
      // console.log(failedOrders)

      // 处理成功的订单
      if (successfulOrders.length > 0) {
        successfulOrders.forEach(response => {
          const successDetails = response.data
              .filter(r => r.success)
              .map(r => {
                const sMsg = r.data.data.map(d => d.sMsg).join('; ');
                return `账户 ${r.account}: ${sMsg || '下单成功'}`;
              })
              .join(', ');

          this.toast.success(`${successDetails}下单成功`);
        });
        this.updateMaxAmounts();
      }

      // 处理失败的订单
      if (failedOrders.length > 0) {
        failedOrders.forEach(response => {
          const failureDetails = response.data
              .map(r => r.data.data.map(d => d.sMsg).join(', '))
              .join('; ');

          this.toast.error(`账户 ${response.account} 下单失败: ${failureDetails}`);
        });
      }
    },

    handleOrderError(error) {
      console.error('下单出错:', error);
      if (error.response && error.response.data) {
        this.toast.error(`下单出错: ${JSON.stringify(error.response.data)}`);
      } else {
        this.toast.error('下单出错，请查看控制台');
      }
    }
  },



  watch: {
    minPrice: 'calculateSegments',
    maxPrice: 'calculateSegments',
    totalQuantity: 'calculateSegments',
    splitquantity: 'calculateSegments',


    marketPrice() {
      this.updateCalculatedResults();
    },
    takeProfit() {
      this.calculateTakeProfitResult();
    },
    stopLoss() {
      this.calculateStopLossResult();
    },
    quantity() {
      this.updateCalculatedResults();
    },

    orderType(){
      this.updateTakeProfit();
      this.updateStopLoss();
    },

    price() {
      this.updateTakeProfit();
      this.updateStopLoss();
    },

    triggerPrice() {
      this.validateTriggerPrice();
      this.updateTakeProfit();
      this.updateStopLoss();
    },

    conditionalType() {
      this.validateTriggerPrice();
    },

    tradeType(newType) {
      this.percentage = newType === 'open' ? this.openPercentage : this.closePercentage;
    },

    percentage(newPercentage) {
      if (this.tradeType === 'open') {
        this.openPercentage = Math.min(newPercentage, 10); // 限制开仓百分比最大为 10%
      } else {
        this.closePercentage = newPercentage; // 平仓百分比可以到 100%
      }
    },

    openPercentage(newPercentage) {
      if (this.tradeType === 'open') {
        this.percentage = newPercentage;
      }
    },

    closePercentage(newPercentage) {
      if (this.tradeType === 'close') {
        this.percentage = newPercentage;
      }
    },


    'selectedTicker.markPx'() {
      this.validateTriggerPrice();
      if (this.orderType === 'market') {
        this.updateMarketPrices();
      }
    },

    sliderValue() {
      this.updateTakeProfit(); // 滑块值变化时更新止盈价格
    },
    stopLossSliderValue() {
      this.updateStopLoss(); // 滑块值变化时更新止损价格
    },


    tpTriggerPx() {
      this.validateTriggerPrice();
    },
    slTriggerPx() {
      this.validateTriggerPrice();
    },
  },
};


</script>

<style scoped>
/* 容器布局 */
.trading-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1px;

}

.trading-panel {
  background-color: #000000;
  width: 100%;

}

.trade-options {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-right: 10px;
}

.trade-type-switch, .order-type-switch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}

/* 统一按钮样式 */
.switch-btn,
.action-btn {
  width: 90%;
  padding: 10px;
  border: none;
  border-radius: 12px;
  background-color: #3c3c3c;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s ease;
}

.switch-btn.active,
.action-btn.split-order:hover {
  background-color: #4caf50;
}

.switch-btn:active,
.action-btn:active {
  transform: scale(0.98);
}

/* 禁用状态 */
.disabled-btn {
  background-color: #888888;
  cursor: not-allowed;
}

/* 表单样式 */
.form-group0 {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}

.form-item0 {
  flex: 1 1 calc(50% - 10px); /* 适应两列布局 */
  display: flex;
  flex-direction: column;
}

.input-field0 {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  background-color: #1a1a1a;
  color: #ffffff;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #4caf50;
  outline: none;
}


/* 表格容器样式 */
.table-container0 {
  max-height: 400px; /* 设置最大高度 */
  overflow-y: auto; /* 启用垂直滚动条 */
  margin-right: 10px;
}

/* 表格样式 */
.order-split-table {
  width: 100%;
  border-collapse: collapse;
}

.order-split-table th, .order-split-table td {
  padding: 1px;
  text-align: center;
  border-bottom: 1px solid #3c3c3c;
}

.order-split-table input {
  width: 80%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  background-color: #1a1a1a;
  color: #ffffff;

}


.action-buttons0 {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  margin-top: 10px;
}

.action-btn {
  width: 49%;
  padding: 10px;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  cursor: pointer;
}

.open-long, .close-long {
  background-color: #4caf50;
}

.open-short, .close-short {
  background-color: #f44336;
}



/* 响应式调整 */
@media (max-width: 600px) {

  /* 容器布局 */
  .trading-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1px;

  }

  .trading-panel {
    background-color: #000000;
    width: 105%;
    margin-right: -20px;

  }

  .table-container {
    max-height: 400px; /* 设置最大高度 */
    overflow-y: auto; /* 启用垂直滚动条 */
    margin-right: 20px;
  }


  .form-item {
    flex: 1 1 100%; /* 使用全宽 */
    margin-right: 20px;
  }

  .switch-btn,
  .action-btn {
    width: 100%; /* 使用全宽 */
    margin-bottom: 10px; /* 增加间距 */
  }
}




.slider-container {
  display: flex;
  align-items: center;
}

.slider {
  flex: 1;
  margin-right: 10px;
}
.ticker-card {
  background-color: #3c3c3c;
  border-radius: 20px;
  padding: 2px;
  text-align: center;
  margin-bottom: 1px;
  margin-right: 20px;
  margin-top: 1px;
}

.ticker-name {
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
  color: #b3b3b3;
}

.ticker-price {
  color: #4caf50;
  font-size: 22px;
  font-weight: bold;
}

.trade-options {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
}

.trade-type-switch, .order-type-switch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.switch-btn {
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #3c3c3c;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch-btn.active {
  background-color: #4caf50;
}

.input-fields {
  flex-grow: 1;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start */
  width: auto;

}


.input-field {
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 15px; /* Keep the input field height */
  padding: 10px;
  margin-bottom: -25px; /* Reduce margin for tighter spacing */
  margin-top: -30px;
  font-size: 16px;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  background-color: #1a1a1a;
  color: #ffffff;
  width: 40%;
}


.calculated-result-field {
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 15px; /* Keep the input field height */
  padding: 10px;
  margin-bottom: -25px; /* Reduce margin for tighter spacing */
  margin-top: -30px;
  font-size: 16px;
  border: 1px solid #3c3c3c;
  border-radius: 10px;
  background-color: #1a1a1a;
  color: #ffffff;
  width: 20%;
}

.input-percentage{
  font-size: 14px;
  width: 18%;
  height: 15px;
}

.form-group {
  flex-wrap: nowrap;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  font-size: 17px;
}



.quantity-slider {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.quantity-slider input[type="range"] {
  width: 90%;
  margin-right: 10px;
}

.quantity-slider span {
  min-width: 30px;
  text-align: right;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-top: -10px;
}

.action-btn {
  width: 49%;
  padding: 10px;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  cursor: pointer;
}

.open-long, .close-long {
  background-color: #4caf50;
}

.open-short, .close-short {
  background-color: #f44336;
}

.disabled-btn {
  background-color: #888888;
  cursor: not-allowed;
}




.order-quantity-switch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}





.select-field {
  height: 36px; /* 调整高度 */
  padding: 0 30px 0 10px; /* 右侧留出空间给箭头图标 */
  margin-bottom: 15px;
  font-size: 16px; /* 稍微调小字体 */
  border: 1px solid #3c3c3c;
  border-radius: 4px;
  background-color: #1a1a1a;
  color: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  cursor: pointer;
  transition: border-color 0.3s;
  width: 30%;
}

.select-field:focus {
  outline: none;
  border-color: #4caf50;
}

.select-field:hover {
  border-color: #4caf50;
}

.select-field option {
  background-color: #2c2c2c;
  color: #ffffff;
  padding: 8px;
}

/* 为移动设备优化下拉框 */
@media (max-width: 480px) {
  .select-field {
    font-size: 14px; /* 防止 iOS 缩放 */
  }
}



</style>