<template>

  <div class="crypto-tickers" ref="tickerContainer">

    <!-- 卡片 -->
    <div
        v-for="(item, index) in combinedTickers"
        :key="item.instId + '-' + index"
        class="ticker-card"
        :class="{
          'selected': item.type === 'ticker' && item.instId === selectedTickerId,
          'index-ticker-card': item.type === 'index'

        }"
        @click="item.type === 'ticker' && selectTicker(item)"
        @mousemove="item.type === 'index' && showTooltip($event, item)"
        @mouseleave="hideTooltip"
    >

      <template v-if="item.type === 'ticker'">
        <span class="ticker-name">{{ formatTickerName(item.instId) }}</span>
        <span class="ticker-price" :class="{ 'loading': !item.markPx }">
          {{ item.markPx || 'Loading...' }}
        </span>
      </template>

      <template v-else-if="item.type === 'index'">


<!--        <span class="index-ticker-name">{{ formatTickerName0(item.instId) }}</span>-->
<!--        <span class="index-ticker-price" :class="{ 'loading': !item.idxPx }">-->
<!--          {{ item.idxPx || 'Loading...' }}-->
<!--        </span>-->


        <span
            class="index-ticker-change"
            :class="{
            'positive': calculatePercentageChange(item.idxPx, item.sodUtc8) > 0,
            'negative': calculatePercentageChange(item.idxPx, item.sodUtc8) < 0
          }"
        >
          {{ calculatePercentageChange(item.idxPx, item.sodUtc8) }}%
        </span>

      </template>

    </div>

    <!-- 悬浮窗 -->
    <!-- 悬浮窗优化 -->
    <div v-if="tooltipVisible" class="tooltip" :style="tooltipStyle">
      {{ tooltipData.instId }} {{ tooltipData.idxPx }}

      <!-- 优化悬浮窗的百分比变化计算 -->
      <div v-for="(value, key) in tooltipDataKeys" :key="key" class="tooltip-row">
        <div>{{ key }}: {{ formatPrice(tooltipData[value]) }}</div>
        <div class="tooltip-change" :class="getChangeClass(tooltipData.idxPx, tooltipData[value])">
          {{ getChangePercentage(tooltipData.idxPx, tooltipData[value]) }}%

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CryptoTickers',
  data() {
    return {
      tickers: [
        { instId: 'BTC-USDT-SWAP', markPx: null },
        { instId: 'ETH-USDT-SWAP', markPx: null },
        { instId: 'SOL-USDT-SWAP', markPx: null },
        { instId: 'DOGE-USDT-SWAP', markPx: null },
        { instId: 'LTC-USDT-SWAP', markPx: null },
        { instId: 'PEPE-USDT-SWAP', markPx: null },
        { instId: 'BNB-USDT-SWAP', markPx: null },
        { instId: 'XRP-USDT-SWAP', markPx: null },
        { instId: 'NOT-USDT-SWAP', markPx: null },
        { instId: 'ETHFI-USDT-SWAP', markPx: null },
        { instId: 'MEW-USDT-SWAP', markPx: null },
        { instId: 'OM-USDT-SWAP', markPx: null },
        { instId: 'TIA-USDT-SWAP', markPx: null },
        { instId: 'TNSR-USDT-SWAP', markPx: null },
        { instId: 'TURBO-USDT-SWAP', markPx: null },
        { instId: 'WLD-USDT-SWAP', markPx: null },
        { instId: 'FLOKI-USDT-SWAP', markPx: null },
        { instId: 'SATS-USDT-SWAP', markPx: null },
        { instId: 'SUI-USDT-SWAP', markPx: null },
        { instId: 'AAVE-USDT-SWAP', markPx: null },
        { instId: 'PEOPLE-USDT-SWAP', markPx: null },
        { instId: 'CRV-USDT-SWAP', markPx: null },
        { instId: 'AVAX-USDT-SWAP', markPx: null },
        { instId: 'LINK-USDT-SWAP', markPx: null },
      ],
      indexTickers: [
        { instId: 'BTC-USDT', idxPx: null },
        { instId: 'ETH-USDT', idxPx: null },
        { instId: 'SOL-USDT', idxPx: null },
        { instId: 'DOGE-USDT', idxPx: null },
        { instId: 'LTC-USDT', idxPx: null },
        { instId: 'PEPE-USDT', idxPx: null },
        { instId: 'BNB-USDT', idxPx: null },
        { instId: 'XRP-USDT', idxPx: null },
        { instId: 'NOT-USDT', idxPx: null },
        { instId: 'ETHFI-USDT', idxPx: null },
        { instId: 'MEW-USDT', idxPx: null },
        { instId: 'OM-USDT', idxPx: null },
        { instId: 'TIA-USDT', idxPx: null },
        { instId: 'TNSR-USDT', idxPx: null },
        { instId: 'TURBO-USDT', idxPx: null },
        { instId: 'WLD-USDT', idxPx: null },
        { instId: 'FLOKI-USDT', idxPx: null },
        { instId: 'SATS-USDT', idxPx: null },
        { instId: 'SUI-USDT', idxPx: null },
        { instId: 'AAVE-USDT', idxPx: null },
        { instId: 'PEOPLE-USDT', idxPx: null },
        { instId: 'CRV-USDT', idxPx: null },
        { instId: 'AVAX-USDT', idxPx: null },
        { instId: 'LINK-USDT', idxPx: null },
      ],
      ws: null,
      selectedTickerId: 'ETH-USDT-SWAP',
      currentIndexData: null, // 当前选中的index数据
      // indexselectedTickerId: 'ETH-USDT',
      reconnectAttempts: 0,
      maxReconnectAttempts: 10,
      reconnectInterval: 100,
      isConnecting: false,

      isDisconnecting: false, // 新增


      tooltipVisible: false,
      tooltipStyle: {},
      tooltipData: {},
      tooltipDataKeys: {
        open24H: 'open24h',
        high24H: 'high24h',
        low24H: 'low24h',
        sodUtc0: 'sodUtc0',
        sodUtc8: 'sodUtc8'
      },
    };
  },

  computed: {
    combinedTickers() {
      const combined = [];
      const maxLength = Math.max(this.tickers.length, this.indexTickers.length);

      for (let i = 0; i < maxLength; i++) {
        if (i < this.tickers.length) {
          combined.push({ ...this.tickers[i], type: 'ticker' });
        }
        if (i < this.indexTickers.length) {
          combined.push({ ...this.indexTickers[i], type: 'index' });
        }
      }

      return combined;
    }
  },

  created() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },

  mounted() {
    this.updatePageTitle();
    this.initWebSocket();
    this.addScrollEventListener();
    this.selectDefaultTicker();
    this.addTouchEventListeners();
  },

  beforeUnmount() {
    this.disconnectWebSocket();
    this.removeScrollEventListener();
    this.removeTouchEventListeners();
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },

  methods: {

    getChangePercentage(current, base) {
      return this.calculatePercentageChange(current, base);
    },
    getChangeClass(current, base) {
      const change = this.calculatePercentageChange(current, base);
      return {
        positive: change > 0,
        negative: change < 0
      };
    },

    initWebSocket() {
      if (!this.isConnecting) {
        this.isConnecting = true;
        this.connectWebSocket();
      }
    },


    connectWebSocket() {
      if (this.ws && (this.ws.readyState === WebSocket.CONNECTING || this.ws.readyState === WebSocket.OPEN)) {
        this.isConnecting = false;
        return;
      }

      this.ws = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');

      this.ws.onopen = () => {
        this.reconnectAttempts = 0;
        this.isConnecting = false;
        this.isDisconnecting = false; // 重置断开标志
        // this.subscribeToMarkPrices();
        // this.subscribeToIndexPrices();
        this.subscribeToAllPrices()
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.event === 'subscribe') {
          // 订阅确认消息，不需要处理
          return;
        }

        // 处理市场价格更新消息
        if (data.arg && data.arg.channel === 'mark-price' && Array.isArray(data.data) && data.data.length > 0) {
          this.updateTickerPrice(data.data[0]);
        }

        // 处理指数价格更新消息
        else if (data.arg && data.arg.channel === 'index-tickers' && Array.isArray(data.data) && data.data.length > 0) {
          this.updateIndexPrice(data.data[0]);

        }

        // 处理未知消息类型
        else {
          console.warn('Received unexpected message structure:', data);
        }
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.reconnect();
      };

      this.ws.onclose = (event) => {
        console.log('CryptoTickers WebSocket disconnected:', event.code, event.reason);
        this.reconnect();
      };
    },

    reconnect() {
      if (this.isDisconnecting) {
        console.log("WebSocket manually disconnected, skipping reconnect.");
        this.isConnecting = false;
        return;
      }

      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
        setTimeout(() => this.connectWebSocket(), this.reconnectInterval);
      } else {
        console.error('Max reconnect attempts reached. Please check your network connection.');
        this.isConnecting = false;
      }
    },

    // 更新Ticker价格
    updateTickerPrice(data) {
      // console.log(data)
      const ticker = this.tickers.find(t => t.instId === data.instId);
      if (ticker) {
        ticker.markPx = this.formatPrice(data.markPx);


        // 检查 ticker.instId 是否与选中的 selectedTickerId 匹配
        if (ticker.instId === this.selectedTickerId) {
          this.$eventBus.emit('ticker-price-updated', {
            instId: ticker.instId,
            markPx: ticker.markPx
          });
          // console.log(ticker.instId, ticker.markPx);
        }

        // 更新页面标题
        // if (ticker.instId === this.selectedTickerId) {
        //   this.updatePageTitle();
          // this.updatePageTitleForIndex()
        // }
      }
    },

    // 更新Index价格
    updateIndexPrice(data) {
      const ticker = this.indexTickers.find(t => t.instId === data.instId);
      if (ticker) {
        const fieldsToUpdate = ['idxPx', 'open24h', 'high24h', 'low24h', 'sodUtc0', 'sodUtc8'];

        fieldsToUpdate.forEach(field => {
          ticker[field] = this.formatPrice(data[field]);
        });

        // 更新悬浮窗数据
        if (this.tooltipVisible && this.tooltipData.instId === data.instId) {
          this.tooltipData = { ...ticker }; // 克隆ticker到tooltipData中
        }

        this.updatePageTitle()

        // // 更新页面标题
        // if (ticker.instId === this.selectedTickerId) {
        //   this.updatePageTitleForIndex(ticker);
        // }
      }
    },


    calculatePercentageChange(currentPrice, referencePrice) {
      if (referencePrice === 0) return 'N/A'; // 避免除以零
      const change = ((currentPrice - referencePrice) / referencePrice) * 100;
      return change.toFixed(2);

    },


    formatPrice(value) {
      const parsedValue = parseFloat(value);

      // 将数值转换为普通的小数形式，避免科学计数法
      const normalizedValue = parsedValue.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,  // 设置合理的最大小数位数
        useGrouping: false          // 禁用千位分隔符
      });

      if (parsedValue < 0.01 || value.startsWith('0')) {
        return normalizedValue;
      } else {
        return parsedValue.toFixed(2);
      }
    },

    formatTickerName(instId) {
      return instId.replace(/-USDT-SWAP$/, '');
    },


    formatTickerName0(instId) {
      return instId.replace(/-USDT$/, '');
    },



    // 更新页面标题
    updatePageTitle() {
      if (this.currentIndexData) {
        const percentageChange = this.calculatePercentageChange(
            this.currentIndexData.idxPx,
            this.currentIndexData.sodUtc8
        );
        document.title = `${this.currentIndexData.idxPx || 'Loading...'} ${percentageChange}% ${this.currentIndexData.instId} `;
      }
    },


    // 更新页面标题
    updatePageTitleForIndex(ticker, indexData) {
      const percentageChange = this.calculatePercentageChange(indexData.idxPx, indexData.sodUtc8);
      // console.log(percentageChange)
      document.title = `${ticker.instId} Index: ${indexData.idxPx || 'Loading...'} (${percentageChange}%)`;
    },



    // 查找对应的 index 数据
    findIndexData(ticker) {
      // 假设 ticker 的 instId 是 'BTC-USDT-SWAP'，index 的 instId 是 'BTC-USDT'
      const indexInstId = ticker.instId.replace('-SWAP', ''); // 替换 SWAP 后缀
      // console.log(indexInstId);

      return this.indexTickers.find(index => index.instId === indexInstId);
    },





    subscribeToAllPrices() {
      if (this.ws.readyState === WebSocket.OPEN) {
        const subscribeMessages = [
          {
            op: 'subscribe',
            args: this.tickers.map(ticker => ({
              channel: 'mark-price',
              instId: ticker.instId
            }))
          },
          {
            op: 'subscribe',
            args: this.indexTickers.map(ticker => ({
              channel: 'index-tickers',
              instId: ticker.instId
            }))
          }
        ];

        // 发送两个订阅消息
        subscribeMessages.forEach(message => {
          this.ws.send(JSON.stringify(message));
        });
      } else {
        console.warn('WebSocket is not open. Unable to subscribe to prices.');
      }
    },


    subscribeToMarkPrices() {
      if (this.ws.readyState === WebSocket.OPEN) {
        const subscribeMessage = {
          op: 'subscribe',
          args: this.tickers.map(ticker => ({
            channel: 'mark-price',
            instId: ticker.instId
          }))
        };
        this.ws.send(JSON.stringify(subscribeMessage));
      } else {
        console.warn('WebSocket is not open. Unable to subscribe to mark prices.');
      }
    },
    subscribeToIndexPrices() {
      if (this.ws.readyState === WebSocket.OPEN) {
        const subscribeMessage = {
          op: 'subscribe',
          args: this.indexTickers.map(ticker => ({
            channel: 'index-tickers',
            instId: ticker.instId
          }))
        };
        this.ws.send(JSON.stringify(subscribeMessage));
      } else {
        console.warn('WebSocket is not open. Unable to subscribe to index prices.');
      }
    },



    disconnectWebSocket() {
      if (this.ws) {
        this.isDisconnecting = true; // 设置手动断开标志
        this.ws.close();
        this.ws = null;
        console.log("CryptoTickers WebSocket disconnected.");
      }
    },

    handleOnline() {
      console.log('Network is online. Reconnecting WebSocket...');
      this.initWebSocket();
    },

    handleOffline() {
      console.log('Network is offline. WebSocket will attempt to reconnect when online.');
    },

    showTooltip(event, item) {
      // 只显示 index ticker 的悬浮窗
      this.tooltipData = item;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let tooltipX = event.clientX;
      let tooltipY = event.clientY;

      const tooltipWidth = 200; // 悬浮窗宽度
      const tooltipHeight = 150; // 悬浮窗高度

      // 判断悬浮窗是否超出屏幕边界并调整位置
      if (tooltipX + tooltipWidth > windowWidth) {
        tooltipX = windowWidth - tooltipWidth - 10; // 保持10px边距
      }

      if (tooltipY + tooltipHeight > windowHeight) {
        tooltipY = windowHeight - tooltipHeight - 10; // 保持10px边距
      }

      // 设置悬浮窗的位置
      this.tooltipStyle = {
        position: 'absolute',
        left: `${tooltipX - 100}px`,
        top: `${tooltipY + 20}px`,
        transform: 'translate(0, 0)'  // 取消之前的偏移
      };

      this.tooltipVisible = true;
    },

    hideTooltip() {
      this.tooltipVisible = false;
    },

    selectTicker(ticker) {
      // console.log('Selected ticker:', ticker);
      this.selectedTickerId = ticker.instId;
      this.$eventBus.emit('ticker-selected', ticker);
      // console.log('ticker-selected event emitted:', ticker);


      // 查找对应的 index 数据
      const indexData = this.findIndexData(ticker);

      if (indexData) {
        // 设置当前 index 数据
        this.currentIndexData = indexData;

        // 每隔一段时间更新一次页面标题
        this.updatePageTitle();
      } else {
        // 如果没有找到对应的 index 数据，默认显示 ticker 的信息
        document.title = `${ticker.instId} ${ticker.markPx || 'Loading...'}`;
      }
    },



    selectDefaultTicker() {
      const defaultTicker = this.tickers.find(t => t.instId === this.selectedTickerId);
      if (defaultTicker) {
        this.selectTicker(defaultTicker);
      }
    },

    addScrollEventListener() {
      const container = this.$refs.tickerContainer;
      container.addEventListener('wheel', this.handleWheelScroll);
    },

    removeScrollEventListener() {
      const container = this.$refs.tickerContainer;
      container.removeEventListener('wheel', this.handleWheelScroll);
    },

    handleWheelScroll(event) {
      const container = this.$refs.tickerContainer;

      // 如果事件目标不是输入框或数字输入框
      if (!event.target.closest('input[type="number"]') && container.contains(event.target)) {
        event.preventDefault();
        const scrollSpeed = 1;
        container.scrollLeft += event.deltaY * scrollSpeed;
      }
    },

    addTouchEventListeners() {
      const container = this.$refs.tickerContainer;
      container.addEventListener('touchstart', this.handleTouchStart);
      container.addEventListener('touchmove', this.handleTouchMove);
    },

    removeTouchEventListeners() {
      const container = this.$refs.tickerContainer;
      container.removeEventListener('touchstart', this.handleTouchStart);
      container.removeEventListener('touchmove', this.handleTouchMove);
    },

    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },

    handleTouchMove(event) {
      if (!this.touchStartX) return;
      const touchEndX = event.touches[0].clientX;
      const diffX = this.touchStartX - touchEndX;
      const container = this.$refs.tickerContainer;
      container.scrollLeft += diffX;
      this.touchStartX = touchEndX;
    },


  },
};
</script>

<style scoped>
.crypto-tickers {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 1px;
  display: flex;
  gap: 1px;
  padding: 5px;
  background-color: #1a1a1a;
  overflow-x: hidden; /* 允许水平滚动 */
  white-space: nowrap; /* 防止换行 */
  scroll-behavior: auto; /* 平滑滚动 */
  -webkit-overflow-scrolling: auto; /* 启用iOS的回弹滚动效果 */
}

.ticker-card {

  background-color: #2c2c2c;
  border-radius: 100px;
  padding: 10px 15px; /* 调整内边距 */
  min-width: auto; /* 自适应宽度 */
  text-align: center;
  color: #ffffff;

  transition: transform 0.3s ease;
  cursor: pointer;
  display: inline-block; /* 使卡片在一行内显示 */
}

.ticker-card:hover {
  transform: translateY(-5px);
  border: 1px solid #4caf50;
}

.ticker-name {
  font-weight: bold;
  margin-right: 1px;
  font-size: 18px;
  color: #b3b3b3;
}

.ticker-price {
  color: #4caf50;
  font-size: 18px;
  font-weight: bold;
}

.ticker-price.loading {
  opacity: 0.5;
}

.ticker-card.selected {
  border: 2px solid #4caf50;
  transform: translateY(-5px);
}





.index-tickers {
  margin-top: 5px;
  margin-bottom: -20px;
  display: flex;
  gap: 10px;
  padding: 5px;
  background-color: #1a1a1a;
  overflow-x: hidden; /* 允许水平滚动 */
  white-space: nowrap; /* 防止换行 */
  scroll-behavior: auto; /* 平滑滚动 */
  -webkit-overflow-scrolling: auto; /* 启用iOS的回弹滚动效果 */
}

.index-ticker-card {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 1px 1px; /* 缩小内边距 */
  min-width: auto; /* 缩小卡片的最小宽度 */
  text-align: center;
  color: #ffffff;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: inline-block;
}

.index-ticker-card:hover {
  transform: translateY(-5px);
  border: 1px solid #4caf50;
}

.index-ticker-name {
  font-weight: bold;
  margin-right: 1px;
  font-size: 18px;
  color: #b3b3b3;
}

.index-ticker-price {
  color: #4caf50;
  font-size: 18px;
  font-weight: bold;
}

.index-ticker-price.loading {
  opacity: 0.5;
}

.index-ticker-card.selected {
  border: 2px solid #4caf50;
  transform: translateY(-5px);
}

.index-ticker-change.positive {
  color: #4caf50;
  padding-left: 1px;
  font-size: 18px;
}

.index-ticker-change.negative {
  color: #f44336;
  padding-left: 1px;
  font-size: 18px;
}


/* 悬浮窗样式 */
.tooltip {
  z-index: 1000; /* 确保悬浮窗在最上方 */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明黑色背景 */
  color: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 400px; /* 限制悬浮窗的最大宽度 */
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
}

.tooltip-change {
  color: #4caf50;
  padding-left: 5px;

}

.tooltip-change.negative {
  color: #ff5252;
  padding-left: 5px;
}




/* 移动端适配 */
@media (max-width: 600px) {
  .crypto-tickers {
    gap: 5px; /* 增加间距 */
  }

  .ticker-card {
    border-radius: 6px; /* 稍微减小圆角 */
    padding: 10px; /* 减小内边距 */
    min-width: auto; /* 自适应宽度 */
  }

  .ticker-name {
    margin-right: 5px;
    font-size: 16px; /* 减小字体大小 */
  }

  .ticker-price {
    font-size: 16px; /* 减小字体大小 */
  }
}


.index-tickers {
  gap: 5px; /* 增加间距 */
}

.index-ticker-card{
  border-radius: 6px; /* 稍微减小圆角 */
  padding: 10px; /* 减小内边距 */
  min-width: auto; /* 自适应宽度 */
}

.index-ticker-name {
  margin-right: 5px;
  font-size: 16px; /* 减小字体大小 */
}

.index-ticker-price {
  font-size: 16px; /* 减小字体大小 */
}

</style>
