import pako from 'pako';
import store from "@/store/store.js";
import forge from "node-forge";

class WebSocketService {
    constructor() {
        this.socket = null;
        this.listeners = {};
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectInterval = 1000; // 重连间隔为1000毫秒
        this.allowReconnect = false; // 控制是否允许重连
        this.pendingMessages = []; // 存储待发送的消息
    }

    connect() {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) return;
        if (this.socket && this.socket.readyState === WebSocket.CONNECTING) return; // 防止在连接过程中再次调用

        const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const wsUrl = `${wsProtocol}://${window.location.host}/ws/`;
        this.socket = new WebSocket(wsUrl);

        // this.socket = new WebSocket(`${wsProtocol}://${window.location.hostname}:8766`);

        // this.socket = new WebSocket('ws://localhost:8766');

        this.socket.onopen = async () => {


            this.allowReconnect = true; // 连接成功后允许重连
            try {
                // 从 sessionStorage 获取公钥
                const publicKey = sessionStorage.getItem('public_key');

                if (!publicKey) {
                    this.$emit("error", "无法获取加密公钥，请稍后重试");
                    return;
                }

                // 获取 Token
                const token = store.getters.token;  // 从 Vuex 获取 token
                if (!token) {
                    console.error("无token");
                    return
                }

                // 生成nonce和时间戳
                const nonce = forge.util.bytesToHex(forge.random.getBytesSync(16)); // 生成16字节的随机字符串作为nonce
                const timestamp = Math.floor(Date.now() / 1000); // 当前时间戳（秒）

                // 1. 生成对称密钥（AES密钥）
                const aesKey = forge.random.getBytesSync(32); // 256位AES密钥
                const iv = forge.random.getBytesSync(16); // 16字节的初始向量

                // 2. 用AES密钥加密交易密码
                const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
                cipher.start({iv: iv});
                cipher.update(forge.util.createBuffer(token, 'utf8'));
                cipher.finish();
                const encryptedToken= forge.util.encode64(iv + cipher.output.getBytes()); // IV + 密文

                // 3. 用RSA公钥加密AES密钥
                const rsa = forge.pki.publicKeyFromPem(publicKey);
                const encryptedSymmetricKey = forge.util.encode64(rsa.encrypt(aesKey, 'RSA-OAEP'));

                // 4. 发送加密的对称密钥、加密的交易密码、nonce和时间戳
                const tokenData = {
                    encryptedSymmetricKey: encryptedSymmetricKey,
                    encryptedToken: encryptedToken,
                    nonce: nonce,
                    timestamp: timestamp
                };

                this.socket.send(JSON.stringify({tokenData})); // 发送认证信息
                console.log('tokenData 已发送');

            } catch (error) {
                console.error("验证toke失败:", error);
                this.$emit("error", "token验证失败，请稍后再试");
            }


            this.pendingMessages.forEach(message => this.socket.send(message));
            this.pendingMessages = []; // 清空待发送消息队列
        };


        this.socket.onmessage = (event) => {
            this.handleMessage(event);
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // this.socket.onclose = () => {
        //     console.log('WebSocket disconnected');
        //     this.socket = null; // 清理旧连接
        //     this.reconnect(); // 尝试重新连接
        // };

        this.socket.onclose = (event) => {
            console.log('WebSocket disconnected:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);


            if (event.code === 1000 && event.reason === "notify_websocket") {
                this.allowReconnect = false;
                this.socket = null;
                console.error("notify_websocket");
                return;
            }

            if (event.code === 1000 && event.reason === "app_start_time is restart") {
                this.allowReconnect = false;
                this.socket = null;
                console.error("app_start_time is restart");
                return;
            }

            if (event.code === 1000 && event.reason === "Token expired") {
                console.error("WebSocket disconnected due to expired token. Redirecting to login...");
                return;
            }

            this.socket = null;
            this.reconnect();
        };



    }

    handleMessage(event) {
        try {
            let jsonData;

            if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const binaryData = new Uint8Array(reader.result);
                        jsonData = pako.ungzip(binaryData, { to: 'string' });
                        this.processMessage(jsonData);
                    } catch (error) {
                        console.error('Error processing WebSocket message:', error);
                    }
                };
                reader.readAsArrayBuffer(event.data);
            } else {
                jsonData = event.data;
                this.processMessage(jsonData);
            }
        } catch (error) {
            console.error('Error processing WebSocket message:', error);
        }
    }

    processMessage(jsonData) {
        try {
            const data = JSON.parse(jsonData);
            if (this.listeners[data.type]) {
                this.listeners[data.type].forEach(callback => callback(data));
            }
        } catch (error) {
            console.error('Error parsing JSON data:', error);
        }
    }

    reconnect() {
        if (this.allowReconnect && this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            const interval = this.reconnectInterval * Math.pow(2, this.reconnectAttempts - 1);
            console.log(`Attempting to reconnect in ${interval / 1000} seconds...`);
            setTimeout(() => {
                if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
                    this.socket.close(); // 关闭旧连接
                }
                this.connect(); // 重新连接
            }, interval);
        } else {
            console.error('Max reconnect attempts reached. Please refresh the page.');
            this.socket = null; // 清理掉失效的 WebSocket 对象
        }
    }

    addListener(type, callback) {
        if (!this.listeners[type]) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(callback);
    }

    removeListener(type, callback) {
        if (this.listeners[type]) {
            this.listeners[type] = this.listeners[type].filter(cb => cb !== callback);
        }
    }

    send(type, data) {
        console.log('Attempting to send message:', { type, data });
        if (this.socket) {
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({ type, data }));
                console.log('Message sent:', { type, data });
            } else {
                console.error('WebSocket is not connected. Unable to send message:', { type, data });
                // 将待发送的消息保存到队列中
                this.pendingMessages.push(JSON.stringify({ type, data }));
            }
        } else {
            console.error('WebSocket is not initialized. Unable to send message:', { type, data });
            // 将待发送的消息保存到队列中
            this.pendingMessages.push(JSON.stringify({ type, data }));
        }
    }

    disconnect() {
        if (this.socket) {
            this.allowReconnect = false; // 断开连接时不允许重连
            this.socket.close();
            this.socket = null; // 清理旧连接
        }
    }

    login() {
        this.connect(); // 在登录时连接 WebSocket
    }

    // logout() {
    //     this.disconnect(); // 在登出时断开 WebSocket
    // }

}

export default new WebSocketService();
