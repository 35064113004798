<template>
  <div>
    <p v-if="error" class="error-message">{{ error }}</p>
    <p v-else-if="!hasData" class="loading-message">加载中...</p>
    <div v-else class="table-container">
      <div class="filter-container">

        <h3 @click="toggleAllPositionAccount">
          持仓信息 ({{ totalPositionCount }})
        </h3>

        <!-- 添加一个清空表格的按钮 -->
        <button @click="clearTableData" class="clear-button">
          ClearTableData
        </button>

        <!-- 新增的产品ID筛选下拉框 -->
        <div v-if="!$device.isMobile" class="filter-container0">
          <label for="product-filter">产品ID</label>
          <select id="product-filter" v-model="selectedProductId" @change="filterByProductId">
            <option value="">ALL</option>
            <option v-for="id in uniqueProductIds" :key="id" :value="id">{{ id }}</option>
          </select>
      </div>

        <!-- 模式方向杠杆单选按钮组 -->
        <div v-if="!$device.isMobile" class="filter-container1">
          <span>方向</span>
          <label>
            <input type="radio" value="" v-model="selectedLeverage" @change="filterByLeverage">
            ALL
          </label>
          <label>
            <input type="radio" value="多" v-model="selectedLeverage" @change="filterByLeverage">
            多
          </label>
          <label>
            <input type="radio" value="空" v-model="selectedLeverage" @change="filterByLeverage">
            空
          </label>
        </div>


        <!-- 新增账户累计盈亏显示 -->
        <div v-if="!$device.isMobile" class="total-upl-container">
          <span v-for="account in activeAccounts" :key="account" class="total-upl">
            {{ getAccountName(account) }} 累计盈亏: {{ getAccountTotalUpl(getAccountName(account)) }}
          </span>
        </div>
      </div>

      <table :class="{ 'desktop-table': true, 'hidden': isTableHidden }">
        <thead>
        <tr>
          <th @click="setSort('accountName')">
            账户
            <span v-if="sortColumn === 'accountName'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('instId')">
            产品ID
            <span v-if="sortColumn === 'instId'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('modeDirectionLeverage')">
            模式方向杠杆
            <span v-if="sortColumn === 'modeDirectionLeverage'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('pos')">
            持仓量
            <span v-if="sortColumn === 'pos'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('upl')">
            盈亏额
            <span v-if="sortColumn === 'upl'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('uplRatio')">
            盈亏比率
            <span v-if="sortColumn === 'uplRatio'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('avgPx')">
            开仓均价
            <span v-if="sortColumn === 'avgPx'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('liqpx')">
            预估强平价
            <span v-if="sortColumn === 'liqpx'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('bePx')">
            盈亏平衡价
            <span v-if="sortColumn === 'bePx'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>
          <th @click="setSort('closeOrderAlgo')">
            全部仓位止损止盈
            <span v-if="sortColumn === 'closeOrderAlgo'">
          {{ sortDirection === 'asc' ? '▲' : '▼' }}
        </span>
          </th>

<!--          <th @click="setSort('posId')">-->
<!--            持仓ID-->
<!--            <span v-if="sortColumn === 'posId'">-->
<!--          {{ sortDirection === 'asc' ? '▲' : '▼' }}-->
<!--        </span>-->
<!--          </th>-->


          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(accountData, accountName) in filteredData" :key="accountName">
          <tr v-if="accountData.positions.length === 0">
            <td>{{ accountName }}</td>
            <td colspan="11">无持仓</td>
          </tr>
          <template v-else>
            <tr v-for="(position, index) in accountData.positions"
                :key="`${position.posId}`">
<!--                :key="`${accountName}-${position.instId}-${position.posSide}-${index}`">-->
              <td v-if="index === 0" :rowspan="accountData.positions.length">{{ accountName }}</td>
              <td>{{ position.instId }}</td>
              <td v-html="formatModeDirectionLeverage(position)"></td>
              <td>{{ position.pos }}</td>

              <!-- 修改部分：添加条件渲染颜色 -->
              <td :style="{ color: parseFloat(position.upl) < 0 ? 'red' : 'white' }">{{ position.upl }}</td>
              <td :style="{ color: parseFloat(position.uplRatio) < 0 ? 'red' : 'white' }">
                {{ (parseFloat(position.uplRatio)).toFixed(2) }}%
              </td>

<!--              <td>{{ position.upl }}</td>-->
<!--              <td>{{ position.uplRatio }}</td>-->

              <td>{{ formatValue(position.avgPx) }}</td>
              <td>{{ formatValue(position.liqpx) }}</td>
              <td>{{ formatValue(position.bePx) }}</td>
              <td>{{ closeOrderAlgo(position) }}</td>

<!--              <td>{{ position.posId }}</td>-->

              <td><button @click="closePosition(position, accountName)">市价全平</button></td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>

      <div class="mobile-cards">
        <!-- 新增的产品ID筛选下拉框 -->
        <div v-if="$device.isMobile" class="filter-container0">
          <label for="product-filter">ID</label>
          <select id="product-filter" v-model="selectedProductId" @change="filterByProductId">
            <option value="">ALL</option>
            <option v-for="id in uniqueProductIds" :key="id" :value="id">{{ id }}</option>
          </select>
          <!-- 新增的模式方向杠杆筛选下拉框 -->
          <label for="leverage-filter">方向</label>
          <select id="leverage-filter" v-model="selectedLeverage" @change="filterByLeverage">
            <option value="">ALL</option>
            <option value="多">多</option>
            <option value="空">空</option>
          </select>
        </div>

        <div v-for="(positions, accountName) in groupedData" :key="accountName" class="account-card">
          <h3 @click="toggleAccount(accountName)" class="account-header">
            {{ accountName }}：
<!--            - 累计盈亏: -->
            {{ getAccountTotalUpl(accountName) }}
          </h3>
          <div v-if="!collapsedAccounts.includes(accountName)">
            <div v-for="position in positions" :key="position.instId" class="position-card">
              <div class="position-grid">
                <div class="position-item">
                  <strong>{{ position.instId }}</strong> <span v-html="formatModeDirectionLeverage(position)"></span>
                </div>
                <div class="position-item">
                  <strong>持仓量:</strong> {{ position.pos }}
                </div>
                <div class="position-item">
                  <strong>开仓均价:</strong> {{ formatValue(position.avgPx) }}
                </div>

<!--                <div class="position-item">-->
<!--                  <strong>盈亏:</strong> {{ position.upl }} ({{ position.uplRatio }})-->
<!--                </div>-->

                <div class="position-item">
                  <strong>盈亏:</strong>
                  <span :style="{ color: parseFloat(position.upl) < 0 ? 'red' : 'white' }">
                  {{ position.upl }}
                  (<span :style="{ color: parseFloat(position.uplRatio) < 0 ? 'red' : 'white' }">{{ parseFloat(position.uplRatio).toFixed(2) }}%</span>)
                </span>
                </div>

                <div class="position-item">
                  <strong>预估强平价:</strong> {{ formatValue(position.liqpx) }}
                </div>
                <div class="position-item">
                  <strong>盈亏平衡价:</strong> {{ formatValue(position.bePx) }}
                </div>
                <div class="position-item">
                  <strong>全部仓位止损止盈:</strong> {{ closeOrderAlgo(position) }}
                </div>
                <div class="position-item">
                  <button @click="closePosition(position, accountName)">市价全平</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from '@/services/websocket';
import api from '@/services/api';
import {useToast} from "vue-toastification";


export default {
  name: 'PositionTable',

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      positionInfo: {},

      error: null,
      accounts: [
        // { label: "账户1", value: "1" },
        // { label: "账户2", value: "2" },
        // { label: "账户3", value: "3" },
        // { label: "账户4", value: "4" },
        { label: "账户5", value: "5" },
        { label: "账户6", value: "6" },
        { label: "账户7", value: "7" },
      ],
      // activeAccounts: ["1", "2", "3", "4"],
      activeAccounts: ["5", "6", "7"],
      collapsedAccounts: [],
      initialLoadComplete: false, // 标记初始加载完成
      updateMethod: 'WebSocket', // 跟踪更新方式
      apiInterval: null, // 用于存储API调用的定时器
      abortController: null, // 用于取消请求的控制器

      selectedProductId: "", // 新增: 用于存储选中的产品ID
      isTableHidden: false, // 表格默认显示
      // isTableHidden: true, // 表格默认隐藏

      sortColumn: 'instId', // 默认排序字段
      sortDirection: 'asc', // 默认排序方向（升序）

      selectedLeverage: "", // 新增: 用于存储选中的模式方向杠杆

    }
  },

  computed: {
    groupedData() {
      const grouped = {};
      for (const [accountName, accountData] of Object.entries(this.filteredData)) {
        if (!grouped[accountName]) {
          grouped[accountName] = [];
        }
        grouped[accountName] = accountData.positions;
      }
      return grouped;
    },

    totalPositionCount() {
      return Object.values(this.filteredData).reduce((total, accountData) => {
        return total + accountData.positions.length;
      }, 0);
    },

    hasData() {
      return Object.keys(this.positionInfo).length > 0;
    },

    mergedData() {
      const merged = {};
      for (const [account, positions] of Object.entries(this.positionInfo)) {
        merged[account] = { positions };
      }
      return merged;
    },

    filteredData() {
      const filtered = {};
      for (const accountValue of this.activeAccounts) {
        const accountName = this.accounts.find(acc => acc.value === accountValue)?.label;
        if (accountName && this.positionInfo[accountName]) {
          filtered[accountName] = {
            positions: this.positionInfo[accountName]
                .filter(position =>
                    (this.selectedProductId === "" || position.instId === this.selectedProductId) &&
                    (this.selectedLeverage === "" || this.formatModeDirectionLeverage(position).includes(this.selectedLeverage))
                )
                .sort((a, b) => {
                  const aValue = a[this.sortColumn];
                  const bValue = b[this.sortColumn];
                  if (this.sortDirection === 'asc') {
                    return aValue > bValue ? 1 : -1;
                  } else {
                    return aValue < bValue ? 1 : -1;
                  }
                })
          };
        }
      }
      return filtered;
    },


    uniqueProductIds() {
      const ids = new Set();
      for (const accountData of Object.values(this.mergedData)) {
        accountData.positions.forEach(position => ids.add(position.instId));
      }
      return Array.from(ids);
    }


  },

  mounted() {
    this.fetchDataViaAPI(this.activeAccounts)
    this.connectWebSocket();
    this.$eventBus.on('accounts-changed', this.handleAccountsChanged);
    this.$eventBus.on('update-method-changed', this.handleUpdateMethodChanged);
  },

  beforeUnmount() {
    this.removeWebSocketListeners();
    WebSocketService.disconnect();
    clearInterval(this.apiInterval); // 清除API调用的定时器
    if (this.abortController) {
      this.abortController.abort(); // 取消未完成的API请求
    }
    this.$eventBus.off('accounts-changed', this.handleAccountsChanged);
    this.$eventBus.off('update-method-changed', this.handleUpdateMethodChanged);
  },

  methods: {

    // 清空表格数据的方法
    clearTableData() {
      // 清空持仓数据和相关筛选条件
      this.positionInfo = {}; // 清空持仓数据
      this.selectedProductId = ""; // 重置产品ID筛选
      this.selectedLeverage = ""; // 重置杠杆筛选
    },

    filterByLeverage() {
      this.filteredData; // 调用计算属性执行筛选逻辑
    },

    setSort(column) {
      if (this.sortColumn === column) {
        // 切换排序方向
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // 设置新排序字段，并默认为升序
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },

    setDefaultCollapseState(data) {
      // 仅在移动设备和初次加载时设置折叠状态
      if (this.$device.isMobile && !this.initialLoadComplete) {
        // 检查数据类型是否为 "positions"
        if (data && data.type === "positions") {
          // 使用 $nextTick 确保 DOM 更新完成后设置折叠状态
          this.$nextTick(() => {
            // 设置折叠账户列表
            this.collapsedAccounts = Object.keys(this.filteredData || {});
            this.initialLoadComplete = true;
          });
        } else {
          console.log("Data type is not 'positions', skipping collapse state.");
        }
      }
    },

    updatePositionInfo(data) {
      if (data && data.type === 'positions' && data.data) {
        // 只保留后端返回的账户信息
        const updatedPositionInfo = {};
        for (const [accountName, positions] of Object.entries(data.data)) {
          updatedPositionInfo[accountName] = positions;
        }

        // 更新持仓数据，只保留返回的账户数据
        this.positionInfo = updatedPositionInfo;

        this.error = null;
        this.$eventBus.emit('update-max-close-positions', this.positionInfo);

        // 调用 setDefaultCollapseState 确保初次加载完成
        this.setDefaultCollapseState(data);
      } else {
        console.error('Received invalid position data:', data);
        this.error = "接收到的持仓数据无效";
      }
    },

    filterByProductId() {
      this.filteredData; // 调用计算属性执行筛选逻辑
    },

    handleAccountsChanged(newAccounts) {
      this.activeAccounts = newAccounts;
      this.fetchDataForAccounts(newAccounts);
    },

    fetchDataForAccounts(accountValues) {
      if (this.updateMethod === 'WebSocket') {
        // WebSocketService.send('fetchData', { accounts: accountValues });
      } else {
        this.fetchDataViaAPI(accountValues);
      }
    },

    addWebSocketListeners() {
      WebSocketService.addListener('positions', this.updatePositionInfo);
    },

    removeWebSocketListeners() {
      WebSocketService.removeListener('positions', this.updatePositionInfo);
    },

    formatModeDirectionLeverage(position) {
      const mgnMode = position.mgnMode === 'cross' ? '全仓' : position.mgnMode;
      const posSide = position.posSide === 'long' ? '多' : '空';
      const color = position.posSide === 'long' ? 'green' : 'red';
      return `<span style="color:${color}">${mgnMode} ${position.lever}x ${posSide}</span>`;
    },

    closeOrderAlgo(position) {
      const slTriggerPx = parseFloat(position.slTriggerPx);
      const tpTriggerPx = parseFloat(position.tpTriggerPx);

      let result = '';
      if (!isNaN(slTriggerPx) && slTriggerPx !== null) {
        result += `止损：${slTriggerPx.toFixed(2)}`;
      }
      if (!isNaN(tpTriggerPx) && tpTriggerPx !== null) {
        if (result) {
          result += ' ';
        }
        result += `止盈：${tpTriggerPx.toFixed(2)}`;
      }
      return result || '';
    },

    async closePosition(position, accountName) {

      const confirmCancel = confirm('确定市价全平吗？');
      if (!confirmCancel) return;

      const accountValue = this.accounts.find(acc => acc.label === accountName)?.value;
      try {
        const response = await api.closePosition({
          account: accountValue,
          mgnMode: position.mgnMode,
          posSide: position.posSide,
          instId: position.instId
        });
        if (response.data) {
          this.toast.success('市价全平成功');
          this.fetchDataForAccounts(this.activeAccounts);
        } else {
          this.toast.error('市价全平失败');
        }
      } catch (error) {
        console.error('市价全平出错:', error);
        this.toast.error('市价全平出错，请查看控制台');
      }
    },

    toggleAccount(accountName) {
      const index = this.collapsedAccounts.indexOf(accountName);
      if (index > -1) {
        this.collapsedAccounts.splice(index, 1);
      } else {
        this.collapsedAccounts.push(accountName);
      }
    },

    toggleAllPositionAccount() {
      if (this.$device.isMobile) {
        // 移动端逻辑：折叠或展开所有账户卡片
        if (this.collapsedAccounts.length === Object.keys(this.filteredData).length) {
          // 如果所有账户都折叠，展开所有
          this.collapsedAccounts = [];
        } else {
          // 如果有账户展开，则折叠所有
          this.collapsedAccounts = Object.keys(this.filteredData);
        }
      } else {
        // 网页端逻辑：隐藏或显示表格
        this.isTableHidden = !this.isTableHidden;
      }
    },

    formatValue(value) {
      const num = parseFloat(value);
      // 检查数字的开头是否为0
      if (num < 0.01) {
        return num.toString();
      } else if (value.startsWith('0')) {
        return num.toString();
      }
      return num.toFixed(2);
    },

    getAccountTotalUpl(accountName) {
      const accountData = this.filteredData[accountName];
      if (!accountData) return 0;
      return accountData.positions.reduce((total, position) => total + parseFloat(position.upl), 0).toFixed(2);
    },

    getAccountName(accountValue) {
      const account = this.accounts.find(acc => acc.value === accountValue);
      return account ? account.label : '';
    },

    handleUpdateMethodChanged(newMethod) {
      this.updateMethod = newMethod;
      if (this.updateMethod === 'WebSocket') {
        this.connectWebSocket();
        clearInterval(this.apiInterval); // 切换到WebSocket时清除API定时器
        if (this.abortController) {
          this.abortController.abort();
        }
        this.abortController = null;
      } else {
        this.removeWebSocketListeners();
        WebSocketService.disconnect();
        this.fetchDataViaAPI(this.activeAccounts);
        this.startApiPolling(this.activeAccounts); // 启动API轮询
      }
    },

    connectWebSocket() {
      WebSocketService.connect();
      this.addWebSocketListeners();
    },


    async fetchDataViaAPI(accountValues) {
      try {
        // 创建新的 AbortController
        this.abortController = new AbortController();

        const response = await api.getPositionInfo(accountValues, {
          signal: this.abortController.signal
        });

        // 过滤掉返回 "Error fetching positions" 的账户
        const validPositionInfo = {};
        for (const [account, positions] of Object.entries(response.data)) {
          if (positions !== "Error fetching positions") {
            validPositionInfo[account] = positions;
          }
        }

        // 更新 positionInfo，仅保留有效账户数据
        this.positionInfo = validPositionInfo;

      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('API request canceled:', error.message);
        } else {
          this.error = "接收到的持仓数据无效";
          console.error('Error fetching data via API:', error);
        }
      }
    },

    startApiPolling(accountValues) {
      this.apiInterval = setInterval(async () => {
        await this.fetchDataViaAPI(accountValues);
      }, 200); // 每200毫秒调用一次API
    }
  }
}
</script>

<style scoped>
.table-container {
  margin: auto;
}
.hidden {
  display: none;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
  margin-top: -30px;
}

.total-upl-container {
  margin-left: 50px;
  display: flex;
  gap: 50px;
}

.total-upl {
  font-weight: bold;
}

.error-message, .loading-message {
  text-align: center;
  margin-top: 20px;
}

.table-container {
  margin-top: 20px;
}


.desktop-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: -10px;
  margin-top: -20px;
  table-layout: auto;
}

th, td {
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000;
  font-weight: bold;
  color: #ffffff;
}

tr:nth-child(even) {
  background-color: #111;
}

.error-message, .loading-message {
  color: #ff6b6b;
  font-weight: bold;
}

.mobile-cards {
  display: none;
}

/* 移动端适配 */
@media (max-width: 600px) {
  .desktop-table {
    display: none;
  }

  .mobile-cards {
    display: block;
    margin-top: -15px;
    margin-bottom: -5px;
  }

  .account-card {
    margin-bottom: 20px;
  }

  .account-header {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }

  .position-card {
    border: 1px solid #ffffff;
    padding: 10px;
    background-color: #111;
    margin-bottom: 10px;
  }

  .position-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 设置为2列布局 */
    gap: 5px; /* 设置卡片之间的间距 */
  }

  .position-item {
    display: flex;
    flex-direction: column;

  }

  .position-item strong {
    margin-bottom: auto;
  }

  .position-card button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px; /* 增加圆角 */
    transition: background-color 0.3s; /* 增加过渡效果 */
  }

  .position-card button:hover {
    background-color: #333333;
  }
}
</style>






