<template>
  <div v-show="show" class="modal-overlay" @click.self="close" @keydown.esc="close" @keydown.enter="validatePassword">
    <div class="modal-content" @click.stop>
<!--      <h3>请输入8位验证码</h3>-->
      <h3>Enter the 8-digit verification code</h3>
      <div class="password-inputs">
        <input
            v-for="(value, index) in passwordInputs"
            :key="index"
            v-model="passwordInputs[index]"
            maxlength="1"
            @input="onInput(index)"
            @keydown="onKeyDown(index, $event)"
            ref="inputRefs"
            class="password-input"
            type="password"
            autocomplete="off"
        />
      </div>
      <button @click="validatePassword" class="submit-btn">Verify</button>
      <button @click="close" class="cancel-btn">Close</button>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import forge from 'node-forge';

export default {
  props: {
    show: Boolean, // 控制弹窗显示
  },
  data() {
    return {
      passwordInputs: ["", "", "", "", "", "", "", ""],
    };
  },
  methods: {
    // 聚焦第一个输入框
    focusFirstInput() {
      this.$nextTick(() => {
        if (Array.isArray(this.$refs.inputRefs) && this.$refs.inputRefs[0]) {
          this.$refs.inputRefs[0].focus();
        }
      });
    },

    // 输入事件，自动跳转到下一个输入框
    onInput(index) {
      if (this.passwordInputs[index].length === 1 && index < 7) {
        this.$refs.inputRefs[index + 1].focus();
      }
    },

    // 键盘按下事件，处理退格键和其他键盘事件
    onKeyDown(index, event) {
      if (event.key === 'Backspace') {
        this.onBackspace(index);
      }
    },

    // 退格事件，自动返回前一个输入框
    onBackspace(index) {
      if (this.passwordInputs[index] === "" && index > 0) {
        this.passwordInputs[index - 1] = ""; // 清空上一个输入框的内容
        this.$nextTick(() => {
          this.$refs.inputRefs[index - 1].focus(); // 聚焦到前一个输入框
        });
      } else {
        this.passwordInputs[index] = ""; // 如果当前输入框有内容，直接清空
      }
    },

    // 密码验证
    async validatePassword() {
      const tradePassword = this.passwordInputs.join("");

      if (tradePassword.length === 0) {
        this.clearPassword();
        return;
      }

      try {
        // 获取公钥
        const publicKeyResponse = await api.getPublicKey();
        const publicKey = publicKeyResponse?.public_key;

        if (!publicKey) {
          this.$emit("error", "无法获取加密公钥，请稍后重试");
          this.clearPassword();
          return;
        }

        // 生成nonce和时间戳
        const nonce = forge.util.bytesToHex(forge.random.getBytesSync(16)); // 生成16字节的随机字符串作为nonce
        const timestamp = Math.floor(Date.now() / 1000); // 当前时间戳（秒）

        // 1. 生成对称密钥（AES密钥）
        const aesKey = forge.random.getBytesSync(32); // 256位AES密钥
        const iv = forge.random.getBytesSync(16); // 16字节的初始向量

        // 2. 用AES密钥加密交易密码
        const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
        cipher.start({ iv: iv });
        cipher.update(forge.util.createBuffer(tradePassword, 'utf8'));
        cipher.finish();
        const encryptedPassword = forge.util.encode64(iv + cipher.output.getBytes()); // IV + 密文

        // 3. 用RSA公钥加密AES密钥
        const rsa = forge.pki.publicKeyFromPem(publicKey);
        const encryptedSymmetricKey = forge.util.encode64(rsa.encrypt(aesKey, 'RSA-OAEP'));

        // 4. 发送加密的对称密钥、加密的交易密码、nonce和时间戳
        const response = await api.verifyTradePassword({
          encryptedSymmetricKey: encryptedSymmetricKey,
          encryptedPassword: encryptedPassword,
          nonce: nonce,
          timestamp: timestamp
        });

        if (response && response.success) {
          this.$emit("success");
          this.close();
        } else {
          this.$emit("error", response?.message || "交易密码验证失败");
          this.clearPassword();
        }
      } catch (error) {
        console.error("验证密码失败:", error);
        this.$emit("error", "交易密码验证失败，请稍后再试");
        this.clearPassword();
      }
    },


    // 清除密码输入
    clearPassword() {
      this.passwordInputs = ["", "", "", "", "", "", "", ""];
      this.focusFirstInput(); // 清空后聚焦第一个输入框
    },

    // 关闭弹窗
    close() {
      this.clearPassword();
      this.$emit("close");
    }
  }
};
</script>


<style>
/* Modal overlay and styling for better UX */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background-color: #000000;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.password-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.password-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
}
.submit-btn,
.cancel-btn {
  margin-top: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  border: none;
  margin-right: 10px;
  background-color: rgba(245, 244, 244, 0.2);
  color: #ffffff;
}



/* Mobile-specific styles */
@media (max-width: 480px) {
  .modal-content {
    max-width: 90%; /* Adjust modal width to fit smaller screens */
    padding: 15px;  /* Reduce padding for mobile */
  }


  .password-input {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }

  /* Password inputs */
  .password-inputs {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line */
    gap: 3px;       /* Space between input fields */
  }

  /* Ensure 4 inputs per row */
  .password-inputs .password-input {
    flex: 1 1 20%; /* Each input takes 20% of the width to fit 4 in one row with spacing */
  }

}


</style>
