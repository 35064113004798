import {createStore} from 'vuex';
import api from '@/services/api';

const store = createStore({
    state: {
        // isLoggedIn: sessionStorage.getItem('token') ? true : false,
        isLoggedIn: sessionStorage.getItem('isLoggedIn') || null,
        loginTime: sessionStorage.getItem('loginTime') || null,
        token: sessionStorage.getItem('token') || null,
        csrfToken: sessionStorage.getItem('csrf_token') || null,
        LoginUsername: sessionStorage.getItem('LoginUsername') || null,
    },
    mutations: {
        setLogin(state, { isLoggedIn, loginTime, token, csrfToken, LoginUsername  }) {
            state.isLoggedIn = isLoggedIn;
            state.loginTime = loginTime;
            state.token = token;
            state.csrfToken = csrfToken;  // 设置 CSRF token
            state.LoginUsername = LoginUsername;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.loginTime = null;
            state.token = null;
            state.csrfToken = null;  // 登出时清除 CSRF token
            state.LoginUsername = null;
        },
    },
    actions: {

        async getPublicKey({ commit }) {
            try {
                const response = await api.getPublicKey();
                const publicKey = response.public_key;
                // 存储公钥到 sessionStorage
                sessionStorage.setItem('public_key', publicKey);
                return publicKey;
            } catch (error) {
                console.error("获取公钥失败:", error);
                return null;
            }
        },

        async login({ commit }, { loginData }) {
            try {
                const response =
                    await api.login({loginData});
                if (response.data && response.data.success) {
                    commit('setLogin', {
                        isLoggedIn: true,
                        LoginUsername: response.data.LoginUsername
                    });
                    sessionStorage.setItem('isLoggedIn', true); // 存储登录状态
                    sessionStorage.setItem('LoginUsername', response.data.LoginUsername);
                } else {
                    throw new Error(response.data.message || '登录失败');
                }
            } catch (error) {
                console.error("登录失败:", error);
                throw error; // 抛出错误，供组件捕获
            }
        },

        async verifyOtp({ commit }, { OtpData }) {
            const response =
                await api.verifyOtp({OtpData});
            if (response.data.success) {
                const currentTime = new Date().getTime();
                commit('setLogin', {
                    isLoggedIn: true,
                    LoginUsername: response.data.LoginUsername,
                    loginTime: currentTime,
                    token: response.data.token, // 存储JWT Token
                    csrf_token: response.data.csrf_token,  // 存储 CSRF token
                });
                sessionStorage.setItem('isLoggedIn', true); // 存储登录状态
                sessionStorage.setItem('LoginUsername', response.data.LoginUsername);
                sessionStorage.setItem('token', response.data.token); // 将JWT存储到 sessionStorage
                sessionStorage.setItem('csrf_token', response.data.csrf_token); // 将 CSRF token 存储到 sessionStorage
                sessionStorage.setItem('loginTime', currentTime); // 存储登录时间
            } else {
                throw new Error(response.data.message || 'Otp验证失败');
            }
        },

        async logout({ commit }) {
            try {
                // 调用 API 的 logout 接口
                const response = await api.logout();
                if (response) {
                    console.log('登出成功');
                }
            } catch (error) {
                console.error('登出请求失败:', error);
            }
            commit('logout', {
                isLoggedIn: false,
                LoginUsername: null,
                loginTime: null,
                token: null,
                csrf_token: null,
            });
            sessionStorage.removeItem('isLoggedIn'); // 清除登录状态
            sessionStorage.removeItem('LoginUsername');
            sessionStorage.removeItem('token'); // 清除JWT
            sessionStorage.removeItem('csrf_token'); // 清除 CSRF token
            sessionStorage.removeItem('loginTime');
            sessionStorage.removeItem('public_key');

            localStorage.removeItem('isLoggedIn'); // 清除登录状态
            localStorage.removeItem('LoginUsername');
            localStorage.removeItem('token');
            localStorage.removeItem('csrf_token');
            localStorage.removeItem('loginTime');
            localStorage.removeItem('public_key');

            document.cookie = 'isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'LoginUsername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'csrf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'loginTime=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'public_key=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        },

        async closeLogout({ commit }) {
            console.log('执行：closeLogout');
            commit('logout', {
                isLoggedIn: false,
                LoginUsername: null,
                loginTime: null,
                token: null,
                csrf_token: null,
            });
            sessionStorage.removeItem('isLoggedIn'); // 清除登录状态
            sessionStorage.removeItem('LoginUsername');
            sessionStorage.removeItem('token'); // 清除JWT
            sessionStorage.removeItem('csrf_token'); // 清除 CSRF token
            sessionStorage.removeItem('loginTime');
            sessionStorage.removeItem('public_key');

            localStorage.removeItem('isLoggedIn'); // 清除登录状态
            localStorage.removeItem('LoginUsername');
            localStorage.removeItem('token');
            localStorage.removeItem('csrf_token');
            localStorage.removeItem('loginTime');
            localStorage.removeItem('public_key');

            document.cookie = 'isLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'LoginUsername=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'csrf_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'loginTime=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            document.cookie = 'public_key=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        },


    },

    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        LoginUsername: (state) => state.LoginUsername,
        loginTime: (state) => state.loginTime,
        token: (state) => state.token,  // 获取 JWT Token
        csrfToken: (state) => state.csrfToken,  // 获取 CSRF token
    },
});

export default store;

