<template>
  <div class="combined-table">

    <!-- 选择全仓/逐仓的下拉框 -->
    <div class="input-group">
      <label for="mgnMode"></label>
      <select id="mgnMode" v-model="selectedMgnMode">
        <option value="cross">全仓</option>
        <!-- <option value="isolated">逐仓</option> -->
      </select>

      <label for="lever"></label>
      <!-- 数字输入框 -->
      <input id="lever" type="number" v-model.number="inputLever" min="1" max="100" @wheel="onWheelChange"/>

      <button @click="setLeverageSettings">SetLever</button>
      <!-- 数字滑块 -->
      <input type="range" v-model.number="inputLever" min="1" max="100" step="0.01" @wheel="onWheelChange"/>
    </div>

    <table v-if="leverageInfo.length || maxAmounts.length">
      <thead>
      <tr>
        <th>Acc</th>
        <th v-if="leverageInfo.length">Lev</th>
        <!--        <th v-if="leverageInfo.length">Short</th>-->
        <th v-if="maxAmounts.length">MAX-L</th>
        <th v-if="maxAmounts.length">MAX-S</th>
        <th v-if="usePercentage">Buy</th>
        <th v-if="usePercentage">Sell</th> <!-- 新增列 -->
      </tr>
      </thead>
      <tbody>
      <tr v-for="account in allAccounts" :key="account">
        <td>{{ account }}</td>
        <td v-if="leverageInfo.length">{{ getLeverageInfo(account, 'lever') }}</td>
        <!--        <td v-if="leverageInfo.length">{{ getLeverageInfo(account, 'short') }}</td>-->
        <td v-if="maxAmounts.length">{{ getMaxAmount(account, 'maxBuy') }}</td>
        <td v-if="maxAmounts.length">{{ getMaxAmount(account, 'maxSell') }}</td>
        <td v-if="usePercentage">{{ calculateOrderAmount(getMaxAmount(account, 'maxBuy')).toFixed(1) }}</td>
        <td v-if="usePercentage">{{ calculateOrderAmount(getMaxAmount(account, 'maxSell')).toFixed(1) }}</td>
      </tr>
      <!-- 添加汇总行 -->
      <tr v-if="maxAmounts.length">
        <td>Tot</td>
        <td v-if="leverageInfo.length"></td>
        <!--        <td v-if="leverageInfo.length"></td>-->
        <td>{{ totalMaxBuy }}</td>
        <td>{{ totalMaxSell }}</td>
        <td v-if="usePercentage">{{ totalOrderBuy }}</td>
        <td v-if="usePercentage">{{ totalOrderSell }}</td>
      </tr>
      </tbody>
    </table>
    <p v-else>暂无数据</p>
  </div>
</template>

<script>
import api from '@/services/api';
import { useToast } from "vue-toastification";
import { inject } from 'vue';

export default {
  name: 'CombinedTable',

  setup() {
    const toast = useToast();
    const eventBus = inject('eventBus');
    return { toast, eventBus };
  },

  props: {
    usePercentage: Boolean,
    percentage: Number,
  },

  data() {
    return {
      leverageInfo: [],
      maxAmounts: [],
      selectedAccounts: [],
      currentInstId: '',
      selectedMgnMode: 'cross',
      inputLever: 10,
    };
  },

  computed: {
    totalMaxBuy() {
      return this.maxAmounts.reduce((sum, amount) => sum + parseFloat(amount.maxBuy), 0).toFixed(1);
    },
    totalMaxSell() {
      return this.maxAmounts.reduce((sum, amount) => sum + parseFloat(amount.maxSell), 0).toFixed(1);
    },
    totalOrderBuy() {
      return this.calculateOrderAmount(parseFloat(this.totalMaxBuy)).toFixed(1);
    },
    totalOrderSell() {
      return this.calculateOrderAmount(parseFloat(this.totalMaxSell)).toFixed(1);
    },
    allAccounts() {
      const accounts = new Set([...this.leverageInfo.map(item => item.account), ...this.maxAmounts.map(amount => amount.account)]);
      return Array.from(accounts);
    }
  },

  created() {
    this.$eventBus.on('accounts-changed', this.onAccountsChanged);
    this.$eventBus.on('ticker-selected', this.onTickerSelected);
    // this.$eventBus.on('leverage-changed', this.fetchMaxAmounts);
  },

  beforeUnmount() {
    this.$eventBus.off('accounts-changed', this.onAccountsChanged);
    this.$eventBus.off('ticker-selected', this.onTickerSelected);
    // this.$eventBus.off('leverage-changed', this.fetchMaxAmounts);
  },

  methods: {
    // 滚轮事件处理器
    onWheelChange(event) {
      // 处理输入框和滑块的滚轮事件
      const target = event.target;

      if ((target.tagName === 'INPUT' && (target.type === 'number' || target.type === 'range'))) {
        event.preventDefault();  // 阻止默认滚轮行为

        // 根据滚动方向确定加减步长
        const step = event.deltaY < 0 ? 1 : -1;  // 上滚增加，下滚减少
        const newValue = this.inputLever + step;

        // 限制值在 1 到 100 之间
        if (newValue >= 1 && newValue <= 100) {
          this.inputLever = newValue;
        }
      }
    },

    async onAccountsChanged(accounts) {
      this.selectedAccounts = accounts;
      if (this.currentInstId) {
        await this.fetchLeverageInfo();
        await this.fetchMaxAmounts();
      }
    },

    async onTickerSelected(ticker) {
      this.currentInstId = ticker.instId;
      await this.fetchLeverageInfo();
      await this.fetchMaxAmounts();
    },

    async fetchLeverageInfo() {
      if (!this.currentInstId || this.selectedAccounts.length === 0) return;
      try {
        const response = await api.getLeverageInfo(this.currentInstId, this.selectedAccounts);
        this.leverageInfo = response.data.data;
      } catch (error) {
        console.error('获取杠杆信息失败:', error);
        this.toast.error('获取杠杆信息失败');
        this.leverageInfo = [];
      }
    },

    async fetchMaxAmounts() {
      if (!this.currentInstId || this.selectedAccounts.length === 0) return;
      try {
        const response = await api.getMaxAmount(this.currentInstId, this.selectedAccounts);
        this.maxAmounts = response.data.data;
      } catch (error) {
        console.error('获取最大可买卖/开仓数量失败:', error);
        this.maxAmounts = [];
      }
    },

    async setLeverageSettings() {
      if (!this.currentInstId || this.selectedAccounts.length === 0) {
        this.toast.error('请先选择账户和产品');
        return;
      }

      const leverageData = {
        instId: this.currentInstId,
        accounts: this.selectedAccounts,
        mgn_mode: this.selectedMgnMode,
        lever: this.inputLever
      };

      try {
        const response = await api.setLeverage(leverageData);
        const results = response.data.results || [];

        results.forEach(result => {
          if (result.status === 'Success') {
            const lever = result.data.data[0].lever;
            this.toast.success(`账户 ${result.account} 的${this.currentInstId}设置成功: 杠杆倍数为 ${lever}`);
          } else if (result.status === 'Failed') {
            const errorMessage = result.error;
            this.toast.error(`账户 ${result.account} 的${this.currentInstId}杠杆设置失败:${errorMessage}`);
          }
        });

        await this.fetchLeverageInfo();
        await this.fetchMaxAmounts()
        // this.$eventBus.emit('leverage-changed');

      } catch (error) {
        console.error('设置杠杆失败:', error);
        this.toast.error('设置杠杆失败,请查看控制台');
      }
    },

    getLeverageInfo(account, type) {
      const info = this.leverageInfo.find(item => item.account === account);
      return info ? info[type] : '';
    },

    getMaxAmount(account, type) {
      const amount = this.maxAmounts.find(item => item.account === account);
      return amount ? amount[type] : '';
    },

    calculateOrderAmount(amount) {
      if (!amount || isNaN(parseFloat(amount))) return 0;
      const maxAmount = parseFloat(amount);
      return Math.floor(maxAmount * (this.percentage / 100) * 10) / 10;
    },
  },

  mounted() {
    this.fetchLeverageInfo();
    this.fetchMaxAmounts();
  }


};
</script>

<style scoped>
.combined-table {
  margin-top: auto;
  margin-bottom: 5px;
  padding-right: 25px;

}

.input-group {
  margin-bottom: 0;
}

input[type="number"], select, button {
  flex: 1;
}

input[type="range"] {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

th, td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000;
  color: #ffffff;
}

@media (max-width: 600px) {
  table {
    font-size: 16px;
    overflow-x: auto; /* 使表格在小屏幕上可以水平滚动 */
  }

  th, td {
    padding: 6px;
  }

  th {
    font-size: 12px;
  }

  .input-group {
    flex-direction: column; /* 输入组在小屏幕上垂直排列 */
    gap: 1px; /* 缩小间距 */
  }

  input[type="number"], select, button {
    font-size: 12px; /* 更小的字体以适应小屏幕 */
    padding: 4px;
  }

  input[type="range"] {
    margin-left: 0;
    margin-right: 0;
  }

  th, td {
    white-space: nowrap; /* 防止内容在小屏幕上换行 */
    font-size: 14px; /* 调整表格内容的字体大小 */
  }
}
</style>







<!--<template>-->
<!--  <div class="max-amount-table">-->
<!--    <table v-if="maxAmounts.length">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>账户</th>-->
<!--        <th>MAX-L</th>-->
<!--        <th>MAX-S</th>-->
<!--        <th v-if="usePercentage">Buy</th>-->
<!--        <th v-if="usePercentage">Sell</th> &lt;!&ndash; 新增列 &ndash;&gt;-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr v-for="amount in maxAmounts" :key="amount.account">-->
<!--        <td>{{ amount.account }}</td>-->
<!--        <td>{{ amount.maxBuy }}</td>-->
<!--        <td>{{ amount.maxSell }}</td>-->
<!--        <td v-if="usePercentage">{{ calculateOrderAmount(amount.maxBuy).toFixed(1) }}</td>-->
<!--        <td v-if="usePercentage">{{ calculateOrderAmount(amount.maxSell).toFixed(1) }}</td> &lt;!&ndash; 新增列 &ndash;&gt;-->
<!--      </tr>-->
<!--      &lt;!&ndash; 添加汇总行 &ndash;&gt;-->
<!--      <tr>-->
<!--        <td>汇总</td>-->
<!--        <td>{{ totalMaxBuy }}</td>-->
<!--        <td>{{ totalMaxSell }}</td>-->
<!--        <td v-if="usePercentage">{{ totalOrderBuy }}</td>-->
<!--        <td v-if="usePercentage">{{ totalOrderSell }}</td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
<!--    <p v-else>暂无数据</p>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import api from '@/services/api';-->

<!--// 导出默认的MaxAmountTable组件-->
<!--export default {-->
<!--  name: 'MaxAmountTable',-->
<!--  // 定义组件的属性-->
<!--  props: {-->
<!--    usePercentage: Boolean,-->
<!--    percentage: Number,-->
<!--  },-->
<!--  // 定义组件的数据-->
<!--  data() {-->
<!--    return {-->
<!--      maxAmounts: [],-->
<!--      selectedAccounts: [],-->
<!--      currentInstId: ''-->
<!--    }-->
<!--  },-->

<!--  computed: {-->
<!--    totalMaxBuy() {-->
<!--      return this.maxAmounts.reduce((sum, amount) => sum + parseFloat(amount.maxBuy), 0).toFixed(1);-->
<!--    },-->
<!--    totalMaxSell() {-->
<!--      return this.maxAmounts.reduce((sum, amount) => sum + parseFloat(amount.maxSell), 0).toFixed(1);-->
<!--    },-->
<!--    totalOrderBuy() {-->
<!--      return this.calculateOrderAmount(parseFloat(this.totalMaxBuy)).toFixed(1);-->
<!--    },-->
<!--    totalOrderSell() {-->
<!--      return this.calculateOrderAmount(parseFloat(this.totalMaxSell)).toFixed(1);-->
<!--    }-->
<!--  },-->


<!--  // 创建组件时触发-->
<!--  created() {-->
<!--    // 监听账户变化事件-->
<!--    this.$eventBus.on('accounts-changed', this.onAccountsChanged);-->
<!--    // 监听股票变化事件-->
<!--    this.$eventBus.on('ticker-selected', this.onTickerSelected);-->

<!--    this.$eventBus.on('leverage-changed',this.fetchMaxAmounts)-->
<!--  },-->
<!--  // 组件卸载时触发-->
<!--  beforeUnmount() {-->
<!--    // 取消监听账户变化事件-->
<!--    this.$eventBus.off('accounts-changed', this.onAccountsChanged);-->
<!--    // 取消监听股票变化事件-->
<!--    this.$eventBus.off('ticker-selected', this.onTickerSelected);-->

<!--    this.$eventBus.off('leverage-changed',this.fetchMaxAmounts)-->
<!--  },-->

<!--  methods: {-->
<!--    async onAccountsChanged(accounts) {-->
<!--      // console.log('Accounts changed in MaxAmountTable:', accounts);-->
<!--      this.selectedAccounts = accounts;-->
<!--      if (this.currentInstId) {-->
<!--        await this.fetchMaxAmounts();-->
<!--      }-->
<!--    },-->
<!--    async onTickerSelected(ticker) {-->
<!--      // console.log('Ticker selected:', ticker);-->
<!--      this.currentInstId = ticker.instId;-->
<!--      await this.fetchMaxAmounts();-->
<!--    },-->

<!--    async fetchMaxAmounts() {-->
<!--      // console.log('Fetching max amounts for:', this.currentInstId, this.selectedAccounts);-->
<!--      if (!this.currentInstId || this.selectedAccounts.length === 0) {-->
<!--        // console.log('No instId or accounts selected, skipping API call');-->
<!--        return;-->
<!--      }-->
<!--      try {-->
<!--        const response = await api.getMaxAmount(this.currentInstId, this.selectedAccounts);-->
<!--        // console.log('API response:', response);-->
<!--        this.maxAmounts = response.data.data;-->
<!--        // console.log('Updated maxAmounts:', this.maxAmounts);-->
<!--        // console.log('Account types in maxAmounts:', this.maxAmounts.map(a => typeof a.account));-->
<!--      } catch (error) {-->
<!--        console.error('获取最大可买卖/开仓数量失败:', error);-->
<!--        this.maxAmounts = [];-->
<!--      }-->
<!--    },-->


<!--    calculateOrderAmount(amount) {-->
<!--      if (!amount || isNaN(parseFloat(amount))) {-->
<!--        return 0;-->
<!--      }-->
<!--      const maxAmount = parseFloat(amount);-->
<!--      const calculatedAmount = maxAmount * (this.percentage / 100);-->
<!--      return Math.floor(calculatedAmount * 10) / 10; // 保留一位小数并向下取整-->
<!--    },-->

<!--  },-->

<!--  mounted() {-->
<!--    this.fetchMaxAmounts();-->

<!--  },-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->
<!--.max-amount-table {-->
<!--  margin-top: auto;-->
<!--  margin-bottom: 10px;-->
<!--  padding-right: 10px; /* 保留右边距 */-->
<!--}-->

<!--table {-->
<!--  width: 100%;-->
<!--  border-collapse: collapse;-->
<!--  table-layout: auto; /* 使列的间距适应单元格内容 */-->
<!--}-->

<!--th, td {-->
<!--  border: 1px solid #ffffff; /* 将边框颜色设置为白色 */-->
<!--  padding: 8px;-->
<!--  text-align: left;-->
<!--}-->

<!--th {-->
<!--  background-color: #000000;-->
<!--  color: #ffffff; /* 设置表头文字颜色为白色 */-->
<!--}-->

<!--/* 移动端适配 */-->
<!--@media (max-width: 600px) {-->
<!--  table {-->
<!--    font-size: 16px; /* 调整字体大小 */-->
<!--    padding-right: 10px; /* 保留右边距 */-->
<!--  }-->

<!--  th, td {-->
<!--    padding: 6px; /* 调整内边距 */-->
<!--  }-->

<!--  th {-->
<!--    font-size: 16px; /* 调整表头字体大小 */-->
<!--  }-->
<!--}-->
<!--</style>-->
